import styled from 'styled-components'

export const BtnTd = styled.button`
    border: none;
    margin: 0;
    padding: 0;
    /* transition: 1s; */    
    i{
        height: 20px;
        font-size: 22px;
       
    }
    :hover{
        color: #be5a5a;               
    }
`
export const InputTd = styled.input`
    border-radius: 10px;
    border: 0.1px solid gray;
    padding: 5px;
    font-weight: 400;
    color: #464646;
    text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
`