import React from 'react';
import ReactDOM from 'react-dom';
// import { withRouter } from 'react-router-dom'
import './index.css';


import 'react-toastify/dist/ReactToastify.css'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootswatch/dist/pulse/bootstrap.min.css'

import Rutas from './Rutas'

import { AuthContext } from './context/AuthContext'
import axios from 'axios'
axios.defaults.withCredentials = true;



ReactDOM.render(
  <React.StrictMode>
    <AuthContext>
      <Rutas />
    </AuthContext>
  </React.StrictMode>,
  document.getElementById('root')
);

