import React, { useContext } from 'react'
import { HashRouter , Route, Router, Routes  } from 'react-router-dom'
import UsuarioForm from './components/Usuarios/UsuarioForm';
import NavBar from './components/Navbar/Navbar'

// import './index.css';
import UsuarioList from './components/Usuarios/UsuarioList';

import { ToastContainer } from 'react-toastify';
import LocalidadForm from './components/Localidades/LocalidadForm';
import LocalidadList from './components/Localidades/LocalidadList';
import LoteForm from './components/Lotes/LoteForm';
import LoteList from './components/Lotes/LoteList';
import DashboardMain from './components/Dashboard/DashboardMain';
import CogidaList from './components/Cogidas/CogidaList';
import CogidaForm from './components/Cogidas/CogidaForm';
import Login from './components/Login/Login';
import DepartamentoList from './components/Departamentos/DepartamentoList'  
import PagoTransporteList from './components/PagoTransporte/PagoTransporteList';
import PagoAgricultorList from './components/PagoAgricultor/PagoAgricultorList'; 
// import moduleName from 'module'

import authContext from './context/AuthContext';

// import RutasProtegidas from './RutasProtegidas'
import ProveedorList from './components/Proveedores/ProveedorList';
import ProveedorForm from './components/Proveedores/ProveedorForm';
import ProyeccionesList from './components/Proyecciones/ProyeccionesList'
import ReporteProyeccion from './components/Proyecciones/Reportes/ReporteProyeccion'
import AlmacenMPIndex from './components/Planta/IndexMapas/IndexMapas'
// ALMACENES PALLETS
import AlmacenMP from './components/Planta/AlmacenMP/AlmacenMP';
import AlmacenAlsurExt from './components/Planta/AlmacenMP/AlmacenAlsurExt/AlmacenAlsurExt'
import AlmacenCalzado from './components/Planta/AlmacenMP/AlmacenCalzado/AlmacenCalzado'
import AlmCongeladoAqp from './components/Planta/AlmacenMP/AlmacenCongeladoAQP/AlmCongeladoAqp'
import AdministrarAPT from './components/Planta/Administrar/Administrar'
import DibujarQr from './components/Planta/Administrar/DibujarQr'

import Pruebas from './components/Planta/Administrar/Pruebas'

//ESTO SE AGREGO PARA CUSCO
import AlmacenCuscoAPT from './components/Planta/AlmacenMP/AlmacenCuscoAPT/AlmacenCuscoAPT';
import AlmacenCuscoCamara from './components/Planta/AlmacenMP/AlmacenCuscoCamara/AlmacenCuscoCamara';
import AlmacenTransito from './components/Planta/AlmacenMP/AlmacenTransito/AlmacenTransito';


import AlmacenesIndex from './components/Almacenes/AlmacenesIndex'
import IndexReportesAlm from './components/Almacenes/Reportes/IndexReportes'
import TrazabilidadAlmacen from './components/Almacenes/TrazabilidadAlmacen/TrazabilidadAlmacen'
import UbicacionPallets from './components/Planta/ReportesMP/UbicacionPallets'
import DetalleNivel from './components/Planta/ReportesMP/DetalleNivel'
import ParteDiario from './components/Planta/ReportesMP/parteDiario'
import SalidaPallet from './components/Planta/SalidaPallet/SalidaPallet'

//CALIDAD PRODUCCION
import Inputs from './components/CalidadProduccion/Inputs/Inputs'
import Permisos from './components/CalidadProduccion/Permisos/Permisos'
import FormInputs from './components/CalidadProduccion/Inputs/FormInputs'
import ResumenInputs from './components/CalidadProduccion/ResumenInputs/ResumenInputs'
import Parametros from './components/CalidadProduccion/Parametros/Parametros';
import RegObjetivos from './components/CalidadProduccion/Objetivos/RegObjetivos'
import InputRegMas from './components/CalidadProduccion/InputRegMas/InputRegMas'
// REGISTRO PERSONAL
import MarcadorCap from './components/RegistroPersonal/MarcadorCap'
import ReporteGeneral from './components/RegistroPersonal/Reportes/ReporteGeneral'
import RegistroCapacitaciones from './components/RegistroPersonal/Configuracion/RegistroCapacitaciones'


function Rutas() {
  let location = "" + window.location;
  const qr = location.indexOf("/obtenerQr/")
  const { loggedIn } = useContext(authContext);

  return (

    <HashRouter>
       {(qr<0) && (                  <>   
      <NavBar />
      </>
                )}
      <div className="col-12 col-md-10 mx-auto pt-5 pb-5">
        <div className="pt-5">

          <Routes>
            {loggedIn.statusLogin === true && (
              <>
              
                <Route  path="/dashboard" element={<DashboardMain/>}/>
                <Route  path="/proyecciones" element={<ProyeccionesList/>} />
                <Route  path="/proyeccionesResumenLotes" element={<ProyeccionesList/>} />
                <Route  path="/reporteProyeccion" element={<ReporteProyeccion/>} />
                <Route  path="/lotes" element={<LoteList/>} />
                <Route  path="/new-lote" element={<LoteForm/>} />
                <Route  path="/update-lote/:id" element={<LoteForm/>} />
                <Route  path="/cogidas" element={<CogidaList/>} />
                <Route  path="/new-cogida" element={<CogidaForm/>} />
                <Route  path="/update-cogida/:id" element={<CogidaForm/>} />
                <Route  path="/almacenMPIndex" element={<AlmacenMPIndex/>} />
                <Route  path="/ubicacionPallets" element={<UbicacionPallets/>} />
                <Route  path="/pruebas" element={<Pruebas/>} />
                <Route  path="/parteDiario" element={<ParteDiario/>} />
                <Route  path="/detalleNivel" element={<DetalleNivel/>} />
                <Route  path="/salidaPallet" element={<SalidaPallet/>} />
                <Route  path="/AdministrarAPT" element={<AdministrarAPT/>} />

                <Route  path="/obtenerQr/:data" element={<DibujarQr/>} />                
                
                <Route  path="/almacenAlsurperu" element={<AlmacenMP/>} />
                
                <Route  path="/almacenAlsurperuExt" element={<AlmacenAlsurExt/>} />
                <Route  path="/almacenCalzado" element={<AlmacenCalzado/>} />
                <Route  path="/almCongeladoAqp" element={<AlmCongeladoAqp/>} />
                <Route  path="/almacenesIndex" element={<AlmacenesIndex/>} />

                <Route  path="/almCuscoApt" element={<AlmacenCuscoAPT/>} />
                <Route  path="/almTransito" element={<AlmacenTransito/>} />
                <Route  path="/almCuscoCamara" element={<AlmacenCuscoCamara/>} />

                <Route  path="/IndexReportesAlm" element={<IndexReportesAlm/>} />
                <Route  path="/TrazabilidadAlmacen" element={<TrazabilidadAlmacen/>} />
                {loggedIn.type !== 'VISITA' &&(
                  <>
                    <Route  path="/marcadorCap" element={<MarcadorCap/>}/>
                    <Route  path="/ReporteGeneral" element={<ReporteGeneral />}/>
                    <Route  path="/configRegCap" element={<RegistroCapacitaciones/>}/>
                    
                                  
                    <Route  path="/proveedor" element={<ProveedorList/>} />
                    <Route  path="/new-proveedor" element={<ProveedorForm/>} />
                    <Route  path="/update-proveedor/:id" element={<ProveedorForm/>} />
                  </>
                )}
                    <Route  path="/pagosTransportista" element={<PagoTransporteList/>} />
                    <Route  path="/pagosAgricultores" element={<PagoAgricultorList/>} />
                {loggedIn.type === 'ADMINISTRADOR' && (
                  <>                    
                    <Route  path="/usuarios" element={<UsuarioList/>} />
                    <Route  path="/new-usuario" element={<UsuarioForm/>} />
                    <Route  path="/update-usuario/:id" element={<UsuarioForm/>} />
                    <Route  path="/localidad" element={<LocalidadList/>} />
                    <Route  path="/new-localidad" element={<LocalidadForm/>} />
                    <Route  path="/update-localidad/:id" element={<LocalidadForm/>} />                   
                    <Route  path="/departamentos" element={<DepartamentoList/>} />
                  </>
                )}
                {(loggedIn.type === 'ADMINISTRADOR' || loggedIn.type ==='CALIDADPRODUCCION') && (
                  <>
                  <Route  path="/inputsCalidadProd" element={<Inputs/>} />
                  <Route  path="/Permisos" element={<Permisos/>} />
                  <Route  path="/FormInputs/:id" element={<FormInputs />}/>
                  <Route  path="/ResumenInputs" element={<ResumenInputs />}/>
                  <Route  path="/ParametrosInputs" element={<Parametros />}/>
                  <Route  path="/RegistroObjetivos" element={<RegObjetivos />}/>
                  <Route  path="/InputRegMas" element={<InputRegMas/>}/>
                  </>
                )}
              </>
            )}


            {loggedIn.statusLogin === false &&
              <>
                <Route  path="/login" element={<Login/>} />
              </>
            }

          </Routes>

          <ToastContainer />

        </div>
      </div>
      {(qr<0) && (                  <>   
      <div className="footer">
         ALSURPERU 2024
      </div>
      </>
                )}



    </HashRouter>
  )
}

export default Rutas
