import React, { MouseEventHandler,FormEvent, useEffect, useState } from 'react'
import {Modal } from 'react-bootstrap';
import { input, listRegInputs } from '../typesCalidadProduccion'

import * as calidadProdServices from '../CalidadProduccionServices'
import { toast } from 'react-toastify';
import moment from 'moment';
interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>,
    input: input,
    idUsuario: string,
    registroInput: listRegInputs,
    afterHidenModal: () => void
}

function ModalFormInput(props: Props|any) {
    const initialRegInput = {_id: '', idInput: '', idUsuario:'', cantidad:0, fechaRegistro:''}
    const [registroInput, setRegistroInput] = useState(initialRegInput)
    const submitRegistroInput = async(e: FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
       if (props.registroInput._id) {
           console.log(registroInput)        
        console.log("SE DESEA EDITAR")
        const resUpdate = await calidadProdServices.editRegistroInput(registroInput);
        toast.success("Registro Editado")
        props.afterHidenModal();
        setRegistroInput(initialRegInput)
       }else{
        const resSubmit = await calidadProdServices.createRegistroInput(registroInput)
        if (resSubmit.data.status===604) {
            return toast.error(resSubmit.data.message)
        }else{            
            toast.success("Registro Guardado!")
        }  
        props.afterHidenModal();
        setRegistroInput(initialRegInput)
       }      
    }
    const changeFormRegInput = (e: any) =>{
        setRegistroInput({...registroInput, [e.target.name]: e.target.value})
    }
    useEffect(() => {
        if (props.show){
            setRegistroInput({...registroInput, idInput: props.input._id, idUsuario: props.idUsuario})
        }else{
            setRegistroInput(initialRegInput)
        }
        if (props.registroInput._id) {
            setRegistroInput({...registroInput,_id: props.registroInput._id,idInput: props.input._id, idUsuario: props.idUsuario, fechaRegistro: moment(props.registroInput.fechaRegistro).format('YYYY-MM-DD'), cantidad: props.registroInput.cantidad})
        }
    }, [props.show])
    return (
        <div className="pt-5">
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered                
            >
                <Modal.Header closeButton>
                   

                </Modal.Header>
                <Modal.Body>
                    <div className="text-center col-12 col-sm-12 col-md-6 mx-auto pb-3"> 
                        {/* <p className="text-info h4">Nuevo Registro</p>                        */}
                        <h3 className="text-info font-weight-bold">{props.input.nameInput}</h3>
                    </div>
                    <form onSubmit={submitRegistroInput}>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="p-3 ">
                        <label htmlFor="" className="text-info">Fecha</label>
                        <input className="form-control form-control-lg border-info " 
                            name="fechaRegistro" 
                            type="date"
                            onChange={changeFormRegInput} 
                            value={registroInput.fechaRegistro}
                            required/>
                    </div>
                    <div className="p-3">
                        <label htmlFor=""  className="text-info">Cantidad</label>
                        <div className="row align-items-center">
                        <input className="form-control form-control-lg border-info col-12 col-sm-8" type="number" step="any" min="0" 
                            name="cantidad" 
                            onChange={changeFormRegInput} 
                            value={registroInput.cantidad}
                            required/>
                        <h5 className='col-12 col-sm-4 text-center text-info font-weight-bold'>{props.input.uMedida.nombre}</h5>
                        </div>
                    </div>
                </div>
                <div className="text-center p-2">
                    <button className={`${props.registroInput._id?'btn btn-danger btn-lg':'btn btn-info btn-lg'}`}>{props.registroInput._id?'EDITAR':'CREAR'} REGISTRO</button>
                </div>
            </form>
                </Modal.Body>
                <Modal.Footer>    
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalFormInput
