import moment from 'moment';
import React, { MouseEventHandler, useState, useEffect, ChangeEvent } from 'react'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as almacenesService from '../AlmacenesService'
import { producto, detSalida, almacenes, detIngresosDisp} from '../TypesAlmacenes'
import {
    Input, Label, Select, Option, Container, FormContainer, Form
    , ContainerInput
    , ButtonForm, InputTextArea
    
} from './ModalSalidaElements'

interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>,
    idSalida: string | undefined,
    numeroSalida: number,
    detalleSalida: detSalida | undefined,
    idAlmacen:almacenes | undefined,
    functionCloseModal: ()=> void
}

function DetSalida(props: Props|any) {

    const stateInitialDetSalida = {
        _id:'',
        idIngresoRef: '',
        numIngresoRef: 0,
        idDetIngresoRef: '',
        estado: 'ATENDIDO TOTAL',
        fechaSalida:'',
        ordenCompra: '', 
        ordenSiembra: '',
        detalle: '', 
        cantidad: 1,
        unidadMedida:'',
        lote: '',
        precio: 0,
        igv: 0, 
        total: 0,
        salida: '',
        numeroSalida: 0,        
        producto: { _id: '', nombre: '' }         
    }
    const [detSalida, setDetSalida] = useState<detSalida>(stateInitialDetSalida)
    const [optProductos, setOptProductos] = useState<producto[]>([])
    const [optDetIngresosDisponibles, setOptDetIngresosDisponibles] = useState<detIngresosDisp[]>([])
    const [cantidadDisponible, setCantidadDisponible] = useState(0);
    const [stateIgv, setStateIgv] = useState('NO')
    const [fechaLimiteSalida, setFechaLimiteSalida] = useState('')
    // const [optProd, setoptProd] = useState(initialState)

    const resetFormDetSalida = () =>{
        setDetSalida(stateInitialDetSalida)
        setOptDetIngresosDisponibles([])
        setOptProductos([]);
        setCantidadDisponible(0)
        setStateIgv('NO')
        setFechaLimiteSalida('')
    }
    const submitDetSalida = async(e: any) => {
        e.preventDefault();
       if (!detSalida._id) {
            const resDetSalida = await almacenesService.createDetSalida(detSalida);
            console.log(resDetSalida);
            if (resDetSalida.status === 200){
                // props.functionCloseModal();
                resetFormDetSalida();
                props.functionCloseModal();
                return toast.success("Detalle Salida Guardado")
            }else{   
                           
                return toast.error("Error al guardar detalle, Intentar nuevamente o comuniquese con el area de Sistemas")
            }  
                     
       }       
    }
    const busquedaProducto = async (e: any) => {

        if (e.key === 'Enter' && props.idAlmacen?._id) {
           
            e.preventDefault();
            const resBusquedaProd = await almacenesService.getProductoByText(e.target.value)
            setOptProductos(resBusquedaProd.data)
        }
    }
    const handleChangeDetSalida = (e:any) => {
        setDetSalida({...detSalida, [e.target.name]: e.target.value});
    }

    const changeOptProducto = async (e:any)=>{
       if (props.idAlmacen?._id) {
        console.log(e.target.value)
        const dataToSend= {
            idAlmacen: props.idAlmacen._id,
            idProducto: e.target.value
        }
        const resDetSalidasDisponibles = await almacenesService.getDetSalidasDisponiblesToSalida(dataToSend);
        console.log(resDetSalidasDisponibles);
        setOptDetIngresosDisponibles(resDetSalidasDisponibles.data)
       }
    }
    const changeIngDisp = async(e:any)=>{
        console.log(e.target.value)
        const detIngresoSelected = optDetIngresosDisponibles.find((detIngDisp)=> detIngDisp._id===e.target.value);
        console.log(detIngresoSelected);
        const today = new Date();
        if (detIngresoSelected) {
            setDetSalida({...detSalida, idIngresoRef: detIngresoSelected.ingreso._id, 
                            numIngresoRef: detIngresoSelected.ingreso.numero,
                            idDetIngresoRef: detIngresoSelected._id,
                            ordenCompra: detIngresoSelected.ordenCompra,
                            // ordenSiembra: detIngresoSelected.ordenSiembra,
                            lote: detIngresoSelected.lote,
                            producto: detIngresoSelected.producto,
                            unidadMedida: detIngresoSelected.unidadMedida,
                            fechaSalida: moment(today).format('YYYY-MM-DD')
                        })            
            setCantidadDisponible(detIngresoSelected.cantidadDisponible)
            setFechaLimiteSalida(moment(detIngresoSelected.ingreso.fechaIngreso).format('YYYY-MM-DD'))
        }
    }
    const verifyIgv = ()=>{
        if (stateIgv==='SI') {
            const igv = parseFloat((detSalida.precio*0.18).toString()).toFixed(2);
            const total = parseFloat((detSalida.precio*1.18).toString()).toFixed(2);
            setDetSalida({...detSalida, igv: parseFloat(igv) , total: parseFloat(total) })
        }else if(stateIgv==='NO'){
            setDetSalida({...detSalida, igv: 0, total: detSalida.precio }) 
        }
    }
    const handleChangeIgv = (e:any) =>{
        setStateIgv(e.target.value);
        
    }
    useEffect(() => {
        verifyIgv();
    }, [detSalida.precio, stateIgv])
    useEffect(() => {
        if (props.show && props.idSalida) {
            setDetSalida({...detSalida, salida: props.idSalida, numeroSalida: props.numeroSalida})
        }else{
            resetFormDetSalida();
        }
    }, [props.show])
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Almacen: {props.idAlmacen?.nombre}  {detSalida._id ? ('Edicion') : ('Creacion')} Detalle - Salida:{props.numeroSalida}                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                    <FormContainer align={'flex-start'}>
                                <ContainerInput size={'200px'}>
                                    <Label htmlFor='busquedaIngreso'>Busq. Producto</Label>
                                    <Input type='text' id='busquedaIngreso' name='busquedaIngreso' bg={'#fff'}
                                        onKeyPress={busquedaProducto}
                                    />
                                </ContainerInput>
                                <ContainerInput size={'250px'}>
                                    <Label htmlFor='productos'>Productos</Label>
                                    <Select id='productos' name='productos' bg={'#fff'}
                                        onChange={changeOptProducto}
                                    >
                                        <Option value="" selected>SELECCIONE</Option>)
                                        {optProductos.map((producto) => {
                                            return <Option key={producto._id} value={producto._id}>{producto.nombre}</Option>
                                        })
                                        } 
                                    </Select>
                                </ContainerInput>
                                <ContainerInput size={'500px'}>
                                    <Label htmlFor='ingresosDisponibles'>Ingresos Disponibles</Label>
                                    <Select id='ingresosDisponibles' name='ingresosDisponibles' bg={'#ffffff76'}
                                        onChange={changeIngDisp}
                                    >
                                        <Option value="" selected>SELECCIONE</Option>)
                                        {optDetIngresosDisponibles.map((detIngDisp) => {
                                            return <Option key={detIngDisp._id} value={detIngDisp._id}>{detIngDisp.producto.nombre} Disponible:{detIngDisp.cantidadDisponible} Ingreso:{detIngDisp.numeroIngreso}</Option>
                                        })
                                        } 
                                    </Select>
                                </ContainerInput>                                
                            </FormContainer>
                        <Form onSubmit={submitDetSalida}>                            
                            <FormContainer align={'flex-start'}>
                                <ContainerInput size={'200px'}>
                                    <Label htmlFor='estado'> Estado</Label>
                                    <Input type='text' id='estado' name='estado' bg={'#21332636'}
                                        value={detSalida.estado}
                                        readOnly
                                        required
                                    />
                                </ContainerInput>
                                <ContainerInput size={'100px'}>
                                    <Label htmlFor='numIngresoRef'>Ref Ingreso</Label>
                                    <Input type='text' id='numIngresoRef' name='numIngresoRef' bg={'#21332636'} 
                                        value={detSalida.numIngresoRef}                                       
                                        readOnly
                                    />                                   
                                </ContainerInput>
                                <ContainerInput size={'250px'}>
                                    <Label htmlFor='producto'>Producto</Label>
                                    <Input type='text' id='producto' name='producto' bg={'#21332636'} 
                                        value={detSalida.producto.nombre}                                       
                                        readOnly
                                    />                                   
                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='lote'>Lote</Label>
                                    <Input type='text' id='lote' name='lote' bg={'#21332636'}
                                        value={detSalida.lote}
                                        onChange={handleChangeDetSalida}
                                        required readOnly/>
                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='ordenCompra'>Orden Compra</Label>
                                    <Input type='text' id='ordenCompra' name='ordenCompra' bg={'#21332636'}
                                        value={detSalida.ordenCompra}
                                        onChange={handleChangeDetSalida}
                                        required readOnly/>
                                </ContainerInput>
                               
                                <ContainerInput size={'220px'}>
                                    <Label htmlFor='fechaSalida'>Fecha Salida</Label>
                                    <Input type='Date'  min={fechaLimiteSalida}  id='fechaSalida' name='fechaSalida' bg={'#fff'}
                                        value={detSalida.fechaSalida}
                                        onChange={handleChangeDetSalida}
                                        required
                                    />
                                </ContainerInput>
                                <ContainerInput size={'120px'}>
                                    <Label htmlFor='cantidad'>Cantidad</Label>
                                    <Input type='number' step='any' min={1} max={cantidadDisponible} id='cantidad' name='cantidad' bg={'#fff'}
                                        value={detSalida.cantidad}
                                        onChange={handleChangeDetSalida}
                                        required
                                    />
                                </ContainerInput>
                                <ContainerInput size={'120px'}>
                                    <Label htmlFor='unidadMedida'>UM</Label>
                                    <Input type='text' id='unidadMedida' name='unidadMedida' bg={'#21332636'}
                                        value={detSalida.unidadMedida}                                                                                                                                                              
                                        readOnly
                                    />
                                </ContainerInput>
                                <ContainerInput size={'120px'}>
                                    <Label htmlFor='cantidadDisponible'>Cantidad Disponible</Label>
                                    <Input type='number' step='any' id='cantidadDisponible' name='cantidadDisponible' bg={'#21332636'}
                                        value={cantidadDisponible}                                                                                                                                                              
                                        readOnly
                                    />
                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='precio'>Precio</Label>
                                    <Input type='number' step='any' id='precio' name='precio' bg={'#fff'}
                                        value={detSalida.precio}
                                        onChange={handleChangeDetSalida}
                                        required
                                        
                                    />
                                </ContainerInput>
                                <ContainerInput size={'100px'}>
                                    <Label htmlFor='igv'>Igv?</Label>
                                    <Select id='igv' name='igv' bg={'#fff'}
                                    onChange={handleChangeIgv}
                                    >
                                        <Option value="NO" selected>NO</Option>
                                        <Option value="SI">SI</Option>
                                    </Select>
                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='igv'>Monto Igv</Label>
                                    <Input type='number' step='any' id='igv' name='igv' bg={'#21332636'}
                                        value={detSalida.igv}
                                        required
                                        readOnly
                                    />

                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='total'>Total</Label>
                                    <Input type='number' step='any' min={0} id='total' name='total' bg={'#21332636'}
                                        value={detSalida.total}
                                        onChange={handleChangeDetSalida}
                                        required
                                        readOnly
                                    />

                                </ContainerInput>
                                <ContainerInput size={'100%'}>
                                    <Label htmlFor='detalle'>Detalle</Label>
                                    <InputTextArea bg={'#fff'} cols={30} rows={2} id='detalle' name='detalle'
                                        value={detSalida.detalle}
                                        onChange={handleChangeDetSalida}
                                    ></InputTextArea>

                                </ContainerInput>

                            </FormContainer>
                            <FormContainer align={'center'}>
                                <ContainerInput size={'300px'}>
                                    <ButtonForm>{detSalida._id ? ('EDITAR') : ('CREAR')} DETALLE</ButtonForm>
                                </ContainerInput>

                            </FormContainer>
                        </Form>


                    </Container>


                </Modal.Body>
                <Modal.Footer>
                    <FormContainer align={'end'}>
                        <ContainerInput size={'100px'}>
                            <ButtonForm
                                onClick={props.onHide}
                            >SALIR</ButtonForm>
                        </ContainerInput>

                    </FormContainer>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DetSalida
