import React, { useState, useEffect } from 'react'
import { Pallets, Bases } from '../almacen'
import { formatos } from '../../ModalBox/boxModal'
import ModalBox from '../../ModalBox/ModalBox'
import * as almacenesServices from './AlmacenTransitoServices'
import IndexMapas from '../../IndexMapas/IndexMapas'
import ModalPaletizado from '../../ModalPaletizadoTransito/ModalPaletizado'

import { TitleH1 } from './AlmacenTransitoElements'
import BoxMP from '../../BoxMP/BoxMP'
import ModalSalida from '../../ModalSalida/ModalSalida'
import { TooltipTarget } from '../../BoxMP/BoxMPElements'
import { toast } from 'react-toastify'
import {
    BtnBusqueda,
    AlmacenContainer,
    Paletizado
} from './AlmacenTransitoElements'
function AlmacenAlsurExt() {
    const [Info, setInfo] = useState<Pallets[]>([])
    const [modalBox, setmodalBox] = useState({ estadoModal: false, base: [] })
    const [bases, setBases] = useState<Bases[]>([])
    const [palletBuscado, setPalletBuscado] = useState({ codigoPallet: '' })
    const [textSearchFormat, setTextSearchFormat] = useState('')
    const [listFormatos, setListFormatos] = useState<formatos[]>([])
    const [formatoSelected, setFormatoSelected] = useState('')
    const [modalPaletizado, setModalPaletizado] = useState(false);
    const [flagState, setFlagState] = useState(false)
    const [stateModalSalida, setStateModalSalida] = useState(false)
    const changeFlagState = () =>{
        setFlagState(true)
    }
    const openModalSalida = () =>{
        setStateModalSalida(true);
    }
    const closeModalSalida = () =>{
        if (flagState) {
            selectBases(); 
        }
        setStateModalSalida(false);
    }
    const handleCloseModal = () => {
        if (flagState) {
            selectBases(); 
        }
        setmodalBox({ ...modalBox, estadoModal: false, base: [] })
    };
    const handleShowModal = (base: any) => {
        setmodalBox({ ...modalBox, estadoModal: true, base: base });
    }


    // setInfo(Data);
    // console.log(Info)
    //Aqui se coloca el código del Almacen
    const selectBases = async () => {
        const resBases = await almacenesServices.getBases('65206c0eec5ec49a72518bbf');
        setBases(resBases.data)
        console.log("SE ESTA EJECUTANDO ESTA MADRE")
    }
    const setBusquedaPallet = async (e: any) => {
        setPalletBuscado({ codigoPallet: e.target.value })

    }
    const BuscarPallet = async () => {
        try {
            // console.log(palletBuscado.codigoPallet)
            const textBusqueda = palletBuscado.codigoPallet;
            if (textBusqueda && formatoSelected) return toast.info("Por favor busque un campo a la vez")
            if (textBusqueda) {
                const resBase = await almacenesServices.busquedaBaseByNumPallet(textBusqueda)
                // console.log(resBase.data)            
                if (resBase.data[0]._id) {
                    const resIDBase = resBase.data[0]._id;
                    const busquedaPalletByBase: any = bases.find((base) => base._id === resIDBase);
                    const busquedaPalletByBaseIndex: any = bases.findIndex((base) => base._id === resIDBase);
                    let copyBases = [...bases];
                    let copyBase = { ...copyBases[busquedaPalletByBaseIndex] }
                    copyBase.busqueda = true;
                    copyBases[busquedaPalletByBaseIndex] = copyBase;
                    setBases(copyBases)
                    return toast.success("Pallet Encontrado")
                } else {
                    return toast.error("Pallet no encontrado")
                }
            } else {
                const dataToSearch = {
                    formato: formatoSelected,
                    almacen: '61534152431a5c10acf19d9f'
                }
                const resBase = await almacenesServices.busquedaBaseByFormato(dataToSearch)
                console.log(resBase.data)
                var copyBases = [...bases];
                for (let i = 0; i < resBase.data.base.length; i++) {
                    const oneIdBase = resBase.data.base[i];
                    console.log(oneIdBase.base._id)
                    const busquedaPalletByBaseIndex: any = bases.findIndex((base) => base._id === oneIdBase.base._id);
                    
                    let copyBase = { ...copyBases[busquedaPalletByBaseIndex] }
                    copyBase.busqueda = true;
                    copyBases[busquedaPalletByBaseIndex] = copyBase;
                    
                }
                setBases(copyBases)
                toast.success('Produccion total '+resBase.data.totalProduccion)
            }


        } catch (error:any) {
            toast.error(error.message)
        }
        //    base[busquedaPalletByBaseIndex] = {...base[busquedaPalletByBaseIndex]}
    }
   
    const handleChangeFormato = (e: any) => {
        setFormatoSelected(e.target.value)
    }
    const ChangeTextSearchFormat = (e: any) => {
        setTextSearchFormat(e.target.value)
    }
    const busquedaTextFormato = async () => {
        console.log(textSearchFormat);
        if (!textSearchFormat) {
            setFormatoSelected('')
            setListFormatos([])
        } else {
            const resBusquedaFormato = await almacenesServices.busquedaFormato(textSearchFormat)
            setListFormatos(resBusquedaFormato.data);
        }
    }
    const handleModalPaletizado = async() =>{
        setModalPaletizado(true);
    }
    const handleCloseModalPaletizado = ()=>{
        setModalPaletizado(false)
        selectBases();
    }
    useEffect(() => {

        selectBases();
    }, [])
    return (
        <>
            <IndexMapas />
            <TitleH1>ALMACEN TRANSITO</TitleH1>
            <div className=" row align-items-end justify-content-start m-1">
                <div className="col-8 col-md-2">

                    <input type="text" className="form-control " name="bformato"
                        onChange={ChangeTextSearchFormat}
                        value={textSearchFormat}
                    />
                </div>
                {/* <span className="col-4 btn btn-block btn-dark "><i className="fas fa-search"></i></span> */}
                <div className="col-4 col-md-1">
                    <span className=" btn btn-block btn-dark" onClick={busquedaTextFormato} ><i className="fas fa-search"></i></span>
                </div>
                <div className="col-12 col-md-2">

                    <label htmlFor="formato">Codigo - Formato</label>
                    <select className="form-control " name="formato" onChange={handleChangeFormato} required >

                        {listFormatos ? (<><option value="" selected>Seleccione</option></>) : (<>  </>)}

                        {listFormatos.map((formato) => {
                            return (
                                <option value={formato._id} >{formato.codigo} - {formato.descripcion}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-8 col-md-2 pt-1">
                    <input className="form-control text-center" type="text" value={palletBuscado.codigoPallet} onChange={setBusquedaPallet} placeholder="Numero Pallet" />
                </div>
                <div className="col-12 col-md-auto">
                    <BtnBusqueda onClick={BuscarPallet} > Buscar</BtnBusqueda>
                </div>
                <div className="col-12 col-md-2">
                <span className=" btn btn-lg btn-block btn-warning text-center" onClick={openModalSalida}  ><span className="h3">+</span> Salida</span>
                </div>
            </div>
            <AlmacenContainer>

                <Paletizado onClick={handleModalPaletizado}>       
                        Paletizado
                </Paletizado>

            </AlmacenContainer>
            <ModalBox base={modalBox.base} show={modalBox.estadoModal} onHide={handleCloseModal} checkFlagState={changeFlagState}/>
            <ModalPaletizado show={modalPaletizado} onHide={handleCloseModalPaletizado}/>
            <ModalSalida show={stateModalSalida} onHide={closeModalSalida} idAlmacen={'61534152431a5c10acf19d9f'}/>
        </>
    )
}

export default AlmacenAlsurExt
