import React,{useState, useRef} from 'react'
import {Box, BoxWrapper, CenterContainer, TooltipTarget, TooltipBox} from './BoxMPElements'
import * as almacenesServices from '../AlmacenMP/AlmacenMPServices'
import Swal from 'sweetalert2'
interface props{
    text: string,
    position: string,
    children: any,
    idPallet: string,
    matriz: string,
    palletFrom: any,
    palletTo: any
}
function BoxMP({idPallet,matriz, text, position, children, palletFrom, palletTo }: props) {
    const [isHovered, setIsHovered] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const targetRef = useRef(null);
    const showTooltip = isHovered || isFocused; //isHovered or isFocused

    const startDrag = (evt:any) =>{
        evt.dataTransfer.setData('itemID', idPallet)
        evt.dataTransfer.setData('matriz', matriz)
        evt.dataTransfer.setData('pallets', text)
    }

    async function mover  (desde: string, hasta: string) {
        console.log("ejecutando traslado")
        const resBase = await almacenesServices.reubicarPallets(desde, hasta)
        

    }

    const endDrop = (evt:any) =>{
        evt.preventDefault();
        let pallets = evt.dataTransfer.getData('pallets')
        let desde = evt.dataTransfer.getData('itemID')
        let oldMatriz = evt.dataTransfer.getData('matriz')
        let detPallets = pallets.split(",")
        if( detPallets.length>0 &&(desde!=idPallet)){
            Swal.fire({
                title: "Confirme",
                text: "Estas seguro de mover todos los Pallets desde '"+ oldMatriz + "' a '" + matriz + "' ?",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, Mover",
                cancelButtonText: "No, Cancelar"
              }).
              then((result) => {
                if (result.isConfirmed) {
                    palletFrom(desde, idPallet);
                    mover(desde, idPallet)
                }
              });

        }
    }

    const dragOver = (evt:any) =>{
        evt.preventDefault();
    }



    return (
        <>
        <BoxWrapper draggable onDragStart={(evt)=>startDrag(evt)} onDragOver={(evt)=>dragOver(evt)} onDrop={endDrop}   >
        {/* <Box>texto</Box> */}
            <TooltipTarget
                onMouseEnter={()=>setIsHovered(true)}
                onMouseLeave={()=> setIsHovered(false)}
                colorBG={0}
                idPallet={""}
                buscado={false}
            >{children}
            </TooltipTarget>
            {showTooltip && (
                <CenterContainer position={position}>
                    <TooltipBox position={position}>{text}                    
                    </TooltipBox>
                </CenterContainer>
            )}
        </BoxWrapper>
            
        </>
    )
}

export default BoxMP
