import React, { MouseEventHandler, useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {Pcliente, cliente, palletToSal, tipoMovimientoPlanta, salidaPallet } from '../AlmacenMP/almacen'
import * as AlmacenMPService from '../AlmacenMP/AlmacenMPServices'
import * as almacenServices from '../../Almacenes/AlmacenesService'
interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>|any,
    idAlmacen: string
    // onHideFun: () => void
}
function ModalSalida(props: Props) {
    const [tipoSalida, setTipoSalida] = useState<tipoMovimientoPlanta[]>([])
    const [listClientes, setListClientes] = useState<Pcliente[]>([])
    const [listPallets, setListPallets] = useState<palletToSal[]>([])
    const [actualPallet, setActualPallet] = useState('');
    const [mainDataSalida, setMainDataSalida] = useState<salidaPallet>({
        _id: '',
        numero: 0,
        tipoSalida: {
            _id: '',
            descripcion: ''
        },
        fecha: '',
        cliente: '',
        numeroOrden: '',
        detPallets: []
    })

    const handleChangeMainDataSalida = (e: any) => {

        setMainDataSalida({ ...mainDataSalida, [e.target.name]: e.target.value })
    }
    const handleActualPallet = (e: any) => {
        try {            
        setActualPallet(e.target.value)
        } catch (error:any) {
            toast.error(error.message)
        }
    }
    const [textBusCli, setTextBusCli] = useState('')
    const [textBusPallet, setTextBusPallet] = useState('')

    const changeTextBusCli = (e: any) => {
        setTextBusCli(e.target.value)
    }
    const changeTextBusPallet = (e: any) => {
        setTextBusPallet(e.target.value)
    }
    const buscarCliente = async () => {

        // console.log(textBusCli)
        const resClientes = await AlmacenMPService.busquedaPcliente(textBusCli);
        setListClientes(resClientes.data)
        console.log(resClientes)
    }
    const buscarPallet = async () => {
        if (textBusPallet === '') {
            return toast.info('El campo de busqueda esta en blanco')
        }
        const resPallets = await AlmacenMPService.palletToSalida(textBusPallet);
        setListPallets(resPallets.data)
    }
    const getTipoMovimientos = async () => {
        const resTipoMovimientos = await almacenServices.getTipoMovimientoByDocumento('SALIDAS PLANTA');
        // console.log(resTipoMovimientos)
        setTipoSalida(resTipoMovimientos.data);
    }
    const addPalletToList = () => {
        setListPallets([])
        try {
            // console.log(actualPallet);
            const findPallet: any = listPallets.find((pallets) => pallets._id === actualPallet);
            // console.log(findPallet);
            if (mainDataSalida.detPallets.length > 0) {
                const findDuplicado = mainDataSalida.detPallets.find((mainPallet: any) => mainPallet._id === actualPallet)
                if (findDuplicado) return toast.error('Pallet ya listado')
            }

            
            // mainDataSalida.detPallets.push(findPallet)
            setMainDataSalida({...mainDataSalida, detPallets: [...mainDataSalida.detPallets, findPallet]})
            toast.success('Pallet Añadido')
        } catch (error) {
            toast.error("Error en busqueda consulte con Sistemas");
        }
    }
    const quitarPalletFromDet = (id: string) =>{
        // console.log(id)
        const indexRemove = mainDataSalida.detPallets.findIndex((pallet: palletToSal)=> pallet._id=== id)
      
        if (indexRemove !== -1) {
                
            var newDetPallets = mainDataSalida.detPallets;
            
            newDetPallets.splice(indexRemove, 1);
            
        setMainDataSalida({...mainDataSalida, detPallets: newDetPallets})
        }
    }
    const saveSalida = async (e:any) =>{
        e.preventDefault();
        try {
            if (mainDataSalida.detPallets.length === 0) {
                    return toast.error('Pallets no asignados')
            }
            const resCreateSalidaPallet =  await AlmacenMPService.createSalidaPallet(mainDataSalida)
            if (resCreateSalidaPallet.status===200) return toast.success('Guardado!')
            return toast.error('Error!')
                
            
        } catch (error:any) {
            toast.error(error.message);
        }
    }
    useEffect(() => {
        if (props.show === true) {
            getTipoMovimientos();
        } else {
            setTipoSalida([])
            setMainDataSalida({...mainDataSalida, detPallets: []})
        }

    }, [props.show])
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Salida Pallets
                        {/* Pallet {props.base.matris} */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form action="" id="formSalidaPallet" onSubmit={saveSalida}>
                    <div className='d-flex row align-items-end justify-content-start'>
                        
                        <div className='col-sm-12 col-md-1 col-12'>
                            <label htmlFor="">Numero</label>
                            <input className='form-control form-control-lg' name='numero' type="number"
                                value={mainDataSalida.numero}
                                onChange={handleChangeMainDataSalida}
                            />
                        </div>
                        <div className='col-sm-12 col-md-3 col-12'>
                            <label htmlFor="">Tipo Salida</label>
                            <select className='form-control form-control-lg' name="tipoSalida" id="tipoSalida"
                                onChange={handleChangeMainDataSalida}
                                required
                            >

                                {mainDataSalida._id ? (<option value={mainDataSalida.tipoSalida._id}>{mainDataSalida.tipoSalida.descripcion}</option>) : (<option value=''>SELECCIONE</option>)}
                                {tipoSalida.map((salida) => {
                                    return (
                                        <option value={salida._id}>{salida.descripcion} - {salida.abreviado}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className='col-sm-12 col-md-2 col-8'>
                            <label htmlFor="">Bus. Cliente</label>
                            <input className=' form-control form-control-lg' type="text"
                                value={textBusCli}
                                onChange={changeTextBusCli}
                            />
                        </div>
                        <div className='col-1'>
                            <span className=' btn btn-lg btn-outline-dark' onClick={buscarCliente}>Buscar</span>
                        </div>
                        <div className='col-12 col-sm-12 col-md-3 '>
                            <label htmlFor="">Cliente</label>
                            <select className='form-control form-control-lg' name="cliente" id="cliente"
                                onChange={handleChangeMainDataSalida}
                                required
                                >
                                <option value="">Seleccione</option>
                                {listClientes.map((clientes) => {
                                    return (
                                        <option value={clientes._id} >{clientes.nombre}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className='col-sm-12 col-md-2 col-12'>
                            <label htmlFor="">Numero Orden</label>
                            <input className='form-control form-control-lg' name="numeroOrden" type="text"
                                onChange={handleChangeMainDataSalida}  required/>
                        </div>
                        <div className='col-sm-12 col-md-3 col-12'>
                            <label htmlFor="">Fecha</label>
                            <input className='form-control form-control-lg' name="fecha" type="date"
                                onChange={handleChangeMainDataSalida} required/>
                        </div>
                        <div className='col-sm-12 col-md-2 col-8'>
                            <label htmlFor="">Bus. Pallet</label>
                            <input className=' form-control form-control-lg' type="text"
                                onChange={changeTextBusPallet}
                                value={textBusPallet}
                            />
                        </div>
                        <div className='col-1'>
                            <span className=' btn btn-lg btn-outline-dark' onClick={buscarPallet}>Buscar</span>
                        </div>
                        <div className=' col-sm-12 col-md-3 col-12'>
                            <label htmlFor="listPallet">Pallet</label>
                            <select className='form-control form-control-lg' name="listPallet" id="listPallet"
                                onChange={handleActualPallet}>
                                <option value="">Seleccione</option>
                                {listPallets.map((pallet) => {
                                    return (
                                        <option className='h4' value={pallet._id} >{pallet.numPallet} - {pallet.almacen?.nombre} </option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className='col-sm-12 col-md-2 col-12 mt-2'>
                            <span className=' btn btn-lg btn-success btn-block'
                                onClick={addPalletToList}>
                                + {mainDataSalida.detPallets.length>0? (mainDataSalida.detPallets.length):(0)}
                            </span>
                        </div> 
                      
                    </div>
                    </form>
                    <div id='detalletPallets' className='p-2'>
                        <div className="table-responsive  col-12 mx-auto  mb-5 bg-body rounded trheight tableFixHeadPallet">
                            <table className="table shadow">
                                <thead>
                                    <tr>
                                        <th scope="col">Codigo Pallet</th>
                                        <th scope="col">Matriz</th>
                                        <th scope="col">Zona</th>
                                        <th scope="col">Almacen</th>
                                        <th scope="col"></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {mainDataSalida.detPallets.map((pallets:palletToSal) => {
                                        return  ( <tr id={pallets._id} key={pallets._id}  >
                                                        <td>{pallets.numPallet}</td>
                                                        <td>{pallets.zona?.nombre}</td>
                                                        <td>{pallets.base?.matris}</td>
                                                        <td>{pallets.almacen?.nombre}</td>    
                                                        <td>
                                                            
                                                            <span className="btn btn-outline-danger m-2" onClick={()=> quitarPalletFromDet(pallets._id)} >Quitar</span>
                                                        </td>                                         
                                            </tr>  )                                      
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='submit' form='formSalidaPallet' variant="success btn-lg btn-block"
                        
                    >GUARDAR</Button>

                </Modal.Footer>
            </Modal>
            {/* <ModalConfirm show={modalConfirm} onHide={handleCloseModalConfirm} pallet={topPallet} afterModalConfirm={afterModalConfirm} esSalida={esSalida}/>
            <ModalConfirmDeleteDet show={modalConfirmDet} onHide={handleCloseModalConfirmDet} id={idPalletToDelete} afterModalConfirmDet={afterModalConfirmDet} />
            <ModalMover show={estadoModalMover} pallet={topPallet} onHide={handleCloseModalMover} afterModalConfirm={afterOkModalMover}/>
            <ModalDetalle show={modalDet} onHide={handleCloseModal} /> */}

        </>
    )
}

export default ModalSalida
