import React, {useState, MouseEventHandler } from 'react'
import { Button, Modal } from 'react-bootstrap';
import * as transporteCtrl from '../Transportistas/TrasportistaService'

import alertLogo from '../../images/alertLogo.png'
import { toast } from 'react-toastify';

interface Props {
    show: {
        estado: boolean,
        idTransportes: any[],
        solJaba: number,
        factura:string
    };
    onHide: MouseEventHandler<HTMLElement> | any,
    dataUpdated: () => void,
    btnModalType:string,
    styleModal:{
        title:string,
        color: string
    }
}
interface styleModal{
    title: string,
    color: string
}
function ModalConfirm({ show, onHide , dataUpdated, btnModalType, styleModal}: Props) {
    // console.log(show.idTransportes)
   
    const submitFlete= async ()=>{
        
      try {
        if(btnModalType==='btnSetFlete'){
            
            const resSetFlete = await transporteCtrl.setFleteTransporteServer(show);
            console.log(resSetFlete) 
            dataUpdated();  
             
        }else if (btnModalType==='btnSetFactura') {
            const resSetFactura = await transporteCtrl.setFacturaTransporteServer(show);
            dataUpdated();
            // console.log(show)
        }  
      } catch (error:any) {
          if (error.message==='Request failed with status code 330') {
              toast.error("Existen facturas ya asignadas en algun registro")
          }else{
            toast.error(error.message)
          }
          dataUpdated(); 
      }          
    }
    return (
        <div>
             <Modal centered show={show.estado} onHide={onHide}>
                <div className="col-12 text-center m-2">
                <h3 className={`text-${styleModal.color}`}>{styleModal.title}</h3>
                </div>
              
                <div className="col-6 mx-auto d-flex p-4">
                      
                    <button className="p-2 btn btn-outline-ligth" onClick={submitFlete}><strong className={`text-${styleModal.color}`}>Asignar</strong></button>
                    <button className="ml-auto p-2 btn btn-outline-dark" onClick={onHide}><strong>Cancelar</strong></button>
                </div>
               
            </Modal>
        </div>
    )
}

export default ModalConfirm
