import axios from 'axios'
import {departamento} from './departamento'

import apiURL from '../../context/apiURL'
const API = apiURL.API;

export const getDepartamentos = async () => {
   return await axios.get<departamento[]>(`${API}/departamentos`)    
}

export const createDepartamentos = async (departamento: departamento) => {
   return await axios.post(`${API}/departamentos`, departamento)    
}

export const getDepartamento = async (id: string) => {
  
   return await axios.get<departamento>(`${API}/departamento/${id}`)    
}