
import styled from 'styled-components'
interface cosechado {
    cosechado: boolean
}
interface containerInput {
    width: any
    // height: string
}
interface selectGeneral {
    height: string | undefined;
    bg: string,
}
interface selectGeneralSet {
    height: string | undefined;
    bg: string,
    width: string,
    textAlign: string
}
interface inputGeneral {
    width: string,
    height: string
}
interface buttonGeneral {
    width: string,
    height: string,
    color: string,
}
interface mainContainerSet {
    display: string,
    width: string,
    alignItems: string,
    flexWrap: string,
    justifyContent: string
}
interface h5title {
    color: string,
    bg: string,
    fontSize: string,
    width: string
}
export const MainContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
    flex-wrap:wrap;
    justify-content: center;
    @media screen and (max-width: 1000px){
        justify-content: center;
    }
`
export const MainContainerSet = styled.div<mainContainerSet>`
    display: ${({ display }) => display ? display : 'flex'};
    width: ${({ width }) => width ? width : '100%'};
    align-items: ${({alignItems}) => alignItems ? alignItems : 'flex-end'};
    flex-wrap:${({ flexWrap }) => flexWrap ? flexWrap : 'wrap'};
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'start'};
  
    @media screen and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        padding: 0;
        margin: 0;
    }
`
export const MainContainerSet2 = styled.div<mainContainerSet>`
    display: ${({ display }) => display ? display : 'flex'};
    border: 2px solid #000;
    margin-left: 40px;
    width:  '100%'};
    align-items: ${({alignItems}) => alignItems ? alignItems : 'flex-end'};
    flex-wrap:${({ flexWrap }) => flexWrap ? flexWrap : 'wrap'};
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'start'};
  
    @media screen and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    @media print and (max-width: 150mm){
        justify-content: center;
        width: 100%;
        padding: 0;
        margin: 0;
    }
`
export const FormMainContainerSet = styled.form<mainContainerSet>`
    display: ${({ display }) => display ? display : 'flex'};
    width: ${({ width }) => width ? width : '100%'};
    align-items: ${({alignItems}) => alignItems ? alignItems : 'flex-end'};
    flex-wrap:${({ flexWrap }) => flexWrap ? flexWrap : 'wrap'};
    justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : 'start'};
    padding: 30px;
    @media screen and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        padding: 0;
        margin: 0;
    }
`

export const ContainerInputs = styled.div<containerInput>`
    display: flex;
    flex-direction:column;
    /* align-items: center; */
    /* text-align: center; */
    padding: 0.5rem;
    width: ${({ width }) => width ? width : '500px'};
    label{
        margin:0 1rem 0;
        font-weight: 700;
        color: rgba(20, 34, 63, .6) 
    }
    @media screen and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        
    }
`

export const SelectGeneral = styled.select<selectGeneral>`
    height: ${({height}) => height ? height : '30px'};
    width: 100%;
    font-size: 1.2rem;  
    font-weight: 700;        
    border: 1px solid #495563;
    color: ${({bg}) => bg ? '#fff' : '#495563'};
    background:  ${({bg}) => bg ? bg : '#fff'};
    border-radius: 6px;
    text-align: center;
    outline: transparent;
    transition: 0.5 ease-in;
    :focus{
        border: 2px solid #495563;
    } 
`
export const SelectGeneralSet = styled.select<selectGeneralSet>`
    height: ${({height}) => height ? height : '30px'};
    width: ${({width}) => width ? width : '100%'};
    font-size: 1.2rem;  
    font-weight: 700;        
    border: 1px solid #495563;
    color: ${({bg}) => bg ? '#fff' : '#495563'};
    background:  ${({bg}) => bg ? bg : '#fff'};
    border-radius: 6px;
    text-align: ${({textAlign}) => textAlign ? textAlign : 'start'};
    outline: transparent;
    transition: 0.5 ease-in;
    text-align-last:center;
    :focus{
        border: 2px solid #495563;
    } 
   
`
export const InputGeneral = styled.input<inputGeneral>`
    width: ${({width}) => width ? width : '30px'};
    height: ${({height}) => height ? height : '30px'};   
    font-size: 1.2rem;  
    font-weight: 500;        
    border: 1px solid #495563;
    color: #495563;
    
    border-radius: 6px;
    text-align: center;
    outline: transparent;
    transition: 0.5 ease-in;
    :focus{
        border-color: #495563;
        border-width: 2px;
    } 
`
export const InputGeneralLG = styled.input<inputGeneral>`
    width: ${({width}) => width ? width : '30px'};
    height: ${({height}) => height ? height : '30px'};   
    font-size: 3rem;  
    font-weight: 700;        
    border: 2px solid #495563;
    color: #495563;
    border-radius: 6px;
    text-align: center;
    outline: transparent;
    transition: 0.5 ease-in;
    :focus{
        border-color: #495563;
        border-width: 3px;
        
    } 
`

export const ButtonGeneral = styled.button<buttonGeneral>`
    width: ${({width}) => width ? width : '30px'};
    height: ${({height}) => height ? height : '30px'}; 
    border-radius: 15px;
    font-size: 1.2rem;
    color: #fff;
    border: 3px solid ${({color}) => color ? color : '#212F3C'};
    background: ${({color}) => color ? color : '#212F3C'};
    /* transition: .1s ease; */
    i{
        font-size: 2rem;
    }
    img{
        height: ${({height}) => height ? height : '30px'};
        padding: 0.2rem;
    }
    :hover{
        background:  #fff;
        color: ${({color}) => color ? color : '#212F3C'};;
    }
    @media screen and (max-width: 1000px){
        /* flex-direction: column; */
        margin: 1rem 0 0;
    }
`
export const BtnSpanGeneral = styled.span<buttonGeneral>`
    width: ${({width}) => width ? width : '30px'};
    height: ${({height}) => height ? height : '30px'}; 
    border-radius: 15px;
    font-size: 1.2rem;
    display: flex;
    color: #fff;
    justify-content: center;
    margin: auto;
    align-items: center;
    border: 3px solid ${({color}) => color ? color : '#212F3C'};
    background: ${({color}) => color ? color : '#212F3C'};
    cursor: pointer;
    i{
        font-size: 2rem;
    }
    img{
        height: ${({height}) => height ? height : '30px'};
        padding: 0.2rem;
    }
    :hover{
        background:  #fff;
        color: ${({color}) => color ? color : '#212F3C'};;
    }
    @media screen and (max-width: 1000px){
        /* flex-direction: column; */
        margin: 1rem 0 0;
    }
`
export const H3Title = styled.h3`
    color: #5a5a5a;
    /* font-size:10px; */
`
export const H5Title = styled.h5<h5title>`
    color: #d8d8d8;
    width:100%;
    /* padding: 2rem 1rem 0; */
    padding: 5px;
    background: #1a1f2e;
    /* font-size:10px; */
`
export const DivDivider = styled.div`
    height: 1px;
    width: 100%;
    margin: 1rem 0 1rem;
    background: #8a8989;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
`
export const DivBordered = styled.div`
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    width: 100%;
    margin: 0 1rem 1rem 0;
    @media screen and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`

export const DivQRTitulo = styled.div`
   
    border: 1px solid #000;
    font-size:30px;
    text-align:center;
    font-weight:bold;
    width: 100%;
    
    @media screen and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    @media print and (max-width: 1000px){
        justify-content: center;
        font-size:40px;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`

export const DivQRPallet = styled.div`
    border: 1px solid #fff;
    border-top:none;
    
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    line-height: 0.8;
    font-size:1200%;
    text-align:center;
    font-weight:normal;
    width: 100%;

    @media print and (max-width: 1000px){
        justify-content: center;
        font-family: Arial Narrow;
        font-size:360px;
        font-weight:normal;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`

export const DivQRPalletSmall = styled.div`
    border: 1px solid #fff;
    border-top:none;
    
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    line-height: 0.8;
    font-size:1000%;
    text-align:center;
    font-weight:normal;
    width: 100%;

    @media print and (max-width: 1000px){
        justify-content: center;
        font-family: Arial Narrow;
        font-size:300px;
        font-weight:normal;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`

export const DivQRCodigoFormato = styled.div`
    border: 1px solid #000;
    border-top:none;
    font-size:50px;
    line-height: 0.9;
    text-align:center;
    width: 100%;

    @media screen and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    @media print and (max-width: 1000px){
        justify-content: center;
        font-size:65px;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`
export const DivQRTotal = styled.div`
    border: 1px solid #000;
    border-top:none;
    font-size:45px;
    font-weight:bold;
    text-align:center;
    width: 100%;
    line-height: 0.8;

    @media screen and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    @media print and (max-width: 1000px){
        justify-content: center;
        font-size:60px;
        font-weight:bold;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`



export const DivQRDetalle = styled.div`
    border: 1px solid #000;
    border-top:none;
    font-size:35px;
    text-align:center;
    width: 100%;

    @media screen and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    @media print and (max-width: 1000px){
        justify-content: center;
        font-size:32px;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`
export const DivQRFechas = styled.div`
    border: 1px solid #000;
    border-top:none;
    font-size:23px;
    text-align:center;
    width: 100%;

    @media screen and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    @media print and (max-width: 1000px){
        justify-content: center;
        font-size:31px;
        font-family: Arial Narrow;
        line-height: 1.2;
        width: 100%;
        margin: 0;
        padding: 0;
    }
`

export const DivQRFechasTitulo = styled.div`
    border: 1px solid #000;
    border-top:none;
    font-size:22px;
    font-weight:bold;
    text-align:center;
    width: 100%;

    @media screen and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    @media print and (max-width: 1000px){
        justify-content: center;
        width: 100%;
        font-size:20px;
        font-family: Arial Narrow;
        font-weight:bold;
        margin: 0;
        padding: 0;
    }
`


export const TextAreaSet = styled.textarea`
    width: 100%;
    
    font-size: 18px;  
    font-weight: 400;        
    border: 1px solid #495563;
    color: #495563;
    padding: 5px;
    border-radius: 6px;
    text-align: start;
    outline: transparent;
    transition: 0.5 ease-in;
    :focus{
        border-color: #495563;
        border-width: 2px;
        padding: 4px;
    } 
`

export const DosColumnasContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Item = styled.div`
  flex: 0 0 48%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;