import React,  { useState,ChangeEvent }  from 'react'
// import {setBusqueda, LotesToProyeccion, proyeccionesVsCosechas} from './proyecciones'
import {proveedor} from '../Proveedores/proveedor'
// import {lote} from '../Lotes/lote'
import {cogida, cosechasByAgricultor} from '../Cogidas/cogida'
import * as ProveedoresCtrl from '../Proveedores/ProveedorService'
import * as LotesController from '../Lotes/LoteService'
import * as ProyeccionesController from '../Proyecciones/ProyeccionesService'

type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
const noTruncarDecimales = {maximumFractionDigits: 20};
function PagoAgricultorList() {
    // const [proyecciones, setProyecciones] = useState<proyeccionesVsCosechas[]>([])
    const [setBusqueda, setSetBusqueda] = useState('')
    const [Agricultores, setAgricultores] = useState<proveedor[]>([])
    const [cosechas, setCosechas] = useState<cosechasByAgricultor[]>([])
    // const [Lotes, setLotes] = useState<LotesToProyeccion[]>([]);

    const handleChange = (e:InputChange)=>{
        setSetBusqueda(e.target.value)
    }
    const submitBusqueda = async () =>{
        const resAgricultores = await ProveedoresCtrl.busquedaProveedor(setBusqueda)
        setAgricultores(resAgricultores.data)
    }
    const selectAgricultor = async(e:InputChange)=>{
        const idAgricultor = e.target.value;
        console.log(idAgricultor)
        const resLotes = await ProveedoresCtrl.getCosechasByProveedor(idAgricultor)  
        console.log(resLotes)    
        setCosechas(resLotes.data)
        // setLotes(resLotes.data)
    }
 
    return (
        <div>
           <div className="col-12 border-bottom">
            <span className="titleForm">PAGO DE AGRICULTORES</span>
           </div>
           <div className="row col-12  align-items-end pt-4">
                <div className="form-group p-1 col-10 col-md-3">
                    <label htmlFor="textAgricultor">Agricultor</label>
                    <input
                        type="text"
                        name="textAgricultor"
                        id="textAgricultor"
                        className="form-control"
                        placeholder="Ruc o Rason Social"
                        onChange={handleChange}
                        value={setBusqueda}
                        />
                </div>
                <div className="form-group col-2 col-md-1 p-1">
                    <button className="form-control btn btn-outline-dark" onClick={submitBusqueda}>Buscar</button>
                </div>
                <div className="form-group p-1 col-12 col-md-5">
                    <label htmlFor="selectAgricultor">Agricultor</label>
                    <select name="selectAgricultor" id="selectAgricultor" className="form-control" onChange={selectAgricultor} >
                    {Agricultores.length>0?(<option value="">Seleccione</option>):('')}
                        {Agricultores?.map((agricultor) => {                                                
                        return <option key={agricultor._id} value={agricultor._id}>{agricultor.nombre} - {agricultor.numeroDocumento}</option>                                           
                        })}
                    </select>
                </div>
                
            </div>

           <div className="table-responsive  col-12 mx-auto  mb-5 bg-body rounded trheight tableFixHead">

                <table className="table shadow">
                    <thead>
                        <tr >
                            <th scope="col">GUIA DE REMISION</th>
                            <th scope="col">JABAS</th>
                            <th scope="col">PESO</th>
                            <th scope="col">PLACA</th>
                            <th scope="col">NUM COSECHA</th>
                            <th scope="col">AGRICULTOR</th>
                       
                        </tr>
                    </thead>
                    <tbody>
                    {cosechas.map((cosecha) => {   
                            if(cosecha.lote.proveedor?._id){
                                return <tr id={cosecha._id} key={cosecha._id}  >
                                <td>{cosecha.guiaRemision}</td>
                                <td>{cosecha.jabas}</td>
                                <td>{cosecha.peso}</td>
                                <td>{cosecha.placa}</td>
                                <td>{cosecha.numCosecha}</td>
                                <td>{cosecha.lote.proveedor?._id}</td>
                                </tr>
                            }                                                                                                     
                            
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PagoAgricultorList
