import React, { MouseEventHandler, FormEvent, useEffect, useState, ChangeEvent} from 'react'
import { Button, Modal } from 'react-bootstrap';
import * as calidadProdServices from '../CalidadProduccionServices'
import { toast } from 'react-toastify';
import moment from 'moment';

import { allTapas, allEnvases, allFormatos, listInputs, parametros, listParametros} from '../typesCalidadProduccion'

import * as CalidadProduccionServices from '../CalidadProduccionServices'
interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>,
    afterHidenModal: () => void,
    parametroSelected?:  listParametros
}
// type FormEvent = FormEvent<HTMLFormElement>
type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
function ModalFormParametros(props: Props|any) {
    const initialStateParametro = {_id: '', idInput:'', idFormato:'',corte:'', pesoEscurrido: 0, margenBruto: 0, idEnvase:'', idTapa:''}
    const [parametro, setParametro] = useState<parametros>(initialStateParametro)
    const [Inputs, setInputs] = useState<listInputs[]>([])
    const [Formatos, setFormatos] = useState<allFormatos[]>([])
    const [Envases, setEnvases] = useState<allEnvases[]>([])
    const [Tapas, setTapas] = useState<allTapas[]>([])

    const submitParametro = async(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
       try {
        if (parametro._id) {            
            await CalidadProduccionServices.editParametro(parametro)
            toast.success("Editado");
            props.afterHidenModal();
        }else{
            const resSubmitParametro = await CalidadProduccionServices.createParametro(parametro);
            toast.success("Parametro Creado")
            props.afterHidenModal();
        }
        
       } catch (error) {           
           toast.error("Verifique si el input ya existe en otro parametro")
       }
    }
    const getAllInputs = async() =>{
        const allInputs = await CalidadProduccionServices.getAllInputs();
        setInputs(allInputs.data);
    }
    const getAllFormatos = async() =>{
        const formatos = await CalidadProduccionServices.getAllFormatos();
        setFormatos(formatos.data);
    }
    const getAllEnvases = async()=>{
        const allEnvases = await CalidadProduccionServices.getAllEnvases();
        setEnvases(allEnvases.data)
    }
    const getAllTapas = async() =>{
        const allTapas = await CalidadProduccionServices.getAllTapas();
        setTapas(allTapas.data);
    }
    const handleChangeParametro = (e:InputChange) =>{
        setParametro({...parametro, [e.target.name]: e.target.value})
    }
    useEffect(() => {
        if (props.show) {
            getAllInputs();
            getAllFormatos();
            getAllEnvases();
            getAllTapas();
        }else{
            // setInputs([])
            // setFormatos([])
            // setEnvases([])
            // setTapas([])
            setParametro(initialStateParametro)
        }
        if (props.parametroSelected?._id) {
            setParametro({...parametro,_id: props.parametroSelected._id, idInput: props.parametroSelected.idInput._id,
                            idFormato: props.parametroSelected.idFormato.id._id, corte: props.parametroSelected.idFormato.corte,
                            pesoEscurrido: props.parametroSelected.idFormato.pesoEscurrido, margenBruto: props.parametroSelected.idFormato.margenBruto,
                            idEnvase: props.parametroSelected.idEnvase._id, idTapa: props.parametroSelected.idTapa._id })
        }
    }, [props.show])
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>


                </Modal.Header>
                <Modal.Body>
                    <div className="text-center col-12 col-sm-12 col-md-6 mx-auto pb-3">

                        <h3 className="text-info font-weight-bold">Parametro</h3>
                    </div>
                    <form onSubmit={submitParametro}>
                        <div className="d-flex flex-wrap justify-content-sm-center justify-content-md-start">
                            <div className=" px-3 col-12 d-flex flex-wrap justify-content-start ">
                                <div className="px-3 pb-2 ">
                                    <label htmlFor="idInput" className="text-info">Input</label>
                                    <select className="form-control form-control-lg border-info" name="idInput" id="idInput"
                                        onChange={handleChangeParametro}
                                        required>
                                        {props.parametroSelected?._id? <option value={props.parametroSelected.idInput._id}>{props.parametroSelected.idInput.nameInput} __ {props.parametroSelected.idInput.idSubCatInput.nombre}</option>:<option value="">Seleccione Input</option>}    
                                        {Inputs.map((input)=>{
                                            return (
                                                <option value={input._id}>{input.nameInput} __ {input.idSubCatInput.nombre}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                                <div className="ml-3 mt-2 col-11 border-bottom border-info bg-info" style={{height:'2px'}}>
                                </div>
                            </div>
                            <div className="px-3 pt-2  d-flex flex-wrap justify-content-start">
                                <div className="px-3 pt-1">
                                    <label htmlFor="" className="text-info">Formato</label>
                                    <select className="form-control form-control-lg border-info" name="idFormato" id="idFormato"
                                    onChange={handleChangeParametro}
                                     required>
                                        {props.parametroSelected?._id ? <option value={props.parametroSelected.idFormato.id._id}>{props.parametroSelected.idFormato.id.descripcion}</option> : <option value="">Seleccione Formato</option> } 
                                        {Formatos.map((formato)=>{
                                           return(<option value={formato._id}>{formato.descripcion}</option>)  
                                        })}
                                    </select>
                                </div>
                                <div className="px-3 pt-1">
                                    <label htmlFor="" className="text-info">Corte</label>
                                    <input className="form-control form-control-lg border-info "
                                        name="corte"
                                        type="text"
                                        value={parametro.corte}
                                        onChange={handleChangeParametro}
                                        required />
                                </div>
                                <div className="px-3 pt-1">
                                    <label htmlFor="" className="text-info">Peso Escurrido</label>
                                    <input className="form-control form-control-lg border-info "
                                        name="pesoEscurrido"
                                        type="number"
                                        onChange={handleChangeParametro}
                                        value={parametro.pesoEscurrido}
                                        required />
                                </div>
                                <div className="px-3  pb-2 pt-1">
                                    <label htmlFor="" className="text-info">Margen Bruto</label>
                                    <input className="form-control form-control-lg border-info "
                                        name="margenBruto"
                                        type="number"
                                        value={parametro.margenBruto}
                                        onChange={handleChangeParametro}
                                        required />
                                </div>
                               <div className="mx-3 mt-2 col-11 border-bottom border-info bg-info" style={{height:'2px'}}>
                                </div>
                            </div>
                            <div className="px-3 col-12 pt-2 d-flex flex-wrap justify-content-start">
                                <div className="px-3  pb-2 pt-1">
                                    <label htmlFor="" className="text-info">Envase</label>
                                    <select className="form-control form-control-lg border-info" name="idEnvase" id="idEnvase"
                                    onChange={handleChangeParametro}
                                     required>
                                        {props.parametroSelected?._id? <option value={props.parametroSelected.idEnvase._id}>{props.parametroSelected.idEnvase.descripcion}</option>: <option value="">Seleccione Envase</option> } 
                                        {Envases.map((envase)=>{
                                            return(<option value={envase._id}>{envase.descripcion}</option>)
                                        })}
                                    </select>
                                </div>
                                <div className="mx-3 mt-2 col-11 border-bottom border-info bg-info" style={{height:'2px'}}>
                                </div>
                            </div>
                            <div className="px-3 col-12 pt-2 d-flex flex-wrap justify-content-start">
                                <div className="px-3 pt-1">
                                    <label htmlFor="" className="text-info">Tapa</label>
                                    <select className="form-control form-control-lg border-info" name="idTapa" id="idTapa"
                                    onChange={handleChangeParametro}
                                     required>
                                        {props.parametroSelected?._id ? <option value={props.parametroSelected.idTapa._id}>{props.parametroSelected.idTapa.descripcion}</option> : <option value="">Seleccione Tapa</option> } 
                                        {Tapas.map((tapa)=>{
                                            return ( <option value={tapa._id}>{tapa.descripcion}</option> )
                                        })}
                                    </select>
                                </div>                        
                               
                            </div>                         
                        </div>
                        <div className="px-4 pt-3 col-12 col-sm-4 mx-auto ">
                            <button className='btn btn-info btn-block btn-lg'> {props.parametroSelected?._id ? 'Editar':'Crear'}</button>
                        </div>                    
                    </form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalFormParametros
