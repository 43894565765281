import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react'

import { cogida, proxCosecha } from './cogida'
import * as cogidaService from './CogidaService'
import moment from 'moment';
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
// services
import * as loteService from '../Lotes/LoteService'
// import * as proveedorService from '../Proveedores/ProveedorService'
import { lote , oneLote} from '../Lotes/lote'
import * as departamentoService from '../Departamentos/departamentoService'
// import { departamento } from '../Departamentos/departamento'
import * as localidadService from '../Localidades/LocalidadService'
// import { localidad } from '../Localidades/localidad'
import { UsuarioInterface } from '../Usuarios/usuario'
import * as usuarioService from '../Usuarios/UsuarioService'
import * as transportistaService from '../Transportistas/TrasportistaService'
import {vehiculo} from '../Transportistas/Vehiculos/vehiculos'
// import { cogidanum } from './numccogida'
// import { setCommentRange, transpileModule } from 'typescript';


// import './cogida.css'
// import { stringify } from 'node:querystring';
type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
interface Params {
    id: string
}
// interface Cogidas {
//     numCog: number
// }
type numCog = number;
function CogidaForm() {

    // const initUser = {name: 'Jon'}
    const history = useNavigate();
    const params = useParams<any>();
    
    const [initialState2, setinitialState2] = useState({
            numCosecha: 0,
    
            lote: {
                _id: '',
                nombreLote: '',
                numeroContrato: '',
                localidad: {
                    _id: '',
                    nombre: '',
                   
                },
                departamento: {
                    _id: '',
                    nombre: ''
                }
            },
            peso: 0,
            jabas: 0,
            calibre: '',
            guiaRemision: '',
            
            placa: '',
            evaluador: {
                _id: '',
                nombre: ''
            },
            transportista: {
                _id: '',
                ruc: '',
                rasonSocial: ''
            },
            pesoCalibre1: 0,
            pesoCalibre2: 0,
            pesoCalibre3: 0,
            pesoCalibre4: 0,
            pesoCalibre5: 0,
            pesoCalibre6: 0,
            pesoCalibre7: 0,
            pesoCalibre8: 0,
            porcCalibre1: 0,
            porcCalibre2: 0,
            porcCalibre3: 0,
            porcCalibre4: 0,
            porcCalibre5: 0,
            porcCalibre6: 0,
            porcCalibre7: 0,
            porcCalibre8: 0,
            lapsoCogida: 0,
            fecha: '',
            CalidadTotal: 0,
            PrimeraTotal: 0,
            PrimeraTotalKilos: 0,
            SegungaTotal: 0,
            SegundaTotalKilos: 0,
            DescarteTotal: 0,
            DescarteTotalKilos: 0,
            PriTipo1: 0,
            PriTipo2: 0,
            PriTipo3: 0,
            SegTipo1: 0,
            SegTipo2: 0,
            SegTipo3: 0,
            SegTipo4: 0,
            SegTipo5: 0,
            SegTipo6: 0,
            SegTipo7: 0,
            DesTipo1: 0,
            DesTipo2: 0,
            DesTipo3: 0,
            DesTipo4: 0,
            DesTipo5: 0,
            DesTipo6: 0,
            DesTipo7: 0,
            DesTipo8: 0,
            DesTipo9: 0,
            DesTipo10: 0,
            DesTipo11: 0,
            DesTipo12: 0,
            DesTipo13: 0,
            DesTipo14: 0,
            DesTipo15: 0,
            DesTipo16: 0,
            DesTipo17: 0,
        }
    );
    const initialState = {
        numCosecha: 0,

        lote: {
            _id: '',
            nombreLote: '',
            numeroContrato: '',
            localidad: {
                _id: '',
                nombre: ''                
            },
            departamento: {
                _id: '',
                nombre: ''
            }
        },
        peso: 0,
        jabas: 0,
        calibre: '',
        guiaRemision: '',
        
        placa: '',
        evaluador: {
            _id: '',
            nombre: ''
        },
        transportista: {
            _id: '',
            ruc: '',
            rasonSocial: ''
        },
        pesoCalibre1: 0,
        pesoCalibre2: 0,
        pesoCalibre3: 0,
        pesoCalibre4: 0,
        pesoCalibre5: 0,
        pesoCalibre6: 0,
        pesoCalibre7: 0,
        pesoCalibre8: 0,
        porcCalibre1: 0,
        porcCalibre2: 0,
        porcCalibre3: 0,
        porcCalibre4: 0,
        porcCalibre5: 0,
        porcCalibre6: 0,
        porcCalibre7: 0,
        porcCalibre8: 0,
        lapsoCogida: 0,
        fecha: '',
        CalidadTotal: 0,
        PrimeraTotal: 0,
        PrimeraTotalKilos: 0,
        SegungaTotal: 0,
        SegundaTotalKilos: 0,
        DescarteTotal: 0,
        DescarteTotalKilos: 0,
        PriTipo1: 0,
        PriTipo2: 0,
        PriTipo3: 0,
        SegTipo1: 0,
        SegTipo2: 0,
        SegTipo3: 0,
        SegTipo4: 0,
        SegTipo5: 0,
        SegTipo6: 0,
        SegTipo7: 0,
        DesTipo1: 0,
        DesTipo2: 0,
        DesTipo3: 0,
        DesTipo4: 0,
        DesTipo5: 0,
        DesTipo6: 0,
        DesTipo7: 0,
        DesTipo8: 0,
        DesTipo9: 0,
        DesTipo10: 0,
        DesTipo11: 0,
        DesTipo12: 0,
        DesTipo13: 0,
        DesTipo14: 0,
        DesTipo15: 0,
        DesTipo16: 0,
        DesTipo17: 0,
    }


    const [cogidas, setCogidas] = useState<cogida>(initialState2);
    // const [editcogida, setEditcogida] = useState<cogida[]>([]);
    // const [lotes, setLotes] = useState<lote[]>([]);
    // const [lote, setLote] = useState<oneLote>();
    // const [departamentos, setDepartamentos] = useState<departamento[]>([]);
    // const [localidades, setLocalidades] = useState<localidad[]>([]);
    const [tecnicos, setTecnicos] = useState<UsuarioInterface[]>([]);
    const [numCogidas, setNumcogidas] = useState<numCog>();
    const [vehiculos, setvehiculos] = useState<vehiculo[]>([])

    const [proximaCosecha, setProximaCosecha] = useState<proxCosecha>({
        proPesoCosecha: 0,
        proFechaCosecha: '',
        nombre: '',
        numeroCosecha: 0,
        lote: ''
    });

    const [busquedaRegistro, setbusquedaRegistro] = useState({
        transportista: '',
        busquedaLote:''
    })


    const [llenado, setEstado] = useState({
        numCosecha: '',
        departamento: '',
        localidad: '',
        lote: '',
        peso: '',
        jabas: '',
        calibre: '',
        proveedor: '',
        razonSocial: '',
        placa: '',
        evaluador: '',
        pesoCalibre1: 0,
        pesoCalibre2: 0,
        pesoCalibre3: 0,
        pesoCalibre4: 0,
        pesoCalibre5: 0,
        pesoCalibre6: 0,
        pesoCalibre7: 0,
        pesoCalibre8: 0,
        porcCalibre1: 0,
        porcCalibre2: 0,
        porcCalibre3: 0,
        porcCalibre4: 0,
        porcCalibre5: 0,
        porcCalibre6: 0,
        porcCalibre7: 0,
        porcCalibre8: 0,

        lapsoCogida: '',
        fecha: ''
    })
    ///ESTADO PARA BLOQUEAR ENVIO DE FORM
    const [loadDiv, setLoadDiv] = useState(false)
    const loadData = async () => {
        // const resLotes = await loteService.getLotes();
        const resDeps = await departamentoService.getDepartamentos();
        const resTecnicos = await usuarioService.getUsuarios();
        // setDepartamentos(resDeps.data);
        setTecnicos(resTecnicos.data);
        //  setLotes(resLotes.data);
    }

    // const ChangeDep = async (e: any) => {
    //     // setEstado({ ...llenado, [e.target.name]: 'selectedData' })
    //     const id = e.target.value;
    //     setCogidas({ ...cogidas, lote: id });
    //     const resLocalidades = await localidadService.getLocalidadesVSdep(id);
    //     console.log(resLocalidades)
    //     // setLocalidades(resLocalidades.data);
    // }
    // const ChangeLocalidad = async (e: any) => {
    //     setEstado({ ...llenado, [e.target.name]: 'selectedData' })
    //     const id = e.target.value;
    //     const resLotes = await loteService.getLoteVSLocalidad(id);
    //     console.log(resLotes.data)
    //     setLotes(resLotes.data);

    // }
    // const ChangeLote = async (e: any) => {
    //     console.log(e.target.value);
    //     //REINICIAMOS EL CHECKBOX DE CONTINUIDAD
    //     setBoxState(false);
    //     const cantidadCogidas = await cogidaService.getNumCogidas(e.target.value);
    //     console.log(cantidadCogidas, "CANTIDAD COGIDA")
    //     const NextCosecha: number = cantidadCogidas.data + 1;
    //     // CONFIGURANDO EL NUMERO DE LA PROXIMA COSECHA

    //     const proxCosechaData = await cogidaService.getProxCosecha(e.target.value, NextCosecha + 1)
    //     setProximaCosecha(proxCosechaData.data);
    //     setCogidas({ ...cogidas, numCosecha: NextCosecha, lote: e.target.value })
    // }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoadDiv(true);
        try {
            //CREACION DE COSECHA
            if (!params.id) {
                console.log(proximaCosecha._id, proximaCosecha.lote)
                //CREAMOS LA CCOSECHA
                await cogidaService.createCogidas(cogidas);
                //EDITAMOS LAS FECHAS DE LA PROYECCION DEL LOTE
                if (proximaCosecha._id) {
                    await cogidaService.editFechaProyeccion(proximaCosecha)
                }

                /////EDITAMOs LA FECHA DE LA PROXIMA COSECHA EN LA TABLA DE LOTES
                await loteService.editProximaCosecha(proximaCosecha.lote, proximaCosecha.proFechaCosecha, proximaCosecha.numeroCosecha );
                
                // REINICIAMOS LOS ESTADOS
                 setCogidas(initialState2);
                //  setLotes([]);
                //  setTecnicos([]);
                //  setProximaCosecha({proPesoCosecha: 0, proFechaCosecha: '', nombre: '', numeroCosecha: 0, lote: '' });
                 toast.success('Nuevo Lote Anadido');
                 setLoadDiv(false);
            } else { //EDICION DE LOTE
                console.log(cogidas, "VIENDO COGIDA STATE");
                await cogidaService.updateCogidas(params.id, cogidas);                
                toast.success('Cosecha Editada');
                setLoadDiv(false);
                history('/cogidas');
            }
            
            
        } catch (error:any) {
            console.log(error.message)
            if (error.message === 'Request failed with status code 301') {
                toast.error('Mumero de Contrato ya Registrado');
            } else {
                toast.error('Error de Red Consulte con su administrador');
            }
            setLoadDiv(false);
        }
    }
    const [porcentMax, setporcentMax] = useState(0)
    const handleInputChange = async (e: InputChange) => {
        console.log(proximaCosecha)
        const nombreInput = e.target.name;
        const valorInput = e.target.value;
        if(nombreInput==='busquedaLote'){
            setbusquedaRegistro({...busquedaRegistro, busquedaLote: valorInput});
        }else{
            
            setCogidas({ ...cogidas, [e.target.name]: e.target.value });
            if(nombreInput==='evaluador'){                
                setinitialState2({...initialState2, evaluador: {_id: e.target.value, nombre:''}})
            }if(nombreInput==='placa'){
                setinitialState2({...initialState2, placa: e.target.value})
            }
           // console.log(cogidas)
        }
        
  

    }
    const loadDataEdit = async (id: string) => {
        const resCogida = await cogidaService.getCogidaLocalidadDep(id);
        console.log(resCogida.data, "PINTANDO DATA DESDE SERVER")
        setCogidas(resCogida.data);
    }
    const calculoPeso = async () => {
        const pesoTotal = (cogidas.peso);

        var porceentaje1 = ((cogidas.porcCalibre1 * pesoTotal) / 100)
        var porceentaje2 = ((cogidas.porcCalibre2 * pesoTotal) / 100)
        var porceentaje3 = ((cogidas.porcCalibre3 * pesoTotal) / 100)
        var porceentaje4 = ((cogidas.porcCalibre4 * pesoTotal) / 100)
        // var porceentaje5 = ((cogidas.porcCalibre5 * pesoTotal) / 100)
        // var porceentaje6 = ((cogidas.porcCalibre6 * pesoTotal) / 100)
        // var porceentaje7 = ((cogidas.porcCalibre7 * pesoTotal) / 100)
        // var porceentaje8 = ((cogidas.porcCalibre8 * pesoTotal) / 100)

        setCogidas({
            ...cogidas,
            pesoCalibre1: porceentaje1,
            pesoCalibre2: porceentaje2,
            pesoCalibre3: porceentaje3,
            pesoCalibre4: porceentaje4
            // pesoCalibre5: porceentaje5,
            // pesoCalibre6: porceentaje6,
            // pesoCalibre7: porceentaje7,
            // pesoCalibre8: porceentaje8
        })

    }

    
 
    const ChangeProxFechaCosecha = async (e: InputChange) => {
        setProximaCosecha({ ...proximaCosecha, [e.target.name]: e.target.value });
    }
    const handleCancel = (e: any) => {
        e.preventDefault();
        setCogidas(initialState);
        history('/cogidas');
    }
    const [boxState, setBoxState] = useState(false)


    const clickContinuidad = (e: any) => {
        if (boxState === false) {
            //RESTANDO EN 1 EL VALOR ACTUAL DE LA COSECHA
            const continuidadCosecha = cogidas.numCosecha - 1;
            setBoxState(true);
            setCogidas({ ...cogidas, numCosecha: continuidadCosecha })

            // if(cogidas.numCosecha>1){
            // }

        } else {
            //RESTANDO EN 1 EL VALOR ACTUAL DE LA COSECHA
            const continuidadCosecha = cogidas.numCosecha + 1;
            setBoxState(false);
            setCogidas({ ...cogidas, numCosecha: continuidadCosecha })
            //  if(cogidas.numCosecha>1){
            //  }
        }
    }
    const [valorCalidad, setValorCalidad] = useState({
        valorPrimera: 0,
        valorSegunda: 0,
        valorDescarte: 0
    })
    const [valorActualSelect, setvalorActualSelect] = useState({
        calidadPrimera: '',
        calidadSegunda: '',
        calidadDescarte: ''
    })
    const handleSetSelectCalidad = (e: InputChange) => {
        console.log(e.target.name)
        setvalorActualSelect({ ...valorActualSelect, [e.target.name]: e.target.value });
        setValorCalidad({ valorPrimera: 0, valorSegunda: 0, valorDescarte: 0 })
        console.log(valorActualSelect);
    }
    const handleInputValorCalidad = (e: InputChange) => {
        setValorCalidad({ ...valorCalidad, [e.target.name]: e.target.value })
    }
    const handleSubmitCalidadPrimera = (e: any) => {
        e.preventDefault();

        // var valorActualPrimera = cogidas.PrimeraTotal;
        // var valorEntrante = valorCalidad.valorPrimera;
        // var valorTotalPrimera = valorActualPrimera + (valorEntrante*1);
        setCogidas({ ...cogidas, [valorActualSelect.calidadPrimera]: valorCalidad.valorPrimera })
        // setCogidas({...cogidas, PrimeraTotal:valorTotalPrimera});

    }
    const handleSubmitCalidadSegunda = (e: any) => {
        e.preventDefault();
        setCogidas({ ...cogidas, [valorActualSelect.calidadSegunda]: valorCalidad.valorSegunda })

    }
    const handleSubmitCalidadDescarte = (e: any) => {
        e.preventDefault();
        setCogidas({ ...cogidas, [valorActualSelect.calidadDescarte]: valorCalidad.valorDescarte })
    }
    useEffect(() => {
        if (params.id) {
            loadDataEdit(params.id);
        } else {
            loadData();
        }
        console.log("SE ESTA EJECUTTANDO EL PRIMER USEEFECT")
    }, []);

    const calculoPesoCalidad = () => {
        const valorTotalPrimera = cogidas.PriTipo1 * 1 + (cogidas.PriTipo2 * 1);
        const valorTotalSegunda = cogidas.SegTipo1 * 1 + cogidas.SegTipo2 * 1 + cogidas.SegTipo3 * 1 + cogidas.SegTipo4 * 1 + cogidas.SegTipo5 * 1 + cogidas.SegTipo6 * 1 + cogidas.SegTipo7 * 1;
        const valorTotalDescarte = parseFloat((cogidas.DesTipo1 * 1 + cogidas.DesTipo2 * 1 + cogidas.DesTipo3 * 1 + cogidas.DesTipo4 * 1 + cogidas.DesTipo5 * 1 + cogidas.DesTipo6 * 1 + cogidas.DesTipo7 * 1 + cogidas.DesTipo8 * 1 + cogidas.DesTipo9 * 1 + cogidas.DesTipo10 * 1 + cogidas.DesTipo11 * 1 + cogidas.DesTipo12 * 1 + cogidas.DesTipo13 * 1 + cogidas.DesTipo14 * 1 + cogidas.DesTipo15 * 1 + cogidas.DesTipo16 * 1 + cogidas.DesTipo17 * 1).toFixed(2));
        const valorCalidadTotal = parseFloat((valorTotalPrimera + valorTotalSegunda + valorTotalDescarte).toFixed(2));
        setCogidas({ ...cogidas, PrimeraTotal: valorTotalPrimera, SegungaTotal: valorTotalSegunda, DescarteTotal: valorTotalDescarte, CalidadTotal: valorCalidadTotal })

    }
    const calculoPesoProcentajesCalidad = () => {
        if (cogidas.peso > 0) {
            const porcentajePrimera = (cogidas.PrimeraTotal * 100) / cogidas.CalidadTotal;
            const porcentajeSegunda = (cogidas.SegungaTotal * 100) / cogidas.CalidadTotal;
            const porcentajeDescarte = (cogidas.DescarteTotal * 100) / cogidas.CalidadTotal;

            const primeraTotalKilos = parseFloat((cogidas.peso * porcentajePrimera / 100).toFixed(2));
            const segundaTotalKilos = parseFloat((cogidas.peso * porcentajeSegunda / 100).toFixed(2));
            const descarteTotalKilos = parseFloat((cogidas.peso * porcentajeDescarte / 100).toFixed(2));

            setCogidas({ ...cogidas, PrimeraTotalKilos: primeraTotalKilos, SegundaTotalKilos: segundaTotalKilos, DescarteTotalKilos: descarteTotalKilos })
        }
        console.log("EJECUTANDO AL MALDITO")
    }
    const handleBusquedaProveedor = async (e: any) => {
        setbusquedaRegistro({ ...busquedaRegistro, [e.target.name]: e.target.value });
    }
    
    ///BUSQUEDA DE LOTE POR TEXTO
    const btnBusquedaLote = async()=>{
        
        try {
            setBoxState(false);
            console.log(busquedaRegistro)
            const resBusquedaLote = await loteService.getLoteVsContrato(busquedaRegistro.busquedaLote);
            if(!resBusquedaLote.data) {
                setProximaCosecha({proPesoCosecha: 0, proFechaCosecha: '', nombre: '', numeroCosecha: 0,  lote:''});
                setCogidas({...cogidas, lote: resBusquedaLote.data, numCosecha: 0})
                return toast.error("Contrato no Registrado =(")
            }
            
            // console.log(resBusquedaLote.data);
            setCogidas({...cogidas, lote: resBusquedaLote.data})
            const cantidadCogidas = await cogidaService.getNumCogidas(resBusquedaLote.data._id);
            const NextCosecha: number = cantidadCogidas.data + 1;
            // CONFIGURANDO EL NUMERO DE LA PROXIMA COSECHA
            const proxCosechaData = await cogidaService.getProxCosecha(resBusquedaLote.data._id, NextCosecha + 1)
            console.log(proxCosechaData.data,"PINTANDO", NextCosecha+1)
            if(proxCosechaData.data){
                setProximaCosecha(proxCosechaData.data);
            }else{
                toast.warning("No hay Proyecciones para la siguiente Cosecha")
                setProximaCosecha({proPesoCosecha: 0,
                    proFechaCosecha: '',
                    nombre: '',
                    numeroCosecha: NextCosecha+1,
                    lote: ''});
            }
            
            setCogidas({...cogidas, lote: resBusquedaLote.data, numCosecha: NextCosecha})
            // setCogidas({ ...cogidas, numCosecha: NextCosecha, lote: e.target.value })
        
        } catch (error:any) {
           return toast.error(error.message)
        }
    }

    ///BUSQUEDA TRANSPORTISTA
    const ejecutarBusquedaTransportista = async (e:any) =>{
        e.preventDefault();
        try {
            const resBusqueda = await transportistaService.OneTransportista(busquedaRegistro.transportista);
            const resVehiculos= await transportistaService.setTransporteVsVehiculo(resBusqueda.data._id?(resBusqueda.data._id):(''));
            console.log(resVehiculos.data); 
            setvehiculos(resVehiculos.data);   
            setCogidas({...cogidas, transportista: {_id: resBusqueda.data._id , ruc: resBusqueda.data.ruc, rasonSocial: resBusqueda.data.rasonSocial} })

            setinitialState2({...initialState2, transportista: {_id: resBusqueda.data._id?(resBusqueda.data._id):('') , ruc: resBusqueda.data.ruc, rasonSocial: resBusqueda.data.rasonSocial}})
        } catch (error:any) {
            setCogidas({...cogidas, transportista: {_id: '' , ruc: '', rasonSocial: ''} })
            setvehiculos([]); 
            toast.error("No hay registros =(")
        }
    }
    
    useEffect(() => {
        calculoPeso();

    }, [cogidas.peso, cogidas.porcCalibre1, cogidas.porcCalibre2, cogidas.porcCalibre3, cogidas.porcCalibre4])
    // , cogidas.porcCalibre5, cogidas.porcCalibre6, cogidas.porcCalibre7, cogidas.porcCalibre8])
    useEffect(() => {
        calculoPesoProcentajesCalidad();
    }, [cogidas.peso])
    useEffect(() => {
        calculoPesoCalidad();
    }, [cogidas.PriTipo1, cogidas.PriTipo2, cogidas.SegTipo1, cogidas.SegTipo2, cogidas.SegTipo3,
    cogidas.SegTipo4, cogidas.SegTipo5, cogidas.SegTipo6, cogidas.SegTipo7, cogidas.DesTipo1,
    cogidas.DesTipo2, cogidas.DesTipo3, cogidas.DesTipo4, cogidas.DesTipo5, cogidas.DesTipo6,
    cogidas.DesTipo7, cogidas.DesTipo8, cogidas.DesTipo9, cogidas.DesTipo10, cogidas.DesTipo11,
    cogidas.DesTipo12, cogidas.DesTipo13, cogidas.DesTipo14, cogidas.DesTipo15, cogidas.DesTipo16,
    cogidas.DesTipo17])
    useEffect(() => {
        calculoPesoProcentajesCalidad();
    }, [cogidas.CalidadTotal])
    return (
        <div className={`${loadDiv ? 'loadDiv' : ''}`}>
            <div className="row ">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            {params.id ? (<h3>EDICION DE COSECHA</h3>) : (<h3>CREACION DE COSECHA</h3>)}
                            <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <label htmlFor="" className="col-12  p-1">Transportista </label>
                                    <div className="form-group col-12 col-md-12 border-bottom pb-2">
                                       
                                        <div className="row mx-auto">
                                            <input
                                                
                                                type="number"
                                                name="transportista"
                                                placeholder="RUC/DNI"
                                                className="form-control col-12 col-md-3"
                                                value={busquedaRegistro.transportista}
                                                onChange={handleBusquedaProveedor}

                                            />
                                            <div className="form-control btn btn-outline-dark btnsmall col-4 col-md-1 " onClick={ejecutarBusquedaTransportista}  >
                                                <i className="fas fa-search"  ></i>
                                            </div>
                                            <input
                                                required
                                                type="text"
                                                name="proveedor"
                                                placeholder=""
                                                className="form-control col-12 col-md-5"
                                                value={cogidas.transportista.rasonSocial} 
                                                onChange={handleInputChange}
                                            />
                                             {/* <label htmlFor="">Evaluador</label> */}
                                            <select onChange={handleInputChange} name="placa" className="form-control form-select col-12 col-md-3" aria-label=".form-select-sm example" required>
                                            {params.id ? (
                                                <option value={cogidas.placa} >{cogidas.placa}</option>
                                            ) : (
                                                <option value={''} ></option>
                                            )}
                                            {vehiculos.map((vehiculo) => {
                                                
                                                    return <option key={vehiculo._id} value={vehiculo.placa}>{vehiculo.placa} - {vehiculo.marca}</option>
                                                

                                            })}

                                        </select>
                                        </div>

                                    </div>
                                    <label htmlFor="" className="col-12  p-1">Lote </label>
                                    <div className="form-group col-12 col-md-12 border-bottom pb-2">
                                       
                                        <div className="row mx-auto">
                                            <input
                                                
                                                type="text"
                                                name="busquedaLote"
                                                placeholder=""
                                                className="form-control col-12 col-md-3"
                                                value={busquedaRegistro.busquedaLote}
                                                onChange={handleInputChange}

                                            />
                                            <div className="form-control btn btn-outline-dark btnsmall col-4 col-md-1 " onClick={btnBusquedaLote}  >
                                                <i className="fas fa-search"  ></i>
                                            </div>
                                             <select onChange={handleInputChange} name="lote" className="form-control form-select col-12 col-md-8" aria-label=".form-select-sm example" required>
                                            {params.id ? (
                                                <option value={cogidas.lote?._id} >{cogidas.lote?.numeroContrato}__{cogidas.lote?.nombreLote}__{cogidas.lote?.localidad.nombre}</option>
                                            ) : (
                                               
                                                <option value={cogidas.lote?._id} >{cogidas.lote?.numeroContrato}__{cogidas.lote?.nombreLote}__{cogidas.lote?.localidad.nombre}</option>
                                            )}
                                            

                                        </select>
                                             
                                           

                                       
                                        </div>

                                    </div>
                                    {/* <div className="form-group col-12 col-md-3">
                                        <label htmlFor="">Departamento</label>
                                        <select onChange={ChangeDep} name="departamento" className={`form-control form-select form-select-sm shadow ${llenado.departamento}`} aria-label=".form-select-sm example" required>
                                            {params.id ? (
                                                <option value={cogidas.lote.departamento._id} >{cogidas.lote.departamento.nombre} </option>)
                                                : (
                                                    <option value={''} >Seleccion</option>

                                                )}
                                            {departamentos.map((departamento) => {
                                                return <option key={departamento._id} value={departamento._id}>{departamento.nombre}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="">Localidad</label>
                                        <select onChange={ChangeLocalidad} name="localidad" className={`form-control form-select form-select-sm shadow ${llenado.localidad}`} aria-label=".form-select-sm example" required>
                                            {params.id ? (
                                                // <option value='' >SE ESTA EDITANDO</option>
                                                <option value={cogidas.lote.localidad._id} >{cogidas.lote.localidad.nombre}</option>

                                            ) : (
                                                <option value={''} >Seleccione</option>
                                            )}
                                            {localidades.map((localidad) => {
                                                return <option key={localidad._id} value={localidad._id}>{localidad.nombre}</option>
                                            })}

                                        </select>
                                    </div>
                                    <div className="form-group col-12 col-md-6">
                                        <label htmlFor=""> Contrato Lote</label>
                                        <select onChange={ChangeLote} name="lote" className={`form-control form-select form-select-sm shadow `} aria-label=".form-select-sm example" required>
                                            {params.id ? (
                                                <option value={cogidas.lote._id} >{cogidas.lote.numeroContrato}</option>
                                            ) : (
                                                <option value={''} >Seleccione</option>
                                            )}
                                            {lotes.map((lote) => {
                                                return <option key={lote._id} value={lote._id}>{lote.numeroContrato}___{lote.nombreLote}___{lote.proveedor.nombre}</option>
                                            })}

                                        </select>


                                    </div> */}
                                    {/* <div className="form-group col-12 col-md-3 ">
                                        <label htmlFor="">Numero de Cosecha</label>

                                        <input
                                            required
                                            readOnly={params.id ? (true) : false}
                                            type="number"
                                            min={numCogidas}
                                            name="numCosecha"
                                            placeholder="Numero de Cosecha"
                                            className={`form-control selectedData`}
                                            value={cogidas.numCosecha}                                                                                   
                                        />

                                    </div> */}
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="">Num Cosecha {params.id ? (<></>) : ('/  Continuidad')}</label>
                                        <div className="row mx-auto">
                                            <input
                                                required
                                                readOnly={params.id ? (true) : false}
                                                type="number"
                                                min={numCogidas}
                                                name="numCosecha"
                                                placeholder="Numero de Cosecha"
                                                className={`form-control selectedData col-7`}
                                                value={cogidas.numCosecha}
                                            />

                                            {params.id ? (<></>) : (<div className="form-control btn  btnsmall col-5 mx-auto"   >
                                                <input type="checkbox"
                                                    className="checkContinuidad"
                                                    name="checkContinuidad"
                                                    checked={boxState}
                                                    onClick={clickContinuidad} />
                                            </div>)}
                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-3 ">
                                        <label htmlFor="">Peso Total</label>
                                        <input
                                            required
                                            type="number"
                                            name="peso"
                                            placeholder="Peso"
                                            className={`form-control ${llenado.peso}`}
                                            value={cogidas.peso}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="">Jabas</label>
                                        <input
                                            required
                                            type="number"
                                            name="jabas"
                                            placeholder="Jabas"
                                            className={`form-control ${llenado.jabas}`}
                                            value={cogidas.jabas}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="">Guia de Remision</label>
                                        <input
                                            required
                                            type="text"
                                            name="guiaRemision"
                                            placeholder="Guia de Remision"
                                            className="form-control"
                                            value={cogidas.guiaRemision}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    {/* <div className="form-group col-12 col-md-3">
                                        <label htmlFor="">Proveedor</label>                                            
                                            <div className="row mx-auto">                                            
                                                <input
                                                    required
                                                    type="text"
                                                    name="proveedor"
                                                    placeholder=""
                                                    className="form-control"
                                                    // value={busquedaProveedor.rucdni}
                                                    // onChange={handleBusquedaProveedor}
                                                                                                      
                                                />                                                
                                            </div>   
                                    </div> */}
                                    {/* <div className="form-group col-12 col-md-3">
                                        <label htmlFor="">Razon Social</label>
                                        
                                         <select name="razonSocial" className={`form-control form-select form-select-sm shadow ${llenado.lote}`} aria-label=".form-select-sm example" required>
                                         {params.id ? (
                                                <option value={cogidas.proveedor._id} >{cogidas.proveedor.nombre}</option>
                                            ) : (
                                                <option value={cogidas.proveedor._id} >{cogidas.proveedor.nombre}</option>
                                            )}

                                        </select>
                                    </div> */}
                                    {/* <div className="form-group col-12 col-md-3">
                                        <label htmlFor="">Placa</label>
                                        <input
                                            required
                                            type="text"
                                            name="placa"
                                            placeholder="Placa"
                                            className="form-control"
                                            value={cogidas.placa}
                                            onChange={handleInputChange}
                                        />
                                    </div> */}
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="">Evaluador</label>
                                        <select onChange={handleInputChange} name="evaluador" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                                            {params.id ? (
                                                <option value={cogidas.evaluador._id} >{cogidas.evaluador.nombre}</option>
                                            ) : (
                                                <option value={''} ></option>
                                            )}
                                            {tecnicos.map((tecnico) => {
                                                if (tecnico.tipo === 'EVALUADOR') {
                                                    return <option key={tecnico._id} value={tecnico._id}>{tecnico.nombre}</option>
                                                }

                                            })}

                                        </select>
                                    </div>
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="fechaSiembra" className="form-label">Fecha </label>
                                        <input
                                            required
                                            type="date"
                                            id="fecha"
                                            name="fecha"
                                            placeholder="Fecha"
                                            className="form-control"
                                            value={moment(cogidas.fecha).format('YYYY-MM-DD')}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <span className="col-12 border-bottom p-1"><strong className="h4 text-info ">CALIDAD: </strong> <span className="h5 text-info">{cogidas.CalidadTotal} Kg muestra total</span> </span>

                                    <div className="form-group col-12 col-md-12">
                                        <span className="text-primera"> Primera: <span className="valor-primera">{cogidas.PrimeraTotal} Kg Muestra - {cogidas.PrimeraTotalKilos} Kg Neto</span>  </span>
                                        {/* <label htmlFor="calidadPrimera">PRIMERA: <strong className="text-danger">{cogidas.PrimeraTotal} - {cogidas.PrimeraTotalKilos} Kg Neto</strong></label> */}
                                        <div className="row">
                                            <select onChange={handleSetSelectCalidad} name="calidadPrimera" className={`form-control form-select form-select-sm shadow col-md-5 `} aria-label=".form-select-sm example" >
                                                <option value="">Seleccione</option>
                                                <option className={`${cogidas.PriTipo1 ? "text-success" : ""}`} value="PriTipo1">30-45mm - {cogidas.PriTipo1} Kg </option>
                                                <option className={`${cogidas.PriTipo2 ? "text-success" : ""}`} value="PriTipo2">45-65mm - {cogidas.PriTipo2} Kg</option>
                                            </select>
                                            <input

                                                type="number"
                                                name="valorPrimera"
                                                min="0"
                                                max="100"
                                                step="any"
                                                className={`form-control col-md-3 col-8`}
                                                value={valorCalidad.valorPrimera}
                                                onChange={handleInputValorCalidad}
                                            />
                                            {/* <button onClick={handleCancel} className="btn btn-outline-secondary col-4">Cancelar</button> */}
                                            <button className=" btn btn-outline-dark col-4 col-md-2" name="btnPrimera" onClick={handleSubmitCalidadPrimera}><i className="fas fa-plus btnaddCalidad"></i>
                                            </button>

                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-12">
                                        <span className="text-segunda"> Segunda: <span className="valor-segunda">{cogidas.SegungaTotal} Kg Muestra -  {cogidas.SegundaTotalKilos} Kg Neto</span>  </span>
                                        {/* <label htmlFor="calidadPrimera">SEGUNDA: <strong className="text-danger">{cogidas.SegungaTotal} - {cogidas.SegundaTotalKilos} Kg Neto</strong></label> */}
                                        <div className="row">
                                            <select onChange={handleSetSelectCalidad} name="calidadSegunda" className={`form-control form-select form-select-sm shadow col-md-5 `} aria-label=".form-select-sm example" >
                                                <option value="">Seleccione</option>
                                                <option className={`${cogidas.SegTipo1 ? "text-success" : ""}`} value="SegTipo1">65-75mm - {cogidas.SegTipo1} Kg</option>
                                                <option className={`${cogidas.SegTipo2 ? "text-success" : ""}`} value="SegTipo2">DESHIDRATADO - {cogidas.SegTipo2} Kg</option>
                                                <option className={`${cogidas.SegTipo3 ? "text-success" : ""}`} value="SegTipo3">FOFA - {cogidas.SegTipo3} Kg</option>
                                                <option className={`${cogidas.SegTipo4 ? "text-success" : ""}`} value="SegTipo4">ARROCETADA - {cogidas.SegTipo4} Kg</option>
                                                <option className={`${cogidas.SegTipo5 ? "text-success" : ""}`} value="SegTipo5">CINTURA - {cogidas.SegTipo5} Kg</option>
                                                <option className={`${cogidas.SegTipo6 ? "text-success" : ""}`} value="SegTipo6">OMBLIGO - {cogidas.SegTipo6} Kg</option>
                                                <option className={`${cogidas.SegTipo7 ? "text-success" : ""}`} value="SegTipo7">PEDUNCULO RASGADO - {cogidas.SegTipo7} Kg</option>

                                            </select>
                                            <input

                                                type="number"
                                                name="valorSegunda"
                                                min="0"
                                                max="100"
                                                step="any"
                                                className={`form-control col-md-3 col-8`}
                                                value={valorCalidad.valorSegunda}
                                                onChange={handleInputValorCalidad}
                                            />
                                            {/* <button onClick={handleCancel} className="btn btn-outline-secondary col-4">Cancelar</button> */}
                                            <button className=" btn btn-outline-dark col-4 col-md-2" name="btnSegunda" onClick={handleSubmitCalidadSegunda} ><i className="fas fa-plus btnaddCalidad"></i>
                                            </button>

                                        </div>
                                    </div>
                                    <div className="form-group col-12 col-md-12">
                                        <span className="text-descarte"> Descarte: <span className="valor-descarte">{cogidas.DescarteTotal} Kg Muestra -  {cogidas.DescarteTotalKilos} Kg Neto</span>  </span>
                                        {/* <label htmlFor="calidadPrimera">DESCARTE: <strong className="text-danger">{cogidas.DescarteTotal} - {cogidas.DescarteTotalKilos} Kg Neto</strong> </label> */}
                                        <div className="row">
                                            <select onChange={handleSetSelectCalidad} name="calidadDescarte" className={`form-control form-select form-select-sm shadow col-md-5 `} aria-label=".form-select-sm example" >
                                                <option value="">Seleccione</option>
                                                <option className={`${cogidas.DesTipo1 ? "text-success" : ""}`} value="DesTipo1">MAYOR a 75mm - {cogidas.DesTipo1} Kg</option>
                                                <option className={`${cogidas.DesTipo2 ? "text-success" : ""}`} value="DesTipo2">MENORES a 30mm - {cogidas.DesTipo2} Kg</option>
                                                <option className={`${cogidas.DesTipo3 ? "text-success" : ""}`} value="DesTipo3">MANCHADA - {cogidas.DesTipo3} Kg</option>
                                                <option className={`${cogidas.DesTipo4 ? "text-success" : ""}`} value="DesTipo4">PICADA - {cogidas.DesTipo4} Kg</option>
                                                <option className={`${cogidas.DesTipo5 ? "text-success" : ""}`} value="DesTipo5">BOTRITIS - {cogidas.DesTipo5} Kg</option>
                                                <option className={`${cogidas.DesTipo6 ? "text-success" : ""}`} value="DesTipo6">MANCHA NEGRA - {cogidas.DesTipo6} Kg</option>
                                                <option className={`${cogidas.DesTipo7 ? "text-success" : ""}`} value="DesTipo7">CINTURA ACENTUADA - {cogidas.DesTipo7} Kg</option>
                                                <option className={`${cogidas.DesTipo8 ? "text-success" : ""}`} value="DesTipo8">OMBLIGO +1cm - {cogidas.DesTipo8} Kg</option>
                                                <option className={`${cogidas.DesTipo9 ? "text-success" : ""}`} value="DesTipo9">THRIPS - {cogidas.DesTipo9} Kg</option>
                                                <option className={`${cogidas.DesTipo10 ? "text-success" : ""}`} value="DesTipo10">PULGON - {cogidas.DesTipo10} Kg</option>
                                                <option className={`${cogidas.DesTipo11 ? "text-success" : ""}`} value="DesTipo11">GUSANO - {cogidas.DesTipo11} Kg</option>
                                                <option className={`${cogidas.DesTipo12 ? "text-success" : ""}`} value="DesTipo12">FIBRA BLANCA - {cogidas.DesTipo12} Kg</option>
                                                <option className={`${cogidas.DesTipo13 ? "text-success" : ""}`} value="DesTipo13">VIOLACEA - {cogidas.DesTipo13} Kg</option>
                                                <option className={`${cogidas.DesTipo14 ? "text-success" : ""}`} value="DesTipo14">VELLOCIDAD - {cogidas.DesTipo14} Kg</option>
                                                <option className={`${cogidas.DesTipo15 ? "text-success" : ""}`} value="DesTipo15">PEDUNCULO +20MM - {cogidas.DesTipo15} Kg</option>
                                                <option className={`${cogidas.DesTipo16 ? "text-success" : ""}`} value="DesTipo16">OXIDO - {cogidas.DesTipo16} Kg</option>
                                                <option className={`${cogidas.DesTipo17 ? "text-success" : ""}`} value="DesTipo17">OTROS - {cogidas.DesTipo17} Kg</option>
                                            </select>
                                            <input

                                                type="number"
                                                name="valorDescarte"
                                                min="0"
                                                max="100"
                                                step="any"
                                                className={`form-control col-md-3 col-8`}
                                                value={valorCalidad.valorDescarte}
                                                onChange={handleInputValorCalidad}
                                            />

                                            <button className=" btn btn-outline-dark col-4 col-md-2" name="btnDescarte" onClick={handleSubmitCalidadDescarte}><i className="fas fa-plus btnaddCalidad"></i>
                                            </button>

                                        </div>
                                    </div>


                                    <label htmlFor="" className="col-12 border-bottom p-1">Calibres</label>

                                    <div className="form-group col-12 col-md-3 cogida">
                                        <div className="row">

                                            <div className="col-6 col-md-6">
                                                <label htmlFor="">% 30 - 45</label>
                                                <input
                                                    required
                                                    type="number"
                                                    name="porcCalibre1"
                                                    min="0"
                                                    max="100"
                                                    className={`form-control ${llenado.porcCalibre1}`}
                                                    value={cogidas.porcCalibre1}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group col-6 col-md-6">
                                                <label htmlFor="">Kg</label>
                                                <input
                                                    required
                                                    type="number"
                                                    name="pesoCalibre1"

                                                    className={`form-control ${llenado.pesoCalibre1}`}
                                                    value={cogidas.pesoCalibre1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group  col-12 col-md-3 cogida">
                                        <div className="row">
                                            <div className="col-6 col-md-6">
                                                <label htmlFor="">% 45 - 55</label>
                                                <input
                                                    required
                                                    type="number"
                                                    name="porcCalibre2"
                                                    placeholder=""
                                                    className={`form-control ${llenado.porcCalibre2}`}
                                                    value={cogidas.porcCalibre2}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group col-6 col-md-6">
                                                <label htmlFor="">Kg</label>
                                                <input
                                                    required
                                                    type="number"
                                                    name="pesoCalibre2"
                                                    placeholder=""
                                                    className={`form-control ${llenado.pesoCalibre2}`}
                                                    value={cogidas.pesoCalibre2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group  col-12 col-md-3 cogida">
                                        <div className="row">
                                            <div className="col-6 col-md-6">
                                                <label htmlFor="">% 55 - 65</label>
                                                <input
                                                    required
                                                    type="number"
                                                    name="porcCalibre3"
                                                    placeholder=""
                                                    className={`form-control ${llenado.porcCalibre3}`}
                                                    value={cogidas.porcCalibre3}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group col-6 col-md-6">
                                                <label htmlFor="">Kg</label>
                                                <input
                                                    required
                                                    type="number"
                                                    name="pesoCalibre3"
                                                    placeholder=""
                                                    className={`form-control ${llenado.pesoCalibre3}`}
                                                    value={cogidas.pesoCalibre3}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group  col-12 col-md-3 cogida">
                                        <div className="row">
                                            <div className="col-6 col-md-6">
                                                <label htmlFor="">% 65 +</label>
                                                <input
                                                    required
                                                    type="number"
                                                    name="porcCalibre4"
                                                    placeholder=""
                                                    className={`form-control ${llenado.porcCalibre4}`}
                                                    value={cogidas.porcCalibre4}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group col-6 col-md-6">
                                                <label htmlFor="">Kg</label>
                                                <input
                                                    required
                                                    type="number"
                                                    name="pesoCalibre4"
                                                    placeholder=""
                                                    className={`form-control ${llenado.pesoCalibre4}`}
                                                    value={cogidas.pesoCalibre4}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    {params.id ? (
                                        <></>
                                    ) : (
                                        <>
                                            <label htmlFor="" className="col-12 border-bottom p-1">Proxima Cosecha <strong className="text-danger">{proximaCosecha.numeroCosecha}</strong></label>
                                            <div className="form-group  col-12 col-md-6 ">
                                                <div className="row">
                                                    <div className="col-5 col-md-4 input-proyeccion">
                                                        <label htmlFor="">Peso </label>
                                                        <input

                                                            type="number"
                                                            name="proPesoCosecha"
                                                            placeholder=""
                                                            className="form-control"
                                                            value={proximaCosecha.proPesoCosecha}
                                                            onChange={ChangeProxFechaCosecha}
                                                        />
                                                    </div>
                                                    <div className="col-7 col-md-5 input-proyeccion">
                                                        <label htmlFor="">Fecha </label>
                                                        <input

                                                            type="date"
                                                            name="proFechaCosecha"
                                                            placeholder=""
                                                            className="form-control"
                                                            value={moment(proximaCosecha.proFechaCosecha).format('YYYY-MM-DD')}
                                                            onChange={ChangeProxFechaCosecha}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className="form-group row col-12 offset-md-3 text-center ">
                                    <div className="col-md-3 p-2">
                                        {params.id ? (<button className=" btn btn-dark col-12  ">Editar Cosecha</button>)
                                            : (<button className=" btn btn-dark col-12  ">Crear Cosecha</button>)}
                                    </div>
                                    <div className="col-md-3 p-2">
                                        <button onClick={handleCancel} className="btn btn-outline-secondary col-12">Cancelar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CogidaForm
