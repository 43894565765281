import React,{MouseEventHandler} from 'react'
import { Button, Modal } from 'react-bootstrap';
import {Pallets} from '../boxModal'
import * as almacenService from '../../AlmacenMP/AlmacenMPServices'
import { toast } from 'react-toastify';
interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement> | any,
    pallet:Pallets,
    afterModalConfirm: ()=> void,
    esSalida: number,
    idUsuario: string
}

interface PropsDet {
    show : boolean,
    onHide:MouseEventHandler<HTMLElement> | any,
    id: string,
    afterModalConfirmDet: ()=> void
}
export const  ModalConfirm = (props:Props) => {
    
    const handleDeletePallet = async()=>{
        
        if (props.esSalida===0) {
            console.log("ELIMINANDO EL PALLET CON ID", props.pallet._id)
            console.log("ELIMINANDO EL PALLET CON USERID", props.idUsuario)
            await almacenService.deleteOnePallet(props.pallet._id,props.idUsuario)
            props.afterModalConfirm()
            toast.error('Pallet Eliminado')
        }else if(props.esSalida===1){
            await almacenService.salidaPallet(props.pallet._id);
            props.afterModalConfirm();
            toast.warning('Salida Realizada')
        }
    }
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size='sm'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                       {props.esSalida} {props.esSalida?('Salida'):('Eliminar')} Pallet <br/> {props.pallet.numPallet}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={props.esSalida?('outline-warning'):('outline-danger')} onClick={handleDeletePallet} >{props.esSalida?('Dar Salida'):('Eliminar')}</Button>
                    <Button variant="danger" onClick={props.onHide} >Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export const ModalConfirmDeleteDet = (props: PropsDet) =>{

    const handleDeleteDetPallet = async() =>{
        try {
            console.log(props.id)
            await almacenService.deleteOneDetPallet(props.id)
            props.afterModalConfirmDet()
            toast.info('Detalle Eliminado')
        } catch (error:any) {
            toast.error(error.message)
        }
    }
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size='sm'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Esta seguro de eliminar el detalle?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleDeleteDetPallet} >Eliminar</Button>
                    <Button variant="danger" onClick={props.onHide} >Cancelar</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
       
}
// export default ModalConfirm
