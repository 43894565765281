import axios from 'axios'
import {Bases, tipoMovimientoPlanta, divPallet, salidaPallet as TypeSalidaPallet} from '../almacen'
import {Pallets, palletSalidaEdit} from '../../ModalBox/boxModal'
import {pallets} from '../../ModalPaletizadoTransito/paletizado'
import {detallePallet} from '../../ModalBox/boxModal'
import apiURL from '../../../../context/apiURL'
const API = apiURL.API;

export const getBases = async (almacen: string) => {
    return await axios.get<Bases[]>(`${API}/selectBases/${almacen}`)    
 }

export const createOnePallet = async (data: Pallets | undefined) =>{
    return await axios.post(`${API}/createOnePallet`, data)
}

export const getPalletsByBase = async (id: string)=>{
    return await axios.get<Pallets[]>(`${API}/getPalletsByBase/${id}`)
}

export const editOnePallet = async(data: Pallets)=>{
    return await axios.post(`${API}/editOnePallet`, data);
}
export const editOnePalletSalida = async(data: palletSalidaEdit)=>{
    return await axios.post(`${API}/editOnePalletSalida`, data);
}


export const deleteOnePallet = async(id: string)=>{
    return await axios.get(`${API}/deleteOnePallet/${id}`)
}

export const getPalletsByIdBase = async(id: string)=>{
    return await axios.get(`${API}/getPalletsByIdBase/${id}`)
}

export const  getDetPalletsById = async(id: string) =>{
    return await axios.get(`${API}/getDetPalletsById/${id}`)
}

export const createDetPallet = async(data: detallePallet) =>{
    return await axios.post(`${API}/createDetPallet`, data)
}

export const updateDetPallet = async(data: detallePallet) =>{
    return await axios.post(`${API}/updateDetPallet`, data)
}

export const busquedaBaseByNumPallet = async(id: string)=>{
    return await axios.get(`${API}/busquedaBaseByNumPallet/${id}`)
}
export const busquedaBaseByFormato = async(data: any)=>{
    return await axios.post(`${API}/busquedaBaseByFormato`, data)
}
export const deleteOneDetPallet = async(id:string)=>{
    return await axios.put(`${API}/deleteOneDetPallet/${id}`)
}

export const busquedaFormato = async(id: string) =>{
    return await axios.get(`${API}/busquedaFormato/${id}`)
}
export const crearFormato = async(data: any) =>{
    return await axios.post(`${API}/createFormato`, data)
}
export const getEnvases = async()=>{
    return await axios.get(`${API}/getEnvases`);
}
export const crearEnvase = async(data: any)=>{
    return await axios.post(`${API}/crearEnvase`, data);
}

export const busquedaEnvase = async(id: string) =>{
    return await axios.get(`${API}/busquedaEnvase/${id}`)
}
export const getTapas = async()=>{
    return await axios.get(`${API}/getTapas`);
}
export const createTapas = async(data: any)=>{
    return await axios.post(`${API}/createTapas`, data);
}

export const busquedaTapas = async(text: string)=>{
    return await axios.get(`${API}/busquedaTapas/${text}`);
}

export const selectOnePallet = async(id:string)=>{
    return await axios.get(`${API}/selectOnePallet/${id}`)
}
export const getAllZonas = async()=>{
    return await axios.get(`${API}/getAllZonas`)
}
export const getAllAlmacenes = async()=>{
    return await axios.get(`${API}/getAllAlmacenes`)
}
export const getAllAlmacenesPlanta = async()=>{
    return await axios.get(`${API}/getAllAlmacenesPlanta`)
}
export const getZonasByAlmacen = async(id: string)=>{
    return await axios.get(`${API}/getZonasByAlmacen/${id}`)
}
export const getBasesByZona = async(id:string)=>{
    return await axios.get(`${API}/getBasesByZona/${id}`)
}
export const moveOnePallet = async(data:any) =>{
    return await axios.post(`${API}/moveOnePallet`, data)
}
// SALIDA PALLET
export const salidaPallet = async(id:string) =>{
    return await axios.get(`${API}/salidaPallet/${id}`);
}

export const palletToSalida = async(id: string) =>{
    return await axios.get(`${API}/palletToSalida/${id}`);
}

//PALLETS PALETIZADO
export const createPalletPaletizado = async(data: pallets)=>{
    return await axios.post(`${API}/createPalletPaletizado`, data)
}

export const busquedaPalletsPaletizado = async(id: string, base: string) =>{
    return await axios.get(`${API}/busquedaPalletsPaletizado/${id}/${base}`)
}

export const createSalidaPallet = async(data: TypeSalidaPallet) =>{
    return await axios.post(`${API}/createSalidaPallet`, data);
}

export const getTipoMovimientoPlanta = async() =>{
    return await axios.get(`${API}/createSalidaPallet`);
}

export const dividirPallet = async(data: divPallet) =>{
    return await axios.post(`${API}/dividirPallet`, data)
}

/// REPORTES 
export const getReporteDetallePallets = async(data: any) => {
    return await axios.post(`${API}/getReporteDetallePallets`, data)
}

export const reporteUbicacionPallets = async(data: any)=>{
    return await axios.post(`${API}/reporteUbicacionPallets`, data)
}
export const getRSP = async(data: any)=>{
    return await axios.post(`${API}/getRSP`, data)
}
export const reporteFormatoVsProd = async(data:any)=>{
    return await axios.post(`${API}/reporteFormatoVsProd`, data)
}
export const createExcelSalidaPallets = async(data:any)=>{
    return await axios.post(`${API}/createExcelSalidaPallets`, data)
}

export const getReportePallet = async(nameFile: string) =>{
    return await axios.get(`${API}/getReportePallet/${nameFile}`,  {headers:{
        'Content-Disposition': "attachment; filename=template.xlsx",
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }, responseType: 'blob'} )
}
export const getReporteDetallePallet = async(nameFile: string) =>{
    return await axios.get(`${API}/getReporteDetallePallet/${nameFile}`,  {headers:{
        'Content-Disposition': "attachment; filename=template.xlsx",
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }, responseType: 'blob'} )
} 
export const testGetPallets = async() =>{
    return await axios.get(`${API}/testGetPallets`, {responseType: 'blob'} )
}

export const createExcelDetallePallets = async(data:any)=>{
    return await axios.post(`${API}/createExcelDetallePallets`, data )
}

export const createExcelEstadoPallets = async(data:any)=>{
    return await axios.post(`${API}/createExcelEstadoPallets`, data )
}

export const createExcelFormatoProduccionPallets = async(data:any)=>{
    return await axios.post(`${API}/createExcelFormatoProduccionPallets`, data )
}

export const getPalletAndDetalleByID = async(id: string)=>{
    return await axios.get(`${API}/getPalletAndDetalleByID/${id}`)
}

export const getRepNivelPallet = async (data: any) => {
    return await axios.post(`${API}/getRepNivelPallet`, data);
}

//CLIENTES PROVEEDORES
export const busquedaProveedor = async(id: string) => {
    return await axios.get(`${API}/busquedaproveedor/${id}`);
}

export const busquedaPcliente = async(id: string) =>{
    return await axios.get(`${API}/busquedaPclientes/${id}`)
}
export const createPcliente = async(data: any) =>{
    return await axios.post(`${API}/createPcliente`, data)
}

