import moment from 'moment';
import React, { useState, useEffect, MouseEventHandler } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { MainContainer, ContainerInputs, InputGeneral, ButtonGeneral} from '../../../stylesGeneral/mainElements'
import cosechadoImg from '../../../Media/png/cosechado.png'
import { toast } from 'react-toastify';
import * as proyeccionCtrl from '../ProyeccionesService'

interface Props {
    show: boolean,
    idProyeccion: string,
    numeroCosecha: number,
    fechaCosechada: string,
    jabasCosechado: number,
    onHide: MouseEventHandler<HTMLElement>|any,
    onHideFunction: () => void
}
function ModalCosechado(props: Props) {
    const initialStateCosechado = {id: '', fechaCosechada: '', jabasCosechado: 0}
    const [cosechado, setCosechado] = useState(initialStateCosechado)
    const [stateSubmitCosechado, setStateSubmitCosechado] = useState(false)
    const handleChange = (e: any) =>{
        setCosechado({...cosechado, [e.target.name]: e.target.value})
    }
    const submitCosecha = async()=>{
       try{
           setStateSubmitCosechado(true)
        if (!cosechado.id || !cosechado.fechaCosechada || !cosechado.jabasCosechado) {
            setStateSubmitCosechado(false)
            return toast.error("Datos incompletos")    
         } 
        const submitCosechado = await proyeccionCtrl.editProyCosechado(cosechado);
        if(submitCosechado.data.ErrorMessage){
            setStateSubmitCosechado(false)
            return toast.error(submitCosechado.data.ErrorMessage)
        }
         props.onHideFunction();
         toast.success("Editado ✓")
         setStateSubmitCosechado(false)
       } catch(error:any){
        setStateSubmitCosechado(false)
           toast.error(error.message)
       }     
    }
    useEffect(() => {
      if(!props.show){
        setCosechado(initialStateCosechado)
      }else{
          setCosechado({...cosechado, 
            id: props.idProyeccion, 
            fechaCosechada: props.fechaCosechada?moment.tz(props.fechaCosechada, "America/Lima").format("YYYY-MM-DD"):'',
            jabasCosechado: props.jabasCosechado
        })
        
      }
    }, [props.show])
    
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>   
                <MainContainer>
                    <ContainerInputs width='100%' >
                        <img src={cosechadoImg} style={{width:"100px", height:"100px", margin:'auto'}} alt=""/>
                        <h3>Lote Cosechado #{props.numeroCosecha}</h3>
                    </ContainerInputs>                 
                    <ContainerInputs  width='100%'>
                        <label htmlFor="" >Fecha</label>
                        <InputGeneral width="100%" height="50px" type="date" name="fechaCosechada" 
                            onChange={handleChange} 
                            value={cosechado.fechaCosechada} 
                        />
                    </ContainerInputs>                
                    <ContainerInputs  width='100%'>
                        <label htmlFor="" >Jabas</label>
                        <InputGeneral width="100%" height="50px" type="number" min="0" step="any" name="jabasCosechado" 
                            onChange={handleChange} 
                            value={cosechado.jabasCosechado} 
                        />
                    </ContainerInputs>
                </MainContainer>
                   
                </Modal.Body>
                <Modal.Footer>
                    <ButtonGeneral width="100%" height="70px" color="rgba(29, 131, 72 )" onClick={submitCosecha} disabled={stateSubmitCosechado}>
                        {stateSubmitCosechado ? 
                        <div className="spinner-border text-ligth" role="status">
                            <span className="sr-only">Loading...</span>
                        </div> :
                        'Guardar Cosecha'}
                        </ButtonGeneral>
                    <ButtonGeneral width="100%" height="60px" color="" onClick={props.onHide}>Cancelar</ButtonGeneral>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalCosechado