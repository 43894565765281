import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react'
import { UsuarioInterface } from './usuario'
import * as usuarioService from './UsuarioService'
import {toast} from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'

type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
interface Params {
  id: string
}


const UsuarioForm = () => {
  const history = useNavigate();
  const params = useParams<any>();
 
  const initialState = {
    nombre: '',
    usuario: '',
    password: '',
    tipo: ''
  }
  const [usuario, setUsuario] = useState<UsuarioInterface>(initialState);

  const handleInputChange = (e: InputChange) => {
    setUsuario({...usuario, [e.target.name]: e.target.value});
  }
  const handleSubmit = async (e: FormEvent<HTMLFormElement>)=>{
    e.preventDefault();

    if(!params.id){
      await usuarioService.createUsuario(usuario);
      toast.success('Nuevo Usuario fue Anadido');
      setUsuario(initialState);
    }else{
      await usuarioService.updateUsuarios(params.id, usuario);
    }

    history('/');
  }

  const getUsuario = async (id: string) => {
    const res = await usuarioService.getUsuario(id);
    // const {nombre, usuario, password, tipo} = res.data;
    setUsuario(res.data);
  }

  useEffect(() => {
    
    if(params.id)  getUsuario(params.id);
  },[])
  return (
    <div className="row">
      <div className="col-md-4 offset-md-4">
        <div className="card">
          <div className="card-body">
            {params.id ? (
              <h3>Editar Usuario</h3>
            ):(<h3>Nuevo Usuario</h3>)
            }
            
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  required
                  type="text"
                  name="nombre"
                  placeholder="Nombre de Usuario"
                  className="form-control"
                  value={usuario.nombre}
                  onChange={handleInputChange}
                  autoFocus />
              </div>
              <div className="form-group">
                <input
                  required
                  type="text"
                  name="usuario"
                  placeholder="Usuario"
                  className="form-control"
                  value={usuario.usuario}
                  onChange={handleInputChange}/>
              </div>
              <div className="form-group">
                <input
                  required
                  type="password"
                  name="password"
                  placeholder="Password"
                  className="form-control"
                  value={usuario.password}
                  onChange={handleInputChange} />
              </div>
              <div className="form-group">
                <select  name="tipo"  onChange={handleInputChange} className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                  <option value={''} >Seleccione</option>
                  <option value={"VISITA"}>VISITA</option>
                  <option value={"TECNICO"}>TECNICO</option>
                  <option value={"EVALUADOR"}>EVALUADOR</option>
                  <option value={"PROYECTISTA"}>PROYECTISTA</option>
                  <option value={"JEFECAMPO"}>JEFE CAMPO</option>
                  <option value={"PLANTA"}>PLANTA</option>
                  <option value={"CALIDADPRODUCCION"}>CALIDAD PRODUCCION</option>
                  <option value={"ADMINISTRADOR"}>ADMINISTRADOR</option>
                  
                </select>
              </div>
              {params.id ? (
                  <button className="btn btn-info">Editar Usuario</button>
                  ):(
                <button className="btn btn-primary">Crear Usuario</button>
                )
              }
            </form>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default UsuarioForm
