import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { IndexMapaContainer, IndexLabel, SelectIndex, OptionIndex } from './IndexMapasElements'


function IndexMapas() {
    const history = useNavigate();
    const [almacen, setAlmacen] = useState('');
    const handleChange = (e:any)=>{
        console.log(e.target.value);
        if(e.target.value ==='ALSURPERU'){
            history('/almacenAlsurperu')
        }if(e.target.value ==='ALSURPERU-EXTERNO'){
            history('/almacenAlsurperuExt')
        }if(e.target.value ==='ALMACEN-CALZADO'){
            history('/almacenCalzado')
        }if(e.target.value ==='ALMACEN-CONGELADO-AQP'){
            history('/almCongeladoAqp')
        }if(e.target.value ==='ALMACEN-CUSCO-APT'){
            history('/almCuscoApt')
        }if(e.target.value ==='ALMACEN-TRANSITO'){
            history('/almTransito')
        }if(e.target.value ==='ALMACEN-CUSCO-CAM3'){
            history('/almCuscoCamara')
        }         
    }
    return (
        <>
            <IndexMapaContainer>
                {/* <IndexLabel>
                    Seleccione un Almacen
                    </IndexLabel> */}
                <SelectIndex onChange={handleChange}>
                    <OptionIndex value='' >SELECCIONE ALMACEN</OptionIndex>
                    <OptionIndex value='ALSURPERU' >ALSUR PERU</OptionIndex>
                    <OptionIndex value='ALSURPERU-EXTERNO'>ALSURPERU EXTERNO</OptionIndex>
                    <OptionIndex value='ALMACEN-CALZADO'>ALMACEN CALZADO</OptionIndex>
                    <OptionIndex value='ALMACEN-CONGELADO-AQP'>ALMACEN CONGELADO AQP</OptionIndex>
                    <OptionIndex value='ALMACEN-CUSCO-APT'>ALMACEN CUSCO - SALON APT</OptionIndex>
                    <OptionIndex value='ALMACEN-CUSCO-CAM3'>ALMACEN CUSCO - CAMARA 3</OptionIndex>
                    <OptionIndex value='ALMACEN-TRANSITO'>ALMACEN TRANSITO</OptionIndex>
                </SelectIndex>
            </IndexMapaContainer>

        </>
    )
}

export default IndexMapas
