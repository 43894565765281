export const Data= [
    {
        numero : 1,
        nombre : 'A2',

    },
    {
        numero : 2,
        nombre : 'A2',
        
    },
    {
        numero : 3,
        nombre : 'A3',
        
    },
    {
        numero : 4,
        nombre : 'A4',
        
    }
]