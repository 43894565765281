import axios from 'axios'
import apiURL from '../../context/apiURL'
import {regMarcacion} from './registroPersonal'
const API = apiURL.API;
export const getTime = async() => {
    return await axios.get("https://finalspaceapi.com/api/v0/character/?limit=2", {
        headers: {
          'Access-Control-Allow-Origin': true,
        },});
}
export const getAreas = async() =>{
  return await axios.get(`${API}/getAreas`)
}
export const getAreasActivos = async() =>{
  return await axios.get(`${API}/getAreasActivos`)
}
export const crearArea = async(data: any)=>{
  return await axios.post(`${API}/crearArea`, data)
}
export const editarArea = async(data: any)=>{
  return await axios.post(`${API}/editarArea`, data)
}
export const crearTemas = async(data:any)=>{
  return await axios.post(`${API}/crearTemas`, data);
}
export const editarTema = async(data:any)=>{
  return await axios.post(`${API}/editarTema`, data);
}
export const getTemasbyAreasActivos = async(id: string)=>{
  return await axios.get(`${API}/getTemasbyAreasActivos/${id}`)
}
export const getAllCapacitadores = async() =>{
  return await axios.get(`${API}/getAllCapacitadores`)
}
export const getAllCapacitadoresActivos = async() =>{
  return await axios.get(`${API}/getAllCapacitadoresActivos`)
}
export const editarCapacitador = async(data: any) =>{
  return await axios.post(`${API}/editarCapacitadores`, data)
}
export const crearCapacitador = async(data: any) =>{
  return await axios.post(`${API}/crearCapacitadores`, data)
}
export const registrarMarcacion = async(data: regMarcacion) =>{
  return await axios.post(`${API}/registrarMarcacion`, data)
}
export const getInitialDataBusqueda = async() => {
  return await axios.get(`${API}/getInitialDataBusqueda`)
}
export const reporteGeneralCap = async(data: any) => {
  return await axios.post(`${API}/reporteGeneralCap`, data)
}
export const sincPendientesReporte = async(data: any) => {
  return await axios.post(`${API}/sincPendientesReporte`, data)
}


export const generateReporteGenExcel = async(data:any) =>{
  return await axios.post(`${API}/generateReporteGenExcel`, data);
}


export const sendReporteGenExcel = async(nameFile: string) =>{
  return await axios.get(`${API}/sendReporteGenExcel/${nameFile}`,  {headers:{
      'Content-Disposition': "attachment; filename=template.xlsx",
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }, responseType: 'blob'})
}
