import axios from 'axios'
import {dashboard} from './dashboard'

import apiURL from '../../context/apiURL'
const API = apiURL.API;

export const getLocalidades = async () => {
     
   return await axios.get<dashboard[]>(`${API}/localidades`)    
}

export const getProduccion = async (data:any) => {
     
   return await axios.post(`${API}/getProduccion`, data)    
}


export const createLocalidades = async (localidad: dashboard) => {
   return await axios.post(`${API}/localidades`, localidad)    
}

export const getLocalidad = async (id: string) => {
   
   return await axios.get<dashboard>(`${API}/localidad/${id}`)    
}

export const updateLocalidades = async (id: string, localidad: dashboard) => {
   return await axios.put<dashboard>(`${API}/localidades/${id}`, localidad)    
}

export const deleteLocalidad = async (id: string) => {
   return await axios.delete<dashboard>(`${API}/localidades/${id}`)    
}

// FUNCIONES ADICIONALES
export const getLocalidadesVSdep = async (id: string) => {
 
   return await axios.get<dashboard[]>(`${API}/localidades/${id}`)    
}