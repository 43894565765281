import React, { useEffect, useState, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
// import moment from 'moment';
import { toast } from 'react-toastify'
import { proveedor } from './proveedor'
import * as proveedorService from './ProveedorService'
type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;



function ProveedorList() {
    const history = useNavigate();

    const [proveedores, setProveedores] = useState<proveedor[]>([]);
    const [busquedaProveedor, setbusquedaProveedor] = useState({
        razonSocial:''
    });

    const handleInputBusqueda = (e: InputChange) => {
        
        setbusquedaProveedor({...busquedaProveedor, [e.target.name]: e.target.value})
    }
    const handleSubmitBusqueda = async () => {
        try {
            if(busquedaProveedor.razonSocial==='') {
                loadProveedores();
                toast.success('Busqueda exitosa');
            }else{
                const resBusqueda = await proveedorService.busquedaProveedor(busquedaProveedor.razonSocial)
                setProveedores(resBusqueda.data);
                toast.success('Busqueda exitosa')
            }
        } catch (error:any) {
            toast.error('Error consulte con el administrador')
        }
    }

    const loadProveedores = async () => {
        const getProveedores = await proveedorService.allProveedores();
        console.log(getProveedores)
        setProveedores(getProveedores.data)
    }
    useEffect(() => {
        loadProveedores();
    }, [])
    return (
        <div>
            <div className="text-center pb-3 ">
                <h3 className="">PROVEEDORES
                <button className="btn btn-outline-dark " onClick={() => history('/new-proveedor')}><i className="fas fa-plus btnadd"></i>
                    </button>
                </h3>

            </div>

            <div className="row mx-auto col-md-8 col-12">
                <div className="form-group col-8 col-md-5 align-self-end">
                    <label htmlFor="">Busqueda</label>
                    <input

                        type="text"
                        className="form-control"
                        name="razonSocial"
                        placeholder="Nombre / RS "
                        value={busquedaProveedor.razonSocial}
                        onChange={handleInputBusqueda}

                    />
                </div>
                <div className="form-group col-4 col-md-1 align-self-end text-center">
                    <button onClick={handleSubmitBusqueda} className="btn btn-outline-dark"><i className="fas fa-search schFormulario"></i></button>
                </div>
            </div>
            <div className="table-responsive table-striped col-12 col-md-8 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">

                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">NOMBRE/RS</th>
                            <th scope="col">DNI/RUC</th>
                            <th scope="col">DIRECCION</th>

                            <th scope="col">_OPCIONES_</th>
                        </tr>
                    </thead>
                    <tbody>
                        {proveedores.map((proveedor) => {
                            return <tr key={proveedor._id}>
                                <td>{proveedor.nombre}</td>
                                <td>{proveedor.numeroDocumento}</td>
                                <td>{proveedor.direccion}</td>

                                <td className="row">

                                    <div className="col-4">
                                        <i onClick={() => history(`/update-proveedor/${proveedor._id}`)} className="fas fa-pen"></i>
                                    </div>
                                    {/* <div className="col-4">
                                        <i id={lote._id} onClick={() => lote._id && handleDelete(proveedor._id)} className="fas fa-trash"></i>
                                    </div> */}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ProveedorList
