
import styled, {css} from 'styled-components'
interface Size{
    size: string
    
}
interface inputs{
    bg: string
}
interface container{
    align: string
}
interface table{
    height: string
}
export const Container = styled.div<container>`
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${({align})=>align};
    @media screen and (max-width: 900px){
        
        justify-content: center;
        align-items: center;
        width: 100%;
   }
`
export const Form = styled.form`
 
`
export const FormContainer = styled.div<container>`
    padding: 5px;
    display: flex;
    display: -webkit-flex;
   display: --ms-flexbox;
   flex-direction:row;    
   flex-wrap: wrap;
   justify-content: ${({align})=> align ? align: 'center'};
   align-items: flex-end;
   
`
export const ContainerInput = styled.div<Size>`
    
    
    padding: 5px;
    display: flex;
    flex-direction: column;
    width: ${({size})=> size ? size: '200px'};
    
    @media screen and (max-width: 991px){
        width: 100%;
        
   }
`

export const Input = styled.input<inputs>`

   
    top: 0;
    left: 0;
    font-family : Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-weight: bold;
    font-size: 20px;
    border: 2px solid #213326 !important;
    border-radius: 8px;
    font-family: inherit;
    color: ${({bg})=>{
        if(bg==='#213326'){
            return '#fff'
        }else{
            return '#213326'
        }
    }};
    outline: none;
    padding: 0.4rem;
    background: ${({bg})=> bg ? bg: '#fff'};
    
    &:hover{        
        border: 2px solid #213326 !important;
         box-shadow: 0px 0px 3px  #213326 !important;
    }
    @media screen and (max-width: 991px){
        width : 100%;
        padding: 0.8rem;
    }
`
export const InputTextArea = styled.textarea<inputs>`

   
    top: 0;
    left: 0;
    font-family : Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-weight: bold;
    font-size: 20px;
    border: 2px solid #213326 !important;
    border-radius: 8px;
    font-family: inherit;
    color: ${({bg})=>{
        if(bg==='#213326'){
            return '#fff'
        }else{
            return '#213326'
        }
    }};
    outline: none;
    padding: 0.4rem;
    background: ${({bg})=> bg ? bg: '#fff'};
    
    &:hover{        
        border: 2px solid #213326 !important;
         box-shadow: 0px 0px 3px  #213326 !important;
    }
    @media screen and (max-width: 991px){
        width : 100%;
        padding: 0.8rem;
    }
`
export const Label = styled.label`
   
    left: 1rem;
    top: 0.8rem;
    padding: 0 0.5rem;
    color: black;
    cursor: text;
    font-size: 15px;
    font-weight: 400;
    color: #7c7c7c;
`

export const Select = styled.select<inputs>`
      top: 0;
    left: 0;
    font-family : Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-weight: bold;
    font-size: 20px;
    border: 2px solid #213326 !important;
    border-radius: 8px;
    font-family: inherit;
    color: ${({bg})=>{
        if(bg==='#213326'){
            return '#fff'
        }else{
            return '#213326'
        }
    }};
    outline: none;
    padding: 0.4rem;
    background: ${({bg})=> bg ? bg: '#fff'};
    
    &:hover{        
        border: 2px solid #213326 !important;
         box-shadow: 0px 0px 3px  #213326 !important;
    }
    @media screen and (max-width: 991px){
        width : 100%;
        padding: 0.8rem;
    }
`
export const Option = styled.option`
    
`

export const TableContainer = styled.div<table>`
max-height: ${({height}) => height?  height: '50%'};

`
export const Table = styled.table`

`
export const THead = styled.thead`


`
export const TR = styled.tr`

`
export const TRbody = styled.tr`
    cursor: pointer;
&:hover{
        background: #213326;
        color: #fff;
    }
`
export const TH = styled.th`
    font-family: sans-serif;
    
`
export const TBody = styled.tbody`

`
export const TD = styled.td`
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 11px;
   
    
`

export const SPAN = styled.span`
    border: 2px solid #213326 !important;
    background: #213326;
    color: #fff;
    border-radius: 8px;
    padding: 0 0.4rem;
    font-weight: bolder;
    font-size: 30px;
    width: 80px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
    &:hover{
        background: #fff;
        color: #213326;
    }
`

export const ButtonForm= styled.button`
     top: 0;
    left: 0;
    font-family : Verdana, Geneva, Tahoma, sans-serif  ;
    font-weight: bold;
    font-size: 30px;
    border: 2px solid #213326 !important;
    border-radius: 8px;
    /* font-family: inherit; */
    cursor: pointer;
    /* width: 100%; */
    &:hover{
        background: #213326;
        color: #fff;
    }
    @media screen and (max-width: 991px){
        width : 100%;
        padding: 0.8rem;
    }
`
export const SpanOptForm = styled.span`
    top: 0;
    left: 0;
    border: 2px solid #245e35 !important;
    background: #245e35;
    color: #fff;
    border-radius: 8px;
    padding: 0 0.4rem;
    font-weight: bolder;
    font-size: 30px;
    
    text-align: center;
    /* margin: 5px; */
    cursor: pointer;
    &:hover{
        background: #fff;
        color: #213326;
    }
`