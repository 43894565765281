import React, { useEffect, useState, ChangeEvent } from 'react'
import { rangoFechasPrint, listObjetivos } from '../typesCalidadProduccion'
import ModalRegObjetivos from './ModalRegObjetivos'

import * as CalidadProduccionServices from '../CalidadProduccionServices'
import { toast } from 'react-toastify';
import moment from 'moment';
import gifLoad from '../../../Media/gifs/load.gif'
import { BtnTd, InputTd } from './RegObjetivosElements'

type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
var myCounter = 0;
let timeout: any = null;
function RegObjetivos() {
    const [rangoFechas, setRangoFechas] = useState({ fechaInicio: '', fechaFin: '' })
    const [arrayFechas, setArrayFechas] = useState<rangoFechasPrint[]>([]);
    const [arrayFechasMoment, setArrayFechasMoment] = useState<rangoFechasPrint[]>([]);
    const [listObjetivos, setListObjetivos] = useState<listObjetivos[]>([]);
    const [busquedaListObjetivos, setBusquedaListObjetivos] = useState<listObjetivos[]>([])
    const [textBusqueda, setTextBusqueda] = useState('')
    const [counter, setCounter] = useState(0);
    const [busquedaObjetivos, setBusquedaObjetivos] = useState();
    const [setValueLoad, setSetValueLoad] = useState(false)

    //UseState para Modal
    const [stateModal, setStateModal] = useState(false)

    const hideModal = () => {
        setStateModal(false)
    }
    const showModal = () => {
        if (listObjetivos?.length! <= 0 || listObjetivos === undefined) return toast.info("No hay Registros")
        setStateModal(true)
    }
    const afterHidenModal = () => {
        setStateModal(false)
    }
    const changeFechas = (e: InputChange) => {
        setRangoFechas({ ...rangoFechas, [e.target.name]: e.target.value })
    }
    const getAllRegObjetivos = async () => {
        setArrayFechas([]);
        setListObjetivos([])
        try {
            if (!rangoFechas.fechaFin || !rangoFechas.fechaInicio) return toast.warning("Introdusca Rango de Fechas")
            //** Configuracion de Fechas */                   
            const resRegObjetivos = await CalidadProduccionServices.getAllRegObjetivos(rangoFechas);
            setArrayFechas(resRegObjetivos.data.arrayFechas)
            setListObjetivos(resRegObjetivos.data.objetivos)
            setArrayFechasMoment(resRegObjetivos.data.arrayFechasToMoment);
            setBusquedaListObjetivos(resRegObjetivos.data.objetivos)
        } catch (error) {

        }
    }

    const newListObjetivos: any = [...listObjetivos]
    const changeRegistroObjetivo = (e: InputChange) => {
        const divTarget = e.target.id.split(' ');
        const positionRegistro: any = parseInt(divTarget[1]);
        const getFecha = arrayFechas[positionRegistro];
        const idObjetivo = divTarget[0];
        const findIndexListObj = newListObjetivos.findIndex((objetivo: listObjetivos) => objetivo._id === idObjetivo)
        if (parseFloat(e.target.value) >= 0) {
            const newValueRegistro = parseFloat(e.target.value)
            newListObjetivos[findIndexListObj].newRegistros[positionRegistro] = parseFloat(e.target.value)
            setListObjetivos(newListObjetivos)
        } else {
            toast.error("Asigne numero mayor a 0")
        }

    }
    const editObjetivoOnTable = async (idObjetivo: string) => {
        console.log(idObjetivo)
        const divTarget = idObjetivo.split(' ');
        const positionRegistro: any = parseInt(divTarget[1]);
        const idObj = divTarget[0];
        const getFecha = arrayFechasMoment[positionRegistro];
        const objetivo = listObjetivos.find((objetivo) => objetivo._id === idObj)
        const valToCreateOrUpdate = objetivo?.newRegistros[positionRegistro];
        if (idObj || getFecha.fecha) {
            const setNewValues = {
                idObjetivo: idObj,
                fecha: getFecha.fecha,
                value: valToCreateOrUpdate
            }
            
            try {
                const resCreateOrUpdateReg = CalidadProduccionServices.createOrUpdateRegObj(setNewValues);
                toast.success("Registrado")
            } catch (error:any) {
                toast.error(error.message);
            }
        } else {
            toast.error("Fecha y ID objetivo no configurados")
        }

    }
    const changeBusquedaInput = (e: InputChange) =>{
            setTextBusqueda(e.target.value);
            filterObjetivos(e.target.value)
    }
    const filterObjetivos = (terminoBusqueda: string) => {
        var resultadoBusqueda = busquedaListObjetivos.filter((objetivo) => {
            if (objetivo.nameObjetivo.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) || 
            objetivo.nameCategorio.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) ) {
                return objetivo
            }
        })
        setListObjetivos(resultadoBusqueda)
    }
    const setLoadDiv = (state: boolean) => {
        setSetValueLoad(state)
    }
    useEffect(() => {

    }, [])
    return (
        <div>
            <div className={`${setValueLoad ? 'addOpacitiGif' : ''} loadContainerGif`}>
                <img src={gifLoad} className={`${setValueLoad ? 'opacityOn' : 'opacityOff'}`} alt="" />
            </div>
            <div className="text-center col-12 col-sm-4 mx-auto p-3">
                <h3><strong>Registro de Objetivos</strong></h3>
            </div>
            <div className="d-flex flex-wrap justify-content-center align-items-end">
                <div className="p-2">
                    <label htmlFor="" className="text-dark">Fecha Inicio</label>
                    <input className="form-control form-control-lg border-dark" name="fechaInicio" type="date"
                        max={rangoFechas.fechaFin}
                        onChange={changeFechas}
                        value={rangoFechas.fechaInicio}
                    />
                </div>
                <div className="p-2">
                    <label htmlFor="" className="text-dark">Fecha fin</label>
                    <input className="form-control form-control-lg border-dark" name='fechaFin' type="date"
                        min={rangoFechas.fechaInicio}
                        onChange={changeFechas}
                        value={rangoFechas.fechaFin}
                    />
                </div>
                <div className="p-2">
                    <button className="btn btn-outline-dark btn-lg"
                        onClick={getAllRegObjetivos}
                    >
                        <i className="fas fa-search"></i>
                    </button>
                </div>
            </div>

            <div className="text-center pt-4 pb-2">
                <button className="btn btn-outline-success btn-lg"
                    onClick={showModal}
                >
                    <strong> Copiado masivo</strong>
                </button>
            </div>
            <div className="col-12 col-sm-6 mx-auto">
                <div className="p-2">
                    <input className="form-control form-control-lg " placeholder="Busqueda Categoria o Nombre" type="text" name="busquedaInput" id="busquedaInput"
                        onChange={changeBusquedaInput} 
                    />
                </div>
            </div>
            <div className="table-responsive mx-auto col-12 col-sm-10 col-md-10">
                <table style={{ background: '#DBDBDB' }} className="table   table-bordered table-ligth border  tableFixHead">
                    <thead>
                        <tr>
                            <th scope="col" style={{ maxWidth: '100px' }} className="">CATEGORIA</th>
                            <th style={{ maxWidth: '10px' }} >NOMBRE</th>
                            {/* <th scope="col" style={{ minWidth: '220px' }} className="">INPUT</th> */}
                            {arrayFechas.map((fechas) => {
                                return <th key={fechas.id} scope="col" style={{ minWidth: '100px', textAlign:'center' }}>{fechas.fecha}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {listObjetivos?.map((objetivos) => {
                            return (
                                <tr key={objetivos._id}>
                                    <td style={{maxWidth:'100px'}}>{objetivos.nameCategorio}</td>
                                    <td style={{fontSize: '19px', maxWidth:'200px'}}>{objetivos.nameObjetivo}</td>
                                    {objetivos.newRegistros.map((registro, index: any) => {
                                        if (registro >= 0) {
                                            return <td className="text-black font-weight-bold h5 text-center">
                                                <InputTd type="number" style={{ width: '100px' }} id={objetivos._id + ' ' + index} step="any" min="0" onChange={changeRegistroObjetivo} value={registro} />
                                                <BtnTd className="btn border" onClick={() => { editObjetivoOnTable(objetivos._id + ' ' + index) }} ><i className="far fa-save"></i></BtnTd>
                                            </td>
                                        } else {
                                            return <td >{registro}</td>
                                        }
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <ModalRegObjetivos show={stateModal} objetivos={listObjetivos} onHide={hideModal} afterHidenModal={afterHidenModal} setLoadDiv={setLoadDiv} />
        </div>
    )
}

export default RegObjetivos
