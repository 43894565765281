import React, { MouseEventHandler, useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import {palletSalida, formatos, envases, tapas, detallePallet} from './typesSalidaPallet'
import { toast } from 'react-toastify';
import * as almacenMPService from '../AlmacenMP/AlmacenMPServices'
import {ModalConfirmDeleteDet} from '../ModalBox/ModalConfirm/ModalConfirm'
import moment from 'moment';


interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>|any,
    idPallet: string,
}
function ModalSalidaPallet(props: Props) {
    const initialStatePallet = {
        accionesCorrectivas: '',
        codigo: '',
        estadopallet: '',
        planta: '', rx: '', userid : '',
        created: '',
        envase: '',
        formato:  {
            _id: '',
            codigo: '',
            descripcion: '',
            calidad: ''
          },
        numPallet: '',
        observaciones: '',
        tapa:  {
            _id: '',
            codigo: '',
            descripcion: ''
          },
        contador: '',
        turno: '',
        _id: ''
    }
    const initialStateFormDet = {
        _id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0, pallet: props.idPallet, subproceso:''
    }
    const [topPallet, setTopPallet] = useState<palletSalida>(initialStatePallet);
    const [detallePallet, setDetallePallet] = useState<detallePallet[]>([])
    const [textBusFormato, setTextBusFormato] = useState('');
    const [listFormatos, setListFormatos] = useState<formatos[]>([])
    const [listEnvases, setListEnvases] = useState<envases[]>([]);
    const [listTapas, setListTapas] = useState<tapas[]>([]);
    const [idPalletToDelete, setidPalletToDelete] = useState('');
    const [modalConfirmDet, setModalConfirmDet] = useState(false)
    const [formDetPallet, setFormDetPallet] = useState<detallePallet>(initialStateFormDet)
    
  
    
    const submitTopPallet = async(e:any) => {
        e.preventDefault()       
        try {
            if (topPallet._id) {
                await almacenMPService.editOnePalletSalida(topPallet);                               
                toast.success("Pallet Editado")
            }  
        } catch (error:any) {
            toast.error(error.message)
        }  
    }
    const handleChangeTop= (e:any)=>{
        setTopPallet({...topPallet, [e.target.name]: e.target.value}) 
    }
    const handleChangeBusqForm = (e:any) =>{
        setTextBusFormato(e.target.value)
    }
    const buscarFormato = async()=>{        
        const resBusquedaFormato = await almacenMPService.busquedaFormato(textBusFormato)
        setListFormatos(resBusquedaFormato.data);        
    }
    const handleSubmitDetallePallet = async(e:any)=>{
        e.preventDefault();
        try{
            if(formDetPallet._id){              
                await almacenMPService.updateDetPallet(formDetPallet);
                setFormDetPallet({...formDetPallet, _id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0})
                actualizarDetalles(topPallet._id)
                toast.success('Detalle Editado') 
            }else{            
              await almacenMPService.createDetPallet(formDetPallet);
              setFormDetPallet({...formDetPallet, _id:'', fechaProduccion: '', codigoJuliano: 0, batch: '', niveles: 0, saldo: 0, prodDia: 0, prodAcumulada: 0})
              actualizarDetalles(topPallet._id);
              toast.success('Detalle Creado') 
            } 
        } catch (error:any) {
            toast.error(error.message)
        }     
    }
    const eliminarDetalle = (id: string) =>{
        
        setidPalletToDelete(id);
        setModalConfirmDet(true)
    }
    const handleCloseModalConfirmDet = () => setModalConfirmDet(false);
    const afterModalConfirmDet = () =>{
        setModalConfirmDet(false);
        actualizarDetalles(topPallet._id);
    }
    const actualizarDetalles = async(id:string)=>{
        const resDetPallet= await almacenMPService.getDetPalletsById(id)     
        // console.log(resDetPallet.data)   
        setDetallePallet(resDetPallet.data)
        
    }

    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Cambia el estado del checkbox cuando se hace clic
        let campo = 'rx';
        //console.log ("modificando el check" + (!isChecked ? "Si" : "No"));
        setTopPallet({...topPallet, [campo]: (!isChecked ? "Si" : "No")});
        
    }

    const editarDetalle = (id: string)=>{
        const busquedaDetalle:any  = detallePallet.find(detPallet => detPallet._id === id);
        if(detallePallet[detallePallet.length-1]._id !== id) return toast.error('Solo se permite la edicion del ultimo detalle')
        setFormDetPallet(busquedaDetalle)
        setFormDetPallet({...busquedaDetalle, fechaProduccion: moment(busquedaDetalle.fechaProduccion).format('YYYY-MM-DD')})
    }
    const handleChangeDetPallet= (e:any)=>{
        setFormDetPallet({...formDetPallet, [e.target.name]: e.target.value})    
    }
    const getDataPallet = async()=>{
        const resDataPallet = await almacenMPService.getPalletAndDetalleByID(props.idPallet)
        // console.log(resDataPallet)
        setTopPallet(resDataPallet.data.pallet[0]);
        setDetallePallet(resDataPallet.data.detPallet);
    }
    const cancelarFormDet = () =>{
        setFormDetPallet(initialStateFormDet)
    }
    const getTapasEnvases = async()=>{
        const resTapas = await almacenMPService.getTapas();
        const resEnvases = await almacenMPService.getEnvases();
        setListTapas(resTapas.data);
        setListEnvases(resEnvases.data);
    }
    useEffect(() => {
        if (props.show) {
            getDataPallet();
            //getTapasEnvases();
        }else{
            setTopPallet(initialStatePallet);
            setFormDetPallet(initialStateFormDet)
            setDetallePallet([]);
        }
    }, [props.show]);
    useEffect(() => {
        var sumaProduccion = 0;
        if(!formDetPallet._id){
          for (let i = 0; i < detallePallet.length; i++) {
              const oneProdAcumulada = detallePallet[i].prodDia;
              sumaProduccion += oneProdAcumulada
          }
        }else{
          for (let i = 0; i < detallePallet.length-1; i++) {
              const oneProdAcumulada = detallePallet[i].prodDia;
              sumaProduccion += oneProdAcumulada
          } 
        }
        const prodTotal = sumaProduccion + formDetPallet.prodDia*1;
        setFormDetPallet({...formDetPallet, prodAcumulada: prodTotal})
      }, [formDetPallet.prodDia])
    return (
        <>
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Pallet {props.idPallet}
             </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <div id="topPallet">
                    
                        
                            <form onSubmit={submitTopPallet}>
                                <div className="row m-2">
                                    
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="numPallet">Codigo Pallet</label>
                                        <input type="text" className="form-control " name="numPallet" 
                                        onChange={handleChangeTop}
                                        value={topPallet?.numPallet} required readOnly />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="row align-items-end">
                                            <div className="col-8 col-md-3">
                                                
                                                <input type="text" className="form-control " name="bformato" 
                                                onChange={handleChangeBusqForm}
                                                value={textBusFormato}/>
                                            </div>                                              
                                            <div className="col-4 col-md-2">
                                            <span className=" btn btn-block btn-dark" onClick={buscarFormato}><i className="fas fa-search"></i></span>
                                            </div>
                                            <div className="col-12 col-md-7">
                                                <label htmlFor="formato">Codigo - Formato</label>
                                                <select className="form-control " name="formato" onChange={handleChangeTop}  required >
                                                    {topPallet?._id ? (<>
                                                        <option value={topPallet?.formato._id} selected>{topPallet?.formato.codigo} - {topPallet?.formato.descripcion}</option>                                                    
                                                        </>
                                                    ) : (<>
                                                        <option value="" selected>Seleccione</option>
                                                        </>
                                                    )}
                                                    {listFormatos.map((formato)=>{
                                                        return(
                                                            <option value={formato._id} >{formato.codigo} - {formato.descripcion}</option>
                                                        )
                                                    })}                                                                                                                        
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div className="col-12 col-md-3">
                                        <label htmlFor="contador">Contador</label>
                                        <input type="text" className="form-control " name="contador" 
                                        onChange={handleChangeTop}
                                        value={topPallet?.contador}  required/>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="turno">Turno</label>
                                        <select className="form-control " name="turno" onChange={handleChangeTop}  required >
                                            {topPallet?._id ? (<>
                                                <option value={topPallet?.turno} selected>{topPallet?.turno}</option>                                                    
                                                </>
                                            ) : (<>
                                                <option value="" selected>Seleccione</option>
                                                </>
                                            )}
                                                <option value="DIA" >DIA</option>
                                                <option value="TARDE">TARDE</option>
                                                <option value="NOCHE">NOCHE</option>
                                        </select>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <label htmlFor="estadopallet">Estado del Pallet</label>
                                            <select className="form-control " name="estadopallet" onChange={handleChangeTop}  required >
                                                {topPallet?._id ? (<>
                                                    <option value={topPallet?.estadopallet} selected>{topPallet?.estadopallet}</option>                                                    
                                                    </>
                                                ) : (<>
                                                    <option value="" selected>Seleccione</option>
                                                    </>
                                                )}
                                                 <option value="OBSERVADO" >OBSERVADO</option>
                                                    <option value="REPROCESO">REPROCESO</option>
                                                    <option value="3RA CALIDAD">3RA CALIDAD</option>
                                                    <option value="EXPORTABLE">EXPORTABLE</option>   
                                                    <option value="INSUMOS">INSUMOS</option> 
                                            </select>
                                        
                                    </div>
                                    <div className="col-12 col-md-2">
                                        <label htmlFor="planta">Planta</label>
                                            <select className="form-control " name="planta" onChange={handleChangeTop}  required >
                                                {topPallet?._id ? (<>
                                                    <option value={topPallet?.planta  } selected>{topPallet?.planta}</option>                                                    
                                                    </>
                                                ) : (<>
                                                    <option value="" selected>Seleccione</option>
                                                    <option value="AREQUIPA" >AREQUIPA</option>
                                                    <option value="CUSCO" >CUSCO</option>
                                                    </>
                                                )}
                                                   
                                            </select>
                                        
                                    </div>
                                    <div className="col-12 col-md-1">
                                            <label htmlFor="rx">Rx</label>
                                            <input type='checkbox' name='rx' className="form-control"  checked={(topPallet?.rx==="Si"?true:false)} onChange={handleCheckboxChange} ></input>
                                        </div>
                                    <div className="col-12 col-md-6">
                                        <label htmlFor="observaciones">Observaciones</label>
                                        <textarea name="observaciones" className="form-control" cols={30} rows={2} 
                                        onChange={handleChangeTop}
                                        value={topPallet?.observaciones}></textarea>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label htmlFor="accionesCorrectivas">Acciones Correctivas</label>
                                        <textarea name="accionesCorrectivas" className="form-control" cols={30} rows={2} 
                                        onChange={handleChangeTop}
                                        value={topPallet?.accionesCorrectivas}></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                <div className={`col-12 col-md-6 m-1 mx-auto`}>
                                    <button className="btn btn-block btn-dark btn-lg" ><i className="fa fa-save"></i>  Editar</button>
                                </div>
                                </div>
                            </form>
                       
                </div>
                <div id="detalle" >
                    
                    <div className="table-responsive  col-12 mx-auto  mb-5 bg-body rounded trheight tableFixHeadPallet">
                        <table className="table table-bordered shadow" style={{background: '#d6d6d6'}}>
                            <thead>
                                <tr>
                                    <th scope="col">Fec. Produccion</th>
                                    <th scope="col">Cod. Juliano</th>
                                    <th scope="col">Batch</th>
                                    <th scope="col">Niveles</th>
                                    <th scope="col">Saldo</th>
                                    <th scope="col">Prod. Del Dia</th>
                                    <th scope="col">Prod. Acumulada</th>
                                    <th scope="col">Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {detallePallet.map((det)=>{
                                    return <tr>
                                    <td>{moment(det.fechaProduccion).format('DD-MM-YYYY')}</td>
                                    <td>{det.codigoJuliano}</td>
                                    <td>{det.batch}</td>
                                    <td>{det.niveles}</td>
                                    <td>{det.saldo}</td>
                                    <td>{det.prodDia}</td>
                                    <td>{det.prodAcumulada}</td>
                                    <td>
                                        <span className="btn btn-outline-dark m-2" onClick={()=>{editarDetalle(det._id)}}><i className="fas fa-pen"></i></span>
                                        <span className="btn btn-outline-danger m-2" onClick={()=>{eliminarDetalle(det._id)}}><i className="fas fa-trash"></i></span>
                                    </td>
                                </tr>
                                })}
                                
                            </tbody>
                        </table>
                    </div>
                        <form onSubmit={handleSubmitDetallePallet}>
                            <div className="row m-2">
                                <div className="col-12 col-md-3">
                                    <label htmlFor="fechaProduccion">Fecha</label>
                                    <input type="date" className="form-control " name="fechaProduccion" required
                                    value={formDetPallet?.fechaProduccion} onChange={handleChangeDetPallet}/>
                                </div>
                                <div className="col-12 col-md-3">
                                    <label htmlFor="codigoJuliano">Cod. Juliano</label>
                                    <input type="number" className="form-control " name="codigoJuliano" required
                                    value={formDetPallet?.codigoJuliano} onChange={handleChangeDetPallet}/>
                                </div>
                                <div className="col-12 col-md-3">
                                    <label htmlFor="batch">Batch</label>
                                    <input type="text" className="form-control " name="batch" required
                                    value={formDetPallet?.batch} onChange={handleChangeDetPallet}/>
                                </div>
                                <div className="col-12 col-md-3">
                                    <label htmlFor="niveles">Niveles</label>
                                    <input type="number" className="form-control " name="niveles" required
                                    value={formDetPallet?.niveles} onChange={handleChangeDetPallet}/>
                                </div>
                                <div className="col-12 col-md-3">
                                    <label htmlFor="saldo">Saldo</label>
                                    <input type="number" className="form-control " name="saldo" required
                                    value={formDetPallet?.saldo} onChange={handleChangeDetPallet}/>
                                </div>
                                <div className="col-12 col-md-3">
                                    <label htmlFor="prodDia">Produccion Dia</label>
                                    <input type="number" className="form-control " name="prodDia" required
                                    value={formDetPallet?.prodDia} onChange={handleChangeDetPallet}/>
                                </div>
                                <div className="col-12 col-md-3">
                                    <label htmlFor="prodAcumulada">Prod. Acumulada</label>
                                    <input type="number" className="form-control " name="prodAcumulada" readOnly required
                                    value={formDetPallet?.prodAcumulada} />
                                </div>
                            </div>
                            <div className="text-center">                                
                                <button className="btn btn-info m-3">{formDetPallet._id ? ('EDITAR') : ('CREAR')} Detalle</button>
                                <span className='btn btn-outline-dark m-3' onClick={cancelarFormDet}>Cancelar</span>
                            </div>
                        </form>
                    
                </div>


            </Modal.Body>
            <Modal.Footer>                    
                <Button variant="success btn-block" onClick={props.onHide} >Salir</Button>

            </Modal.Footer>
        </Modal>
        <ModalConfirmDeleteDet show={modalConfirmDet} onHide={handleCloseModalConfirmDet} id={idPalletToDelete} afterModalConfirmDet={afterModalConfirmDet} />

        {/* <ModalConfirm show={modalConfirm} onHide={handleCloseModalConfirm} pallet={topPallet} afterModalConfirm={afterModalConfirm} esSalida={esSalida}/>
        <ModalConfirmDeleteDet show={modalConfirmDet} onHide={handleCloseModalConfirmDet} id={idPalletToDelete} afterModalConfirmDet={afterModalConfirmDet} />
        <ModalMover show={estadoModalMover} pallet={topPallet} onHide={handleCloseModalMover} afterModalConfirm={afterOkModalMover}/>
        <ModalDividir show={modalDividir} onHide={handleCloseModalDividir} afterModalConfirm={handleCloseModalDividir} detPallets={detallePallet} idPallet={topPallet._id} codPallet={topPallet.numPallet}/>  */}
        
    </>
    )
}

export default ModalSalidaPallet
