import React, { useState, useEffect, ChangeEvent, FormEvent} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { proveedor } from './proveedor';
import * as proveedorService from './ProveedorService'
import {toast} from 'react-toastify'
type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;

interface Params {
    id: string
}



function ProveedorForm() {
    const initialState = {
        nombre: '',
        tipoDocumento: '',
        numeroDocumento: '',
        direccion: '',
        detalle: ''
        
    }
    const history = useNavigate();
    const params = useParams<any>();

    const [proveedor, setproveedor] = useState<proveedor>(initialState);

    const handleInputChange = (e:InputChange) =>{
        setproveedor({...proveedor, [e.target.name]: e.target.value})
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (!params.id) {                
                await proveedorService.crearProveedor(proveedor)
                history('/proveedor');
                toast.success('Guardado');
            } else {
               const dataOneProveedor = await proveedorService.editarProveedor(params.id, proveedor)
               history('/proveedor');
               toast.success('Editado');
            }
            
        } catch (error:any) {
            console.log(error.message)
            if (error.message === 'Request failed with status code 301') {
                toast.error('Nombre o RS ya registrado');
            }
            if (error.message === 'Request failed with status code 302') {
                toast.error('Numero de Documento ya registrado');
            }
        }
    }
    const handleCancel = (e:any) => {
        e.preventDefault();
        setproveedor(initialState);
        history('/proveedor');
    }
    const loadData = () =>{

    }
    const loadDataEdit = async (id: string) => {
        const resProveedor = await proveedorService.findOneProveedor(id);
        console.log(resProveedor.data, "data lote edit")
        setproveedor(resProveedor.data);
    }
    useEffect(() => {
        loadData();
        if (params.id) {
            loadDataEdit(params.id);
        }
    }, [])
    return (
        
            <div >
                <div className="col-12">
                    <div className="card">
                        <div className="card-body  ">
                            <div className="form-group col-12 col-md-10 offset-md-1 ">
                            {params.id ? (<h3>EDITAR PROVEEDOR</h3>) : (<h3>CREAR PROVEEDOR</h3>)}
                            </div>
                            <form onSubmit={handleSubmit}>
                                
                                <div className="row justify-content-md-center">
                                    
                                    
                                    <div className="form-group col-12 col-md-5">
                                        <label htmlFor="fechaSiembra" className="form-label">Nombre o Razon Social </label>
                                        <input
                                            required
                                            type="text"
                                            id="nombre"
                                            name="nombre"
                                            placeholder=""
                                            className="form-control"
                                            value={proveedor.nombre}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-md-2" >
                                        <label htmlFor="tipoDocumento" className="form-label">Tipo Documento</label>                                       
                                        <select onChange={handleInputChange} name="tipoDocumento" className="form-control form-select form-select-sm" aria-label=".form-select-sm example" required>
                                            {params.id?(<option value={proveedor.tipoDocumento}>{proveedor.tipoDocumento}</option> ):(<option value=""></option> )}                                           
                                            <option value="DNI">DNI</option>
                                            <option value="RUC">RUC</option>

                                            
                                        </select>
                                    </div>
                                    <div className="form-group col-12 col-md-3">
                                        <label htmlFor="inicioCosechaTen" className="form-label">Numero documento</label>
                                        <input
                                            required
                                            type="number"
                                            id="numeroDocumento"
                                            name="numeroDocumento"                                            
                                            className="form-control"
                                            value={proveedor.numeroDocumento}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group col-12 col-md-9">
                                        <label htmlFor="finCosechaTen" className="form-label">Direccion</label>
                                        <input
                                            
                                            type="text"
                                            id="direccion"
                                            name="direccion"
                                            className="form-control"
                                            value={proveedor.direccion}
                                            onChange={handleInputChange}
                                        />
                                    </div>                            

                                </div>
                                <div className="form-group row col-12 justify-content-md-center text-center ">
                                    <div className="col-md-3 p-2">
                                        {params.id ? (<button className=" btn btn-dark col-12  ">Editar Proveedor</button>)
                                            : (<button className=" btn btn-dark col-12  ">Crear Proveedor</button>)}
                                    </div>
                                    <div className="col-md-3 p-2">
                                        <button onClick={handleCancel} className="btn btn-outline-secondary col-12">Cancelar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        
    )
}

export default ProveedorForm
