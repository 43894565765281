import axios from 'axios'
import {cogida,proxCosecha, busquedaCosecha, cogidaList} from './cogida'
import {cogidanum} from './numccogida'
import apiURL from '../../context/apiURL'
const API = apiURL.API;

export const getCogidas = async () => {
   return await axios.get<cogida[]>(`${API}/cogidas`)    
}

export const createCogidas = async (localidad: cogida) => {
   return await axios.post(`${API}/cogidas`, localidad)    
}

export const getCogida = async (id: string) => {  
   return await axios.get<cogida>(`${API}/cogida/${id}`)    
}

export const updateCogidas = async (id: string, localidad: cogida) => {
   return await axios.put<cogida>(`${API}/cogidas/${id}`, localidad)    
}

export const deleteCogida = async (id: string) => {
   return await axios.delete<cogida>(`${API}/cogidas/${id}`)    
}
// FUNCIONES ADICIONALES
export const getCogidasLocalidadDep = async () => {
   
   return await axios.get<cogidaList[]>(`${API}/cogidasld`)    
}

export const getCogidaLocalidadDep = async (id:string) => {   
   return await axios.get<cogida>(`${API}/cogidald/${id}`)    
}
export const getDetCogidaLocalidadDep = async (id:string) => {   
   return await axios.get<cogida[]>(`${API}/cogidadet/${id}`)    
}

type numCog = number;
export const getNumCogidas = async (id: string) => {
   return await axios.get<numCog>(`${API}/numcogidas/${id}`)
}

export const getProxCosecha = async (id: string, numero:number) => {
   return await axios.get<proxCosecha>(`${API}/proxCosecha/${id}-${numero}`)
}

export const editFechaProyeccion = async (data:proxCosecha) =>{
   return await axios.put<proxCosecha>(`${API}/editFechaProyeccion`, data);
}

//PRINTPDF
export const CretePdfCosecha = async(id: string)=>{
   return await axios.get(`${API}/createpdfcosecha/${id}`)
}

export const SendPdfCosecha = async()=>{
   return await axios.get(`${API}/sendpdfcosecha`, {responseType: 'blob'})
}

export const PdfCosechasVsLote = async(id:string)=>{
   return await axios.get(`${API}/PdfCosechasVsLote/${id}`)
}
export const SendPdfCosechasVsLote = async ()=>{
   return await axios.get(`${API}/SendPdfCosechasVsLote`, {responseType: 'blob'})
}
////BUSQUEDA LIST
export const busquedaCosechaList = async(data:busquedaCosecha)=>{
   return await axios.post(`${API}/busquedaCosechaList`, data);
}