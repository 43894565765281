import React, { useState, useEffect } from 'react'

import * as almacenMPServices from '../AlmacenMP/AlmacenMPServices'
import { reporteSalidaPallet, formatos, Pcliente } from '../AlmacenMP/almacen'
// import { MainContainer } from "../../../stylesGeneral/mainElements";
import { MainContainer, ContainerInputs, SelectGeneral, InputGeneral, ButtonGeneral } from '../../../stylesGeneral/mainElements'
import { toast } from 'react-toastify';
import IcoExcel from '../../../Media/svg/excel.svg'
import momentz from 'moment-timezone';
import ModalSalidaPallet from './ModalSalidaPallet';
import saveAs from 'file-saver'
function SalidaPallet() {    
    // const [listAlmacenes, setListAlmacenes] = useState<almacenes[]>([]);
    const [reporte, setReporte] = useState<reporteSalidaPallet[]>([])
    const [listOptFormato, setListOptFormato] = useState<formatos[]>([])
    const [listOptCliente, setListOptCliente] = useState<Pcliente[]>([])
    const [textBusFormato, setTextBusFormato] = useState('')
    const [textBusCliente, setTextBusCliente] = useState('')
    const [dataGetReporte, setDataGetReporte] = useState({fechaInicio:'', fechaFin:'', idFormato: '', cliente: '', ordenCompra:''})
    const [loadDiv, setLoadDiv] = useState(false)
    const [modalSalidaState, setModalSalidaState] = useState(false)// Estado Modal Salida
    const [idPalletToModal, setIdPalletToModal] = useState('')

    const handleSetDataToSearch = (e: any) => {
        setDataGetReporte({ ...dataGetReporte, [e.target.name]: e.target.value })
    }
    const handleChangetextBusFormato = (e: any) => {
        setTextBusFormato(e.target.value)
    }
    const handleChangetextBusCliente = (e: any) => {
        setTextBusCliente(e.target.value)
    }
  
    const getReportePallets = async () => {
        try {
            setLoadDiv(true);
            const resReporte = await almacenMPServices.getRSP(dataGetReporte);
            if (resReporte.data.ErrorMessage) {
                setLoadDiv(false);
                return toast.error(resReporte.data.ErrorMessage)
            }            
            if (!resReporte || resReporte.data.length === 0) {
                setLoadDiv(false);
                return toast.error('Sin registros')
            }
            setReporte(resReporte.data)
            setLoadDiv(false);
        } catch (error:any) {
            setLoadDiv(false)
            toast.error(error)
            console.log(error)
        }
    }
    const busquedaFormato = async () => {
        try {
            const resFormatos = await almacenMPServices.busquedaFormato(textBusFormato);

            if (!resFormatos.data[0].codigo) return toast.error("Error de Busqueda")
            setListOptFormato(resFormatos.data)
            // console.log(resFormatos)
        } catch (error) {
            toast.error("Error de Busqueda")
        }
    }
    const busquedaCliente = async () => {
        const resFormatos = await almacenMPServices.busquedaPcliente(textBusCliente);
        setListOptCliente(resFormatos.data)
        // console.log(resFormatos)
    }
    const pdfBlob = async(res:any)=>{
        const pdfBlob= await new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"})
        saveAs(pdfBlob, 'ReporteSalidaPallets.xlsx')
       }
    const createExcel = async() =>{
        await almacenMPServices.createExcelSalidaPallets(reporte)
        const resExcel = await almacenMPServices.getReportePallet('ReporteSalidaPallets.xlsx');
        pdfBlob(resExcel)       
    }
    const hideModalSalida = () =>{
        setModalSalidaState(false)
    }
    const showModalSalida =(id: string) =>{
        setIdPalletToModal(id);
        setModalSalidaState(true);
    }
    useEffect(() => {
        // loadAlmacenes();
    }, [])
    return (
        <div className={`${loadDiv ? 'loadDiv' : ''} `}>
            <div className='text-center'>
                <h1>Salida Pallets</h1>
            </div>
            <MainContainer >
            <ContainerInputs width="250px"  >
                    <label htmlFor="">Fecha Inicio</label>
                    <InputGeneral width="100%" height="40px" style={{background:"rgba(174, 182, 191)"}}
                        type="date"
                        name="fechaInicio"
                        onChange={handleSetDataToSearch}
                    />
                </ContainerInputs >
                <ContainerInputs width="250px"  >
                    <label htmlFor="">Fecha Fin</label>
                    <InputGeneral width="100%" height="40px"style={{background:"rgba(174, 182, 191)"}}
                        type="date"
                        name="fechaFin"
                        onChange={handleSetDataToSearch}
                    />
                </ContainerInputs >
                <ContainerInputs width="300px"  >
                    <label htmlFor="">Busqueda Formato</label>
                    <InputGeneral width="100%" height="40px" type="text"
                        onChange={handleChangetextBusFormato}
                    />
                </ContainerInputs >
                <ContainerInputs width="200px" >
                    <ButtonGeneral width="100%" height="40px" color=""
                        onClick={busquedaFormato}
                    >Buscar Formato</ButtonGeneral>
                </ContainerInputs >
                <ContainerInputs width="400px">
                    <label htmlFor="idFormato">Formato</label>
                    <SelectGeneral height="40px" bg="" name='idFormato'
                        onChange={handleSetDataToSearch}
                    >
                        <option value="">Todos</option>
                        {listOptFormato.map((formatos) => {
                            return (
                                <option key={formatos._id} value={formatos._id}>{formatos.descripcion} - {formatos.codigo}</option>
                            )
                        })}
                    </SelectGeneral>
                </ContainerInputs>
                <ContainerInputs width="300px">
                    <label htmlFor="">Busqueda Cliente</label>
                    <InputGeneral width="100%" height="40px"  type="text"
                        onChange={handleChangetextBusCliente} />
                </ContainerInputs>
                <ContainerInputs width="300px">
                    <ButtonGeneral width="100%" height="40px" color=""  onClick={busquedaCliente}>Busqueda Cliente</ButtonGeneral >
                </ContainerInputs>
                <ContainerInputs width="300px">
                    <label htmlFor="cliente">Cliente</label>
                    <SelectGeneral  height="40px" bg="" name='cliente'
                        onChange={handleSetDataToSearch}>
                        <option value="">Todos</option>
                        {listOptCliente.map((clientes) => {
                            return (
                                <option value={clientes._id}>{clientes.nombre}</option>
                            )
                        })}
                    </SelectGeneral>
                </ContainerInputs>
                <ContainerInputs width="300px">
                    <label htmlFor="">Orden Compra</label>
                        <InputGeneral width="100%" height="40px"  type="text" name="ordenCompra"
                            onChange={handleSetDataToSearch}
                        />
                </ContainerInputs>

                <ContainerInputs width="200px">
                    <ButtonGeneral width="100%" height="40px" color="rgba(25, 111, 61 )"  
                        onClick={getReportePallets}
                    ><i className="fas fa-search"></i></ButtonGeneral>
                </ContainerInputs>

                <ContainerInputs width="100px" >
                    <ButtonGeneral width="100%" height="40px" color="rgba(0, 101, 18 )"  
                        onClick={getReportePallets}
                    >
                        <img src={IcoExcel} alt=""  onClick={createExcel} className="iconExcel"/>
                    </ButtonGeneral>                     
                </ContainerInputs>
            </MainContainer>
                        
            <div className="table-responsive-lg  col-12 mx-auto  mb-5 bg-body rounded trheight tableFixHead">

                <table style={{background: '#DBDBDB', cursor: 'pointer'}} className="table table-bordered table-hover shadow">
                    <thead>
                        <tr >
                            <th scope="col">ESTADO</th>
                            <th scope="col">CODIGO PALLET</th>
                            <th scope="col">FORMATO</th>
                            <th scope="col">CLIENTE</th>
                            <th scope="col">PRODUCCION</th>
                            <th scope="col">NRO ORDEN</th>
                            <th scope="col">FECHA ORDEN</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reporte.map((reporte) => {

                            return (
                            <tr key={reporte.pallet._id} onClick={()=>showModalSalida(reporte.pallet._id)}>
                                <td>{reporte.pallet.estado}</td>
                                <td>{reporte.pallet.numPallet}</td>                            
                                <td>{reporte.formato.descripcion} <br /> </td>
                                <td>{reporte.cliente?.nombre}</td>
                                <td className="h5">{reporte.totalprod}</td>
                                <td>{reporte.numeroOrden}</td>
                                <td>{momentz.tz(reporte.fecha, "America/Lima").format("YYYY-MM-DD") }</td>
                            </tr>
                            )
                        })
                        }
                    </tbody>
                </table>
            </div>
            <ModalSalidaPallet show={modalSalidaState} onHide={hideModalSalida} idPallet={idPalletToModal}  />
        </div>
    )
}

export default SalidaPallet
