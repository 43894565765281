import React, { useState, MouseEventHandler } from 'react'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { LotesToProyeccion } from '../proyecciones'
import * as ProyeccionesController from '../../Proyecciones/ProyeccionesService'
import gifLoad from '../../../Media/gifs/load.gif'
interface Props {
    show: boolean,
    idTipoProyeccion: string,
    dataOneLoteToShow: LotesToProyeccion,
    onHide: MouseEventHandler<HTMLElement>|any,
    afterHideModal: () => void
}
function ModalConfirmTipoCampos(props: Props) {
    const [setValueLoad, setSetValueLoad] = useState(false)
    const [stateButtonSubmit, setStateButtonSubmit] = useState(false)
    const crearProyecciones = async(e:any)=>{
        e.preventDefault();
        setStateButtonSubmit(true)
        setSetValueLoad(true)
        if(!props.idTipoProyeccion || !props.dataOneLoteToShow) return toast.error("No hay datos para la creacion")
        await ProyeccionesController.creacionProyeccionV2(props.idTipoProyeccion, props.dataOneLoteToShow)
        props.afterHideModal();
        setSetValueLoad(false)
        setStateButtonSubmit(false)
    }
   
    return (
        <>
        <div className={`${setValueLoad ? 'addOpacitiGif' : ''} loadContainerGif`}>
                <img src={gifLoad} className={`${setValueLoad ? 'opacityOn' : 'opacityOff'}`} alt="" />
            </div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-center">
                        Crear proyecciones
                    </Modal.Title>
                </Modal.Header>
               <form action="" onSubmit={crearProyecciones}>
                <div className="p-2">
                        <button className=" mt-3 btn btn-lg btn-success btn-block"
                            disabled={stateButtonSubmit}   
                        >
                            CREAR
                        </button>
                        <button className="mt-4 btn btn-lg btn-outline-dark btn-block"
                            onClick={props.onHide}                        
                        >
                            Cancelar
                        </button>
                    </div>
               </form>
            </Modal>
        </>
    )
}

export default ModalConfirmTipoCampos
