import React, { useEffect, useState, useRef, useContext } from 'react'
import './styles.css'


import { mapa } from './mapa'
import { busqueda, fechaProyeccion, proyeccion, resumenCosechas } from './busqueda'
// import Marcadores from './Marcadores/Marcadores';
import * as loteService from '../Lotes/LoteService'
import * as depService from '../Departamentos/departamentoService'
import { departamento } from '../Departamentos/departamento'
import { lote } from '../Lotes/lote'
import { indicadores, loteDep } from '../Lotes/loteDep'
// import { useHistory } from 'react-router-dom';
// import authContext from '../../context/AuthContext'
import * as dashboardServices from './DashboardService';
import { reporteProduccion} from '../Planta/AlmacenMP/almacen'
import momentz from 'moment-timezone'
//COMPONENTES

// import { stringify } from 'node:querystring'
import { toast } from 'react-toastify'
// const noTruncarDecimales = {maximumFractionDigits: 20};
//.toLocaleString(undefined, noTruncarDecimales)

import axios from 'axios'
function DashboardMain() {
    // const { loggedIn } = useContext(authContext);

    const [alllotes, setAllLote] = useState<loteDep[]>([]);
    const [marcadores, setMarcadores] = useState<loteDep[]>([]);
  
    const [busqueda, setBusqueda] = useState<busqueda>({
        departamento: '',
        localidad: '',
        lote: '',
        pen:''
    })
    const [proyeccion, setProyeccion] = useState<proyeccion>({
        peso: 0,
        load: false
    });
    const [fechasReporte, setfechasReporte] = useState<fechaProyeccion>({
        fechaInicial: momentz.tz(new Date, "America/Lima").format("YYYY-MM-DD"),
        fechaFinal: momentz.tz(new Date, "America/Lima").format("YYYY-MM-DD")
    })
  
    let tmp1 = new Date();
    let tmp2 = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    let tmp3 = momentz.tz(tmp2, "America/Lima").format('YYYY-MM-DD');
    let tmp4 = momentz.tz(tmp1, "America/Lima").format('YYYY-MM-DD');
    
    const [fechaDesde, setFechaDesde] = useState(tmp3.toString());
    const [fechaHasta, setFechaHasta] = useState(tmp4.toString());
    const [stateBtnSubmit, setStateBtnSubmit] = useState(false)
    //console.log(fechaDesde);
    const [reporteProduccion, setReporteProduccion] = useState<reporteProduccion[]>([]);
    const [dataGetReporte, setDataGetReporte] = useState({ localidad: 'AREQUIPA', fechaInicial:fechaDesde, fechaFinal: fechaHasta})
    const busquedaProduccion = async () => {
        try {
            /*const h = document.getElementById('fechaFinal')
            const d = document.getElementById('fechaInicial')
            let hhh = '';
            let ddd = '';
            if(h){
                if (h instanceof HTMLInputElement && h.valueAsDate) {
                    hhh = momentz.tz(h.valueAsDate, "America/Lima").format('YYYY-MM-DD').toString();
                }
            }
            if(d){
                if (d instanceof HTMLInputElement && d.valueAsDate) {
                    ddd = momentz.tz(d.valueAsDate, "America/Lima").format('YYYY-MM-DD').toString();
                }
            }
            console.log("***********************************")
            console.log("Desde:" + ddd)
            console.log("Hasta:"+hhh)
            */
            //setDataGetReporte({ ...dataGetReporte, localidad: 'AREQUIPA' , fechaInicial: ddd, fechaFinal: hhh })
            setStateBtnSubmit(true)
            //console.log(dataGetReporte);
            const resReporte = await dashboardServices.getProduccion(dataGetReporte);
            if (resReporte.data.length !<0){
                //setStateBtnSubmit(false)
                return toast.error("Consulta sin registros")
            } 
            setReporteProduccion(resReporte.data)
            setStateBtnSubmit(false)
        } catch (error) {
            setProyeccion({ load: false, peso: 0 })
            
        }

    }

    //const [dataGetReporte, setDataGetReporte] = useState({ planta: '', fecha:'', proceso:''})

    const handleSetDataToSearch = async (e: any) => {
        setDataGetReporte({ ...dataGetReporte, [e.target.name]: e.target.value })
    }

    const changeFechaDesde = (e: any) => {
        setFechaDesde( e.target.value);
        console.log(fechaDesde);
    }
   
    const changeFechaHasta = (e: any) => {
        setFechaHasta( e.target.value);
        console.log(fechaHasta);
    }
   
  
    //modale funcions
    const [loadDiv, setLoadDiv] = useState(false)
    const [show, setShow] = useState({ estado: false, idLote: '' });
    const handleClose = () => setShow({ idLote: '', estado: false });
    const handleShow = (e: any) => {
        setShow({ idLote: e.target.parentNode.id, estado: true });
    }

    ///CHECKBOX CONTROL
    const [boxState, setBoxState] = useState(false)


    useEffect(() => {
        busquedaProduccion();
    }, [])

    return (
        <div  style={{ width: '100%' }}>
 
            <div className=" row p-2  justify-content-right">
                <div className='col-3 '>
                    <label htmlFor="idAlmacen">PLANTA</label>
                    <select className='form-control ' id='localidad' name='localidad' onChange={handleSetDataToSearch}>
                        <option value="">Seleccione</option>
                        <option value="AREQUIPA" selected>AREQUIPA</option>
                        <option value="CUSCO">CUSCO</option>
                        
                    </select>
                </div>
                <div className="text-center col-8 col-md-4">
                    <label htmlFor="fechaInicial">Desde</label><br />
                    <input type="date" id="fechaInicial" name='fechaInicial' onChange={handleSetDataToSearch} className="form-control" />
                </div>
                <div className="text-center col-8 col-md-4">
                    <label htmlFor="fechaFinal">Hasta</label><br />
                    <input type="date" id="fechaFinal" name='fechaFinal' value="{dataGetReporte.fechaFinal}" onChange={handleSetDataToSearch} className="form-control" />
                </div>
                <div className=" text-center col-8 col-md-2">
                    <label htmlFor="fechaFinal"> &nbsp; </label><br />
                    <button onClick={busquedaProduccion}  disabled={stateBtnSubmit}  className="btn btn-primary form-control">
                            {stateBtnSubmit 
                            ?   <div className="spinner-border text-ligth" role="status">
                                    <span className="sr-only">Cargando...</span>
                                </div>
                            : 'Filtrar' } </button>
                </div>
            </div>
           

            <div  style={{ width: '100%' }}>
                <table className="table shadow"  style={{width:'700px' }}>
                    <thead>
                        <tr>
                            <th scope="col" className="">FECHA</th>
                            <th scope="col">CODIGO</th>
                            <th scope="col">PRODUCTO</th>
                            <th scope="col">CANTIDAD</th>
                        </tr>
                    </thead>
                    <tbody>

                        {reporteProduccion.map((row) => {                            
                            return <tr>
                                <td style={{fontSize:'10px' }}>{momentz.tz(row._id.fechaProduccion, "America/Lima").format('YYYY-MM-DD')}</td>
                                <td style={{fontSize:'10px' }}>{row._id.codigo}</td>
                                <td style={{fontSize:'10px' }}>{row._id.descripcion}</td>
                                <td style={{fontSize:'10px', textAlign: 'right' }}>{row.proddia}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>

        </div>
    )
}

export default DashboardMain
