import React, { useState, useEffect, ChangeEvent } from 'react'
import * as almacenServices from '../Planta/AlmacenMP/AlmacenMPServices'
import { almacenes } from './TypesAlmacenes'
import {
    CardAlmacen, Title, Button, CardMovimientos,
    TitleContainer
} from './AlmacenesIndexElements'

// type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
import Ingresos from './ModalIngresos/Ingresos'
import ListIngresos from './ModalIngresos/ListIngresos'
import ListSalidas from './ModalSalidas/ListSalidas'
function AlmacenesIndex() {
    const [almacen, setAlmacen] = useState<almacenes>()
    const [idAlmacen, setIdAlmacen] = useState('');
    const [modalStates, setModalStates] = useState({ ListIngresos: false, ListSalidas: false, movimientos: false })
    const [almacenes, setAlmacenes] = useState<almacenes[]>([])
    const handleCloseModals = () => {
        setModalStates({ ...modalStates, ListIngresos: false })
    }
    const closeModalSalidas= () =>{
        setModalStates({...modalStates, ListSalidas: false})
    }

    const openModals = (e:any) => {

        const almacenToModal = almacenes.find(almacen =>almacen._id===e.target.id)
        setIdAlmacen(e.target.value);
        setModalStates({ ...modalStates, [e.target.name]: true })
        setAlmacen(almacenToModal)
    }

    const loadAlmacenes = async () => {
        const resAlmacenes = await almacenServices.getAllAlmacenes();
        setAlmacenes(resAlmacenes.data);
    }
    useEffect(() => {
        loadAlmacenes();
    }, [])

    return (
        <>

            <div className="text-center">
                <h3>ALMACENES</h3>
                <button className="btn btn-outline-dark " ><i className="fas fa-plus btnadd"></i> </button>
            </div>
            <div>
                <div className="row justify-content-center text-center m-3">                    
                    {
                        almacenes.map((almacen) => {
                            return <CardAlmacen>
                                <TitleContainer>
                                    <Title >{almacen.nombre}</Title>
                                </TitleContainer>

                                <CardMovimientos>
                                    <Button name='movimientos' id={almacen._id} onClick={ openModals}>Ver Movimientos</Button>
                                    <Button name='ListIngresos' id={almacen._id} onClick={openModals}>Ingresos</Button>
                                    <Button name='ListSalidas' id={almacen._id} onClick={ openModals}>Salidas</Button>
                                </CardMovimientos>
                            </CardAlmacen>
                        })
                    }
                   

                </div>
            </div>
            {/* <Ingresos idAlmacen={almacen} show={modalStates.ListIngresos} onHide={handleCloseModals}></Ingresos> */}
            <ListIngresos  idAlmacen={almacen} show={modalStates.ListIngresos} onHide={handleCloseModals}></ListIngresos>
            <ListSalidas  idAlmacen={almacen} show={modalStates.ListSalidas} onHide={closeModalSalidas}></ListSalidas>
        </>
    )
}

export default AlmacenesIndex
