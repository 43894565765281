import React, { ChangeEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import * as CalidadProduccionServices from '../CalidadProduccionServices'
import * as UsuarioServices from '../../Usuarios/UsuarioService'
import { permisos, usuario, subCategoriaTable, categoriaTable } from '../typesCalidadProduccion'
type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
function Permisos() {
    const initialPermiso = { idUsuario: '', modulo: 'CalidadProduccion', categoria: '', subCategoria: '', insert: false, update: false, delete: false }
    const [permiso, setPermiso] = useState<permisos>(initialPermiso)
    const [usuarios, setUsuarios] = useState<usuario[]>([])
    const [listCategorias, setListCategorias] = useState<categoriaTable[]>([])
    const [listSubCategorias, setListSubCategorias] = useState<subCategoriaTable[]>([])
    const getUsuarios = async () => {
        const resUsuarios = await UsuarioServices.getUsuarios();
        setUsuarios(resUsuarios.data);
    }
    const getAllCategorias = async () => {
        setListCategorias([]);
        const resPermisos = await CalidadProduccionServices.getAllCategorias();
        setListCategorias(resPermisos.data);
    }
    const changeUsuario = (e: InputChange) => {
        setPermiso({ ...initialPermiso, [e.target.name]: e.target.value, insert: false, update: false, delete: false })
        setListSubCategorias([]);
        getAllCategorias();
    }
    const changeCategorias = async (e: InputChange) => {
        setPermiso({ ...permiso, [e.target.name]: e.target.value , insert: false, update: false, delete: false})
        setListSubCategorias([])
        const idCategoria = e.target.value;
        const resSubcategoria = await CalidadProduccionServices.getSubCategoriaBySub(idCategoria)
        setListSubCategorias(resSubcategoria.data)
    
    }
    const changeSubCategoria = async (e: InputChange) => {
        setPermiso({ ...permiso, [e.target.name]: e.target.value })        
    }
    const getPermisoUsuario = async()=>{
        const resPermiso = await CalidadProduccionServices.getPermisoUser(permiso);
        setPermiso({...permiso, insert: resPermiso.data.insert, update: resPermiso.data.update, delete: resPermiso.data.delete})
        // console.log(resPermiso)
    }
    const changeCheckBoxs = (e: any) => {
        if (e.target.name === 'insert') {
            setPermiso({ ...permiso, insert: !permiso.insert })
        }
        if (e.target.name === 'update') {
            setPermiso({ ...permiso, update: !permiso.update })
        }
        if (e.target.name === 'delete') {
            setPermiso({ ...permiso, delete: !permiso.delete })
        }
    }
    const actualizarCrearPermiso = async() =>{
        if (!permiso.idUsuario || !permiso.categoria || !permiso.subCategoria) {
            return toast.error("Faltan Datos, f5 e intente nuevamente")
        }
        const resSetPermiso = await CalidadProduccionServices.addPermisoUser(permiso)
        toast.success("Permiso Actualizado")        
    }
    useEffect(() => {
        getUsuarios();
        getAllCategorias();
    }, [])
    useEffect(() => {        
        if (permiso.subCategoria) {
            getPermisoUsuario();
        }else{
            setPermiso({...permiso, insert: false, update: false, delete: false})
        }
    }, [permiso.subCategoria])
    return (
        <div>
            <div className="text-center">
                <h4>GESTION DE PERMISOS</h4>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
                <div className="p-2 ">
                    <select className="form-control form-control-lg border-dark" name="idUsuario" id="idUsuario"
                        onChange={changeUsuario}>
                        <option value="">Seleccion Usuario</option>
                        {usuarios.map((usuario) => {
                            return (
                                <option  value={usuario._id}> {usuario.nombre}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="p-2 ">
                    <select className="form-control form-control-lg border-dark" name="categoria" id="categoria"
                        onChange={changeCategorias}>
                        <option value="">Seleccion Categoria</option>
                        {listCategorias.map((categoria) => {
                            return (
                                <option value={categoria._id}>{categoria.nombre}</option>
                            )
                        })
                        }
                    </select>
                </div>
                <div className="p-2">
                    <select className="form-control form-control-lg border-success" name="subCategoria" id="subCategoria"
                        onChange={changeSubCategoria}>
                        <option value="">Seleccion SubCategoria</option>
                        {listSubCategorias.map((subCategoria) => {
                            return (
                                <option value={subCategoria._id}>{subCategoria.nombre}</option>
                            )
                        })
                        }
                    </select>
                </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
                <div className="m-4">
                    <div className="row">
                        <label className="form-check-label h4 text-success" htmlFor='insert' > Insert</label>
                        <input className="form-check-input mt-4 ml-2 " style={{ width: "30px", height: "30px" }} type="checkbox" name="insert" id="insert"
                            checked={permiso.insert}
                            onClick={changeCheckBoxs} />
                    </div>
                </div>
                <div className="m-4">
                    <div className="row">
                        <label className="form-check-label h4 text-info" htmlFor='update' > Update</label>
                        <input className="form-check-input mt-4 ml-2 " style={{ width: "30px", height: "30px" }} type="checkbox" name="update" id="update"
                            checked={permiso.update}
                            onClick={changeCheckBoxs} />
                    </div>
                </div>
                <div className="m-4">
                    <div className="row">
                        <label className="form-check-label h4 text-danger" htmlFor='delete' > Delete</label>
                        <input className="form-check-input mt-4 ml-2 " style={{ width: "30px", height: "30px" }} type="checkbox" name="delete" id="delete"
                            checked={permiso.delete}
                            onClick={changeCheckBoxs} />
                    </div>
                </div>
            </div> 
            <div className="pt-5 d-flex flex-wrap justify-content-center">
                    <div className="p2">
                        <button type="button" style={{border: "2px solid"}} className="btn btn-outline-success btn-lg"
                            onClick={actualizarCrearPermiso}>
                            <strong>Actualizar Permiso</strong>
                        </button>
                    </div>
            </div>           
        </div>
    )
}

export default Permisos
