import axios from 'axios'
import {proveedor} from './proveedor'
import {cosechasByAgricultor} from '../Cogidas/cogida'

import apiURL from '../../context/apiURL'
const API = apiURL.API;

export const findOneProveedor = async(id: string) => {
    return await axios.get<proveedor>(`${API}/findOneProveedor/${id}`);
}
export const findOneProveedorByRuc = async(id: string) => {
    return await axios.get<proveedor>(`${API}/findOneProveedorByRuc/${id}`);
}
export const crearProveedor = async(data: proveedor) => {
    return await axios.post<proveedor>(`${API}/crearproveedor`, data);
}
export const editarProveedor = async(id: string, data: proveedor) => {
    return await axios.put<proveedor>(`${API}/editarproveedor/${id}`, data);
}
export const allProveedores = async() => {
    return await axios.get<proveedor[]>(`${API}/allproveedores`);
}

export const busquedaProveedor = async(id: string) => {
    return await axios.get<proveedor[]>(`${API}/busquedaproveedor/${id}`);
}

export const getCosechasByProveedor = async(id: string) => {
    return await axios.get<cosechasByAgricultor[]>(`${API}/getCosechasByProveedor/${id}`);
}