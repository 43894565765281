import styled from 'styled-components'

export const IndexMapaContainer = styled.div`
    width: 100%;
    padding:0px;
    margin: 0px;
    /* border: 2px solid #646868; */
    text-align:center;
    
`
export const IndexLabel = styled.label`
    font-size: 22px;
    color: #646868;
    font-weight: bold;
    margin: auto;
    width:100%;
    padding: 0px;
    /* border: 2px solid #646868; */
`
export const SelectIndex = styled.select`
   
   box-shadow: 5px 10px 50px rgba(0,0,0, 0.4);
  padding: 0.6em 0.8em;
  background: #125c03;
  font-size: 1.5rem;
  color: #fff;
  border:0;
  border-radius: 0px !important;

  @media screen and (max-width: 750px){
      width:100%;
  }
`
export const OptionIndex = styled.option`
    font-family: sans-serif;
    padding: 0.6em 0.8em;
`