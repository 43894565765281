import React,{MouseEventHandler, useState, useEffect} from 'react'
import gifLoad from '../../../Media/gifs/load.gif'
import {Button, Modal } from 'react-bootstrap';
import momentz from 'moment-timezone';
import * as proyeccionCtrl from '../ProyeccionesService'
import { MainContainer, ContainerInputs, InputGeneral, ButtonGeneral} from '../../../stylesGeneral/mainElements'
import { toast } from 'react-toastify';
import proyCampo from '../../../Media/png/proyCampo.png'
interface Props {
    show: {
        estado: boolean ,
        idProyeccion: string ,
        numCosecha:number,
        fechaProyeccionCampo:string, 
        jabasProyeccionCampo:number
    };
    onHide: MouseEventHandler<HTMLElement>|any,
    onHideFun: () => void
}

function ModalProyeccion(props:Props) {
    
    const [setValueLoad, setSetValueLoad] = useState(false)
    const [formatProps, setFormatProps] = useState({id:'', fecha:'',jabas:0})
    // const [stateDesbreve, setStateDesbreve] = useState(false)

    const handleChange= (e:any)=>{
        setFormatProps({...formatProps, [e.target.name]: e.target.value})

    }
    
    const loadData = () =>{
            const resFecha = momentz.tz(props.show.fechaProyeccionCampo, "America/Lima").format("YYYY-MM-DD")        
            setFormatProps({...formatProps, id:props.show.idProyeccion, fecha:props.show.fechaProyeccionCampo?resFecha:'', jabas: props.show.jabasProyeccionCampo})      
    }
    const submitProy = async() =>{
        setSetValueLoad(true)
       try {  
           console.log(formatProps) 
        if( !formatProps.id || !formatProps.fecha || formatProps.jabas !<=0 || !formatProps.jabas ) {
            setSetValueLoad(false)
            return toast.error("Complete fecha y Jabas")
        } 
        const submitPC =  await proyeccionCtrl.editOneProyeccion(formatProps);
        if(submitPC.data.ErrorMessage) {
            setSetValueLoad(false)
            return toast.error(submitPC.data.ErrorMessage)
        }
        toast.success('Editado ✓')
        props.onHideFun();
       } catch (error:any) {
           toast.error(error.message)
       }
       setSetValueLoad(false)
    }
  useEffect(() => {
     if(props.show.estado){        
        loadData();
     }else{
        setFormatProps({id:'', fecha:'',jabas:0})
     }
  }, [props.show.estado])
    return (
        <>
        <div className={`${setValueLoad ? 'addOpacitiGif' : ''} loadContainerGif`}>
                <img src={gifLoad} className={`${setValueLoad ? 'opacityOn' : 'opacityOff'}`} alt="" />
            </div>
             <Modal
             
            show={props.show.estado}
            onHide={props.onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">                    
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <MainContainer>
                <ContainerInputs width='100%'>
                    <img src={proyCampo} style={{width:"100px", height:"100px", margin:'auto'}} alt=""/>
                    <h3 className="text-center">Proyeccion Campo #{props.show.numCosecha}</h3>
                </ContainerInputs>
                <ContainerInputs width='100%' >
                    <label htmlFor="" >Fecha</label>
                    <InputGeneral width="100%" height="50px" type="date" name="fecha" 
                    onChange={handleChange} value={formatProps.fecha}/>
                </ContainerInputs>
                <ContainerInputs width='100%'>
                    <label htmlFor="" >Jabas</label>
                    <InputGeneral width="100%" height="50px" type="number" name="jabas"  
                    onChange={handleChange} value={formatProps.jabas}/>
                </ContainerInputs>  
            </MainContainer>
                             
            </Modal.Body>
            <Modal.Footer>
                <ButtonGeneral width="100%" height="70px" color="rgba(20, 143, 119)" onClick={submitProy}>Guardar Proyeccion</ButtonGeneral>
                <ButtonGeneral width="100%" height="60px" color="" onClick={props.onHide}>Cancelar</ButtonGeneral>
                
            </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalProyeccion
