import React, { useState, useEffect, MouseEventHandler} from 'react'
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MainContainer, ContainerInputs, InputGeneral, ButtonGeneral} from '../../../stylesGeneral/mainElements'
import * as proyeccionCtrl from '../ProyeccionesService'
interface Props {
    show: boolean,
    numProyeccion: number,
    // nombreEstacion: string,
    lote: string,
    onHide: MouseEventHandler<HTMLElement>|any,
    afterHideModalCreateProy: ()=> void
}
function ModalCreateProy(props: Props) {
    const initialProyeccion = {
        // nombreEstacion: '',
        numProyeccion: 0,
        fechaProyeccion:'',
        jabasProyeccion: 0,
        lote: ''
    }
    const [newProyeccion, setNewProyeccion] = useState(initialProyeccion)

    const changeNewProyeccion = (e:any)=>{
        setNewProyeccion({...newProyeccion, [e.target.name]: e.target.value})
    }
    const submitNewProyeccion = async() =>{
       try {
            if(!newProyeccion.fechaProyeccion || newProyeccion.jabasProyeccion !<=0) return toast.error("Introdusca fecha y peso")
            // console.log(newProyeccion)
            await proyeccionCtrl.createNewProyeccion(newProyeccion) 
            toast.success("Proyeccion Agregada");
            props.afterHideModalCreateProy()        
       } catch (error:any) {
           toast.error(error.message)
       }
    }
    useEffect(() => {
        if(!props.show){
            setNewProyeccion(initialProyeccion)
        }else{
            setNewProyeccion({...newProyeccion, numProyeccion: props.numProyeccion, lote: props.lote})
        }
    }, [props.show])
    return (
        <>
             <Modal
                 show={props.show}
                 onHide={props.onHide}
                 size="sm"
                 aria-labelledby="contained-modal-title-vcenter"
                 centered
             >
                 <Modal.Header closeButton>  
                     <Modal.Title id="contained-modal-title-vcenter">
                         Agregar Proyeccion {props.numProyeccion}
                     </Modal.Title>                  
                 </Modal.Header>

                 <MainContainer className="container row mx-auto pb-3">
                    <ContainerInputs width='100%'>
                        <label htmlFor="">Fecha Proyeccion</label>
                        <InputGeneral  width="100%" height="50px" type="date" 
                            name='fechaProyeccion'
                            value={newProyeccion.fechaProyeccion}
                            onChange={changeNewProyeccion}
                        />
                    </ContainerInputs>
                    <ContainerInputs width='100%'>
                    <label htmlFor="">Jabas Proyectadas</label>
                        <InputGeneral width="100%" height="50px" type="number" 
                            name="jabasProyeccion"
                            value={newProyeccion.jabasProyeccion}
                            onChange={changeNewProyeccion}
                        />
                    </ContainerInputs>
                    <ContainerInputs width='100%'>
                    <ButtonGeneral width="100%" height="70px" color="rgba(20, 143, 119)" className=" mt-3 p-3 btn btn-lg btn-success btn-block"
                        onClick={submitNewProyeccion}
                    >
                        Crear
                    </ButtonGeneral>
                    </ContainerInputs>
                    <ContainerInputs width='100%'>
                        <ButtonGeneral width="100%" height="60px" color="">
                            Cancelar
                        </ButtonGeneral>
                    </ContainerInputs>
                    
                 </MainContainer>
                
              
             </Modal> 
         </>
    )
}

export default ModalCreateProy