import React, {createContext, useEffect, useState } from 'react'
// import { propTypes } from 'react-bootstrap/esm/Image';
import * as usuarioService from '../components/Usuarios/UsuarioService'
import {UsuarioState} from '../components/Usuarios/usuario'

  export type GlobalContent = {
    loggedIn: {statusLogin: any, type: string, _id: string}
    getLoggedIn:() => void
  }
const authContext = createContext<GlobalContent>({ loggedIn: {statusLogin: false, type:'', _id:''}, getLoggedIn: ()=>{}} );
function AuthContext(props:any) {
    const[loggedIn, setLoggedIn] = useState<UsuarioState>({statusLogin: false, type:'', _id:''});
    const getLoggedIn =   async ()=>{
        try {
          const loggedInRes = await usuarioService.isValidateLogin(); 
        setLoggedIn({...props, statusLogin: loggedInRes.data.statusLogin, type: loggedInRes.data.type, _id:loggedInRes.data._id } );  
        } catch (error) {
          console.log(error)
        }     
    }
    useEffect(() =>{
        getLoggedIn();   
    }, []); 
    return (<authContext.Provider value={{loggedIn, getLoggedIn}}>
        {props.children}
    </authContext.Provider>
    );
}

export default authContext;
export {AuthContext}
