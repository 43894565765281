import React, { useState, useEffect, useRef} from 'react'
import { areas, areas2, temas, capacitadores } from '../registroPersonal'
import {
    MainContainerSet,
    ContainerInputs,
    SelectGeneral,
    InputGeneral,
    ButtonGeneral,
    H3Title,
    H5Title,
    DivDivider,
    DivBordered,
    FormMainContainerSet,
    BtnSpanGeneral,
    TextAreaSet
  } from '../../../stylesGeneral/mainElements'
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'
import * as RPservices from '../RegistroPersonalServices'

function RegistroCapacitaciones() {
    const initialOptEstado = [{valor:"0", nombre:"INACTIVO"}, {valor:"1", nombre:"ACTIVO"}]
    const initialCapacitador = {_id: '', 
        nombres: '', 
        apellidos: '',
        descripcion: '',
        estado: 1
    }
    const initialFormTema = { _id:'', idArea:'', nombre:'', descripcion:'', estado: 1, duracion: 0 }
    const initialFormArea = { _id:'', nombre:'', estado:1 }
    const [areas, setAreas] = useState<areas[]>([])
    const [temas, setTemas] = useState<temas[]>([])
    const [optionsCap, setOptionsCap] = useState<any[]>(initialOptEstado)
    const [optFormTema, setOptFormTema] = useState<any[]>(initialOptEstado)
    const [optFormArea, setOptFormArea] = useState<any[]>(initialOptEstado)
    const [capacitadores, setCapacitadores] = useState<capacitadores[]>([])
    const [formCapacitador, setFormCapacitador] = useState<capacitadores>(initialCapacitador)
    const [formTema, setFormTema] = useState<temas>(initialFormTema)
    const [formArea, setFormArea] = useState<areas2>(initialFormArea)

    const loadFirt100field= async() =>{
        const resAreas = await RPservices.getAreas();
        const resCaps  = await RPservices.getAllCapacitadores();        
        setAreas(resAreas.data);        
        setCapacitadores(resCaps.data)
    }
    const submitCapacitador = async(e:any) =>{
        
        e.preventDefault();
        if (!formCapacitador._id) {
            const resCrearCapacitador = await RPservices.crearCapacitador(formCapacitador);
            if(resCrearCapacitador.data.MessageError){
                return Swal.fire({
                    position: 'center',
                    icon: 'error',                    
                    title: 'ERROR',   
                    text: resCrearCapacitador.data.MessageError,                 
                    showConfirmButton: true,
                    // timer: 2000,                    
                  }) 
            }
               
            if(!resCrearCapacitador.data.MessageError){
                Swal.fire({
                    position: 'center',
                    icon: 'success',                    
                    title: 'CREADO',   
                    text: 'Capacitador',                 
                    showConfirmButton: false,
                    timer: 2000,                    
                  })  
            }                         
        }else{
            const resEditCapacitador = await RPservices.editarCapacitador(formCapacitador);
            if(resEditCapacitador.data.MessageError){
                return Swal.fire({
                    position: 'center',
                    icon: 'error',                    
                    title: 'ERROR',   
                    text: resEditCapacitador.data.MessageError,                 
                    showConfirmButton: true,
                    // timer: 2000,                    
                  }) 
            }
            if(!resEditCapacitador.data.MessageError){
                 Swal.fire({
                    position: 'center',
                    icon: 'success',
                    text: 'Capacitador',
                    title: 'EDITADO',                    
                    showConfirmButton: false,
                    timer: 2000,                    
                  }) 
            }  
        }
        const resCaps  = await RPservices.getAllCapacitadores();
        setCapacitadores(resCaps.data)
        setFormCapacitador(initialCapacitador)
        setOptionsCap([])
        setOptionsCap(initialOptEstado)
    } 
    const handleEditCapacitador = (e:string) =>{
        setOptionsCap([])
        const findCapacitador = capacitadores.find((caps) => caps._id === e);        
        if(!findCapacitador) return toast.error("Capacitador no encontrado recargue ventana")
        setFormCapacitador(findCapacitador)        
        setInterval(function () { setOptionsCap(initialOptEstado) }, 500);
    }
    const changeCapacitador = ( e:any ) =>{        
        setFormCapacitador({...formCapacitador, [e.target.name]: e.target.value})
    }
    const changeTema = ( e:any ) =>{
        setFormTema({...formTema, [e.target.name]: e.target.value})
    }
    const handleEditTema = (e:string) =>{
        setOptFormTema([])
        const findTema = areas.find((area: areas) => {
            for (let i = 0; i < area.temas.length; i++) {
                const oneTema: temas = area.temas[i];                          
                if(oneTema && oneTema._id ===  e ) return oneTema                
            }
        } );
       
        
        if(!findTema) return toast.error("Registro no encontrado")             
        const findTema2 = findTema.temas.find((tema: any) => tema._id ===  e );
        if (findTema2) setFormTema(findTema2)              
        setInterval(function () { setOptFormTema(initialOptEstado) }, 500);
    }
    const submitTema = async(e:any)=>{
        e.preventDefault();        
        if (!formTema._id) {
            const resCrearTema = await RPservices.crearTemas(formTema);
            if(resCrearTema.data.MessageError){
                return Swal.fire({
                    position: 'center',
                    icon: 'error',                    
                    title: 'ERROR',   
                    text: resCrearTema.data.MessageError,                 
                    showConfirmButton: true,
                    // timer: 2000,                    
                  }) 
            }
            Swal.fire({
                position: 'center',
                icon: 'success',                    
                title: 'CREADO',   
                text: 'Tema',                 
                showConfirmButton: false,
                timer: 2000,                    
              }) 
        }else{
            
            const resEditarTema = await RPservices.editarTema(formTema);
            if(resEditarTema.data.MessageError){
                return Swal.fire({
                    position: 'center',
                    icon: 'error',                    
                    title: 'ERROR',   
                    text: resEditarTema.data.MessageError,                 
                    showConfirmButton: true,
                    // timer: 2000,                    
                  }) 
            }
            Swal.fire({
                position: 'center',
                icon: 'success',                    
                title: 'EDITADO',   
                text: 'Tema',                 
                showConfirmButton: false,
                timer: 2000,                    
              })
        }
        const resAreas = await RPservices.getAreas();
        setAreas(resAreas.data)
        setFormTema(initialFormTema)
        setOptFormTema([]);
        setOptFormTema(initialOptEstado)
    }
    const submitArea = async(e:any)=>{
        e.preventDefault();
        if(!formArea._id){
            const resCrearArea = await RPservices.crearArea(formArea);
            if(resCrearArea.data.MessageError){
                return Swal.fire({
                    position: 'center',
                    icon: 'error',                    
                    title: 'ERROR',   
                    text: resCrearArea.data.MessageError,                 
                    showConfirmButton: true,
                    // timer: 2000,                    
                  }) 
            }
            Swal.fire({
                position: 'center',
                icon: 'success',                    
                title: 'CREADO',   
                text: 'Area',                 
                showConfirmButton: false,
                timer: 2000,                    
              }) 
        }else{            
            const resEditarArea = await RPservices.editarArea(formArea);
            if(resEditarArea.data.MessageError){
                return Swal.fire({
                    position: 'center',
                    icon: 'error',                    
                    title: 'ERROR',   
                    text: resEditarArea.data.MessageError,                 
                    showConfirmButton: true,
                    // timer: 2000,                    
                  }) 
            }
            Swal.fire({
                position: 'center',
                icon: 'success',                    
                title: 'EDITADO',   
                text: 'Area',                 
                showConfirmButton: false,
                timer: 2000,                    
            })
        }
        const resAreas = await RPservices.getAreas();
        setAreas(resAreas.data)
        setFormArea(initialFormArea)
        setOptFormArea([]);
        setOptFormArea(initialOptEstado)
    }
    const changeArea = (e:any) =>{
        setFormArea({...formArea, [e.target.name]: e.target.value})
    }
    const handleEditArea = (id: string) =>{
        const findArea = areas.find((area) => area._id === id )
        if(findArea) setFormArea(findArea);
    }
    const cancelarFormCapacitador = () =>{
        setOptionsCap([])
        setInterval(function () { setOptionsCap(initialOptEstado) }, 500);
        setFormCapacitador(initialCapacitador)
    }
    const cancelarFormTema = () =>{
        setOptFormTema([]);
        setInterval( function () { setOptFormTema(initialOptEstado) }, 500)
        setFormTema(initialFormTema)
    }
    const cancelarFormArea = () =>{
        setOptFormArea([])
        setInterval( function () { setOptFormArea(initialOptEstado) }, 500)
        setFormArea(initialFormArea);
    }
    useEffect(() => {
        loadFirt100field();
    }, [])    
    // useEffect(() => {
    //     setOptionsCap(initialOptEstado)
    // }, [formCapacitador._id])
    
  return (
    <>
        <MainContainerSet display="" width="" alignItems="" flexWrap="" justifyContent="">
            <H3Title>
                Configuracion Registro de Capacitaciones
            </H3Title>
            <DivDivider></DivDivider>
        </MainContainerSet>
        <MainContainerSet display="" width="" alignItems="center" flexWrap="" justifyContent="center">
            <MainContainerSet display="" width="" alignItems="" flexWrap="" justifyContent="">
                <H5Title color="" bg="" fontSize="" width="">
                    Capacitadores
                </H5Title>            
            </MainContainerSet>
            <DivBordered>
            <MainContainerSet display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
               
                <FormMainContainerSet onSubmit={submitCapacitador} display="" width="40%" alignItems="start" flexWrap="" justifyContent="start">
                   <ContainerInputs width="100%">
                        <label htmlFor="nombres">Nombres</label>
                        <InputGeneral width="100%" height="50px" name="nombres" 
                            onChange={changeCapacitador}  
                            value={formCapacitador.nombres}                        
                            required                            
                        />
                    </ContainerInputs>
                    <ContainerInputs width="100%">
                        <label htmlFor="apellidos">Apellidos</label>
                        <InputGeneral width="100%" height="50px" name="apellidos" 
                        onChange={changeCapacitador}
                        value={formCapacitador.apellidos}  
                        required/>
                    </ContainerInputs>
                    <ContainerInputs width="100%">
                        <label htmlFor="descripcion">Descripcion</label>
                        <InputGeneral width="100%" height="50px" name="descripcion" 
                        onChange={changeCapacitador} 
                        value={formCapacitador.descripcion}  
                        />
                    </ContainerInputs>
                    <ContainerInputs width="100%">
                     <label htmlFor="estado">Estado</label>
                        <SelectGeneral height="50px" bg="" name="estado" onChange={changeCapacitador} required>
                            {formCapacitador.estado?<option value="1" selected>ACTIVO</option>  :<option value="0"selected>INACTIVO</option> }
                            {optionsCap.map((optcap)=>{
                                return <option value={optcap.valor}>{optcap.nombre}</option>
                            })}
                        </SelectGeneral>
                    </ContainerInputs>
                    <ContainerInputs width="50%">
                       <ButtonGeneral width="100%" height="50px" color=""
                        >
                        {formCapacitador._id ? 'Editar': 'Crear'} Capacitador
                       </ButtonGeneral>                      
                    </ContainerInputs>
                    <ContainerInputs  width="50%">
                        <BtnSpanGeneral width="100%" height="50px" color="#7B7D7D" onClick={cancelarFormCapacitador}>
                            Cancelar
                       </BtnSpanGeneral>
                    </ContainerInputs>
                </FormMainContainerSet>

                <MainContainerSet display="" width="60%" alignItems="center" flexWrap="" justifyContent="center">
                <div className="table-responsive m-4 table-responsive-sm">
                    <table className="tableFixedNone mx-auto">
                    <thead>
                        <tr className=''>   
                        {/* <th >ID</th>              */}
                            <th >NOMBRES</th>  
                            <th >APELLIDOS</th>
                            <th>DESCRIPCION</th>
                            <th>ESTADO</th>
                            <th></th>                                                                 
                        </tr>
                    </thead>
                    <tbody>
                    {capacitadores.map((caps)=>{
                        return <tr>
                            {/* <td>{area._id}</td> */}
                            <td>{caps.nombres}</td>
                            <td>{caps.apellidos}</td>
                            <td>{caps.descripcion}</td>
                            <td>{caps.estado? 'ACTIVO': 'INACTIVO'}</td>
                            <td>
                            <ContainerInputs width="80px" onClick={()=> handleEditCapacitador(caps._id?caps._id:'')}>
                                <ButtonGeneral width="100%" height="50px" color="#424949"><i className="fas fa-pen"></i></ButtonGeneral>
                            </ContainerInputs>                           
                            </td>
                        </tr>
                    })}
                    </tbody>
                    </table>
                </div>
                </MainContainerSet>
            </MainContainerSet>
            </DivBordered>
            
            <MainContainerSet display="" width="" alignItems="" flexWrap="" justifyContent="">
                <H5Title color="" bg="" fontSize="" width="">
                    Temas
                </H5Title>            
            </MainContainerSet>
            <DivBordered>
            <MainContainerSet onSubmit={submitTema} display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">               
                <FormMainContainerSet display="" width="40%" alignItems="start" flexWrap="" justifyContent="start">
                    <ContainerInputs width="100%">
                        <label htmlFor="area">Nombre Tema </label>
                        <InputGeneral width="100%" height="50px" name="nombre" onChange={changeTema} value={formTema.nombre} required/>
                    </ContainerInputs>
                    <ContainerInputs width="100%">
                        <label htmlFor="area">Descripcion </label>
                        <TextAreaSet rows={3} name="descripcion" onChange={changeTema} value={formTema.descripcion} required/>
                    </ContainerInputs>
                    <ContainerInputs width="100%">
                        <label htmlFor="duracion">Duracion Minutos </label>
                        <InputGeneral type="number" width="100%" height="50px" name="duracion" onChange={changeTema} value={formTema.duracion} required/>
                    </ContainerInputs>
                    <ContainerInputs width="100%">
                        <label htmlFor="idArea">Area</label>
                        <SelectGeneral height="50px" bg="" name="idArea" onChange={changeTema} value={formTema.idArea} required>
                            <option value="">Seleccione</option>   
                            {areas.map((area)=>{
                                return <> <option value={area._id}>{area.nombre}</option></>
                            })}                         
                        </SelectGeneral>
                    </ContainerInputs>
                    
                    <ContainerInputs width="100%">
                     <label htmlFor="">Estado</label>
                        <SelectGeneral height="50px" bg="" name="estado" onChange={changeTema} required>
                        {formTema.estado?<option value="1" selected>ACTIVO</option>  :<option value="0"selected>INACTIVO</option> }
                            {optFormTema.map((optcap)=>{
                                return <option value={optcap.valor}>{optcap.nombre}</option>
                            })}
                        </SelectGeneral>
                    </ContainerInputs>
                    <ContainerInputs width="50%">
                       <ButtonGeneral width="100%" height="50px" color="">
                            {formTema._id? 'Editar': 'Crear'} Tema
                       </ButtonGeneral>                      
                    </ContainerInputs>
                    <ContainerInputs  width="50%">
                        <BtnSpanGeneral width="100%" height="50px" color="#7B7D7D" onClick={cancelarFormTema}>
                            Cancelar
                       </BtnSpanGeneral>
                    </ContainerInputs>
                </FormMainContainerSet>
                <MainContainerSet display="" width="60%" alignItems="center" flexWrap="" justifyContent="center">
                <div className="table-responsive m-4 table-responsive-sm">
                    <table className="tableFixedNone mx-auto">
                    <thead>
                        <tr className=''>   
                        {/* <th >ID</th>              */}
                            <th >TEMA</th> 
                            <th>DURACION(min)</th>
                            <th> DESCRIPCION</th> 
                            <th> AREA</th>
                            <th >ESTADO</th>
                            <th></th>                                                                 
                        </tr>
                    </thead>
                    <tbody>
                    {areas.map((area:areas)=>{                        
                      return area.temas.map((tema:temas)=>{
                            return <tr>                    
                                <td>{tema.nombre}</td>
                                <td>{tema.duracion}</td>
                                <td>{tema.descripcion}</td>
                                <td>{area.nombre}</td>
                                <td>{tema.estado? 'ACTIVO': 'INACTIVO'}</td>
                                <td>
                                <ContainerInputs width="80px">
                                    <ButtonGeneral width="100%" height="50px" color="#424949" onClick={()=> handleEditTema(tema._id)}><i className="fas fa-pen"></i></ButtonGeneral>
                                </ContainerInputs>                           
                                </td>
                            </tr>
                       })
                    })}
                    </tbody>
                    </table>
                </div>
                </MainContainerSet>
            </MainContainerSet>
            </DivBordered>

            <MainContainerSet display="" width="" alignItems="" flexWrap="" justifyContent="">
                <H5Title color="" bg="" fontSize="" width="">
                    Areas
                </H5Title>            
            </MainContainerSet>
            <DivBordered>
            <MainContainerSet display="" width="100%" alignItems="flex-start" flexWrap="" justifyContent="center">
               
                <FormMainContainerSet onSubmit={submitArea} display="" width="40%" alignItems="start" flexWrap="" justifyContent="start">
                    <ContainerInputs width="100%">
                        <label htmlFor="">Nombre Area</label>
                        <InputGeneral width="100%" height="50px" name="nombre" onChange={changeArea} value={formArea.nombre}  required/>
                    </ContainerInputs>
                    <ContainerInputs width="100%">
                     <label htmlFor="">Estado</label>
                        <SelectGeneral height="50px" bg="" name="estado" onChange={changeArea} required >
                            {formArea.estado?<option value="1" selected>ACTIVO</option>  :<option value="0"selected>INACTIVO</option> }
                            {optFormArea.map((optcap)=>{
                                return <option value={optcap.valor}>{optcap.nombre}</option>
                            })}
                        </SelectGeneral>
                    </ContainerInputs>
                    <ContainerInputs width="50%">
                       <ButtonGeneral width="100%" height="50px" color="">
                           {formArea._id? 'Editar': 'Crear'} Area
                       </ButtonGeneral>                      
                    </ContainerInputs>
                    <ContainerInputs  width="50%">
                        <BtnSpanGeneral width="100%" height="50px" color="#7B7D7D" onClick ={cancelarFormArea}>
                            Cancelar
                       </BtnSpanGeneral>
                    </ContainerInputs>
                </FormMainContainerSet>
                <MainContainerSet display="" width="60%" alignItems="center" flexWrap="" justifyContent="center">
                <div className="table-responsive m-4 table-responsive-sm">
                    <table className="tableFixedNone mx-auto">
                    <thead>
                        <tr className=''>                          
                            <th >AREA</th>  
                            <th >ESTADO</th>
                            <th></th>                                                                 
                        </tr>
                    </thead>
                    <tbody>
                    {areas.map((area)=>{
                        return <tr>                           
                            <td>{area.nombre}</td>
                            <td>{area.estado? 'ACTIVO': 'INACTIVO'}</td>
                            <td>
                            <ContainerInputs width="80px">
                                <ButtonGeneral width="100%" height="50px" color="#424949" onClick={()=> handleEditArea(area._id)}><i className="fas fa-pen"></i></ButtonGeneral>
                            </ContainerInputs>                           
                            </td>
                        </tr>
                    })}
                    </tbody>
                    </table>
                </div>
                </MainContainerSet>
            </MainContainerSet>
            </DivBordered>
        </MainContainerSet>
    </>    
  )
}

export default RegistroCapacitaciones
