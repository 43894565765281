import axios from 'axios'
import apiURL from '../../context/apiURL'
import {input, permisos, registroInput, rangoFechas, parametros, listObjetivos, typeCreateUpdateRegInput} from './typesCalidadProduccion'
const API = apiURL.API;

export const getAllInputs = async()=>{
    return await axios.get(`${API}/getAllInputs`)
}
export const getAllOutputs = async()=>{
    return await axios.get(`${API}/getAllOutputs`)
}

export const getCatagoriaInputs = async (id: string) => {
    return await axios.get(`${API}/getCatagoriaInputs/${id}`)    
 }

 export const getSubCategoriaInputs = async(id: string) =>{
     return await axios.get(`${API}/getSubCategoriaInputs/${id}`)
 }

 export const getAllCategorias = async() =>{
     return await axios.get(`${API}/getAllCategorias`);
 }
 export const getSubCategoriaBySub = async(id: string) =>{
     return await axios.get(`${API}/getSubCategoriaBySub/${id}`)
 }
 export const getPermisoUser = async(data: permisos) =>{
     return await axios.post(`${API}/getPermisoUser`, data);
 }
 export const addPermisoUser = async(data: permisos) =>{
     return await axios.post(`${API}/addPermisoUser`, data);
 }
 export const getInputsBySubCat = async(id: string) =>{
     return await axios.get(`${API}/getInputsBySubCat/${id}`)
 }
 export const createRegistroInput = async(data: registroInput)=>{
     return await axios.post(`${API}/createRegistroInput`, data)
 }
 ////REGISTRO INPUTS
 export const getAllRegistrosInput = async(id: string) =>{
     return await axios.get(`${API}/getAllRegistrosInput/${id}`)
 }

 export const editRegistroInput = async(data: registroInput) =>{
     return await axios.post(`${API}/editRegistroInput`, data)
 }

 export const getResumenInputsByFecha = async(data: rangoFechas) =>{
     return await axios.post(`${API}/getResumenInputsByFecha`, data)
 }
 export const getInputsRegByFecha = async(fechas: rangoFechas) =>{
     return await axios.post(`${API}/getInputsRegByFecha`, fechas)
 }
 export const getOneRegInputByFecha = async(data:{idInput: string, fechaRegistro: string , idUsuario: string}) =>{
     return await axios.post(`${API}/getOneRegInputByFecha`, data)
 }
 export const createUpdateRegInput = async(data: typeCreateUpdateRegInput)=>{
     return await axios.post(`${API}/createUpdateRegInput`, data)
 }
 /// FORMATO 
 export const getAllFormatos = async() =>{
    return await axios.get(`${API}/getAllFormatos`)
}
export const getAllEnvases = async() =>{
    return await axios.get(`${API}/getEnvases`)
}
export const getAllTapas = async() =>{
    return await axios.get(`${API}/getTapas`)
}

///PARAMETROS
export const createParametro = async(data: parametros)=>{
    return await axios.post(`${API}/createParametro`, data)
}
export const listParametros= async()=>{
    return await axios.get(`${API}/listParametros`)
}
export const editParametro = async(data: parametros) =>{
    return await axios.post(`${API}/editParametro`, data)
}

/// OBJETIVOS
export const getAllRegObjetivos = async(fechas: rangoFechas) =>{
    return await axios.post(`${API}/getAllRegObjetivos`, fechas)
}
export const getRegistrosByOneFecha = async(fecha: string) =>{
    return await axios.get(`${API}/getRegistrosByOneFecha/${fecha}`,)
}
export const crearRegistroObjetivosPorFecha = async(data: any) =>{
    return await axios.post(`${API}/crearRegistroObjetivosPorFecha`, data)
}
export const createOrUpdateRegObj = async(data: any) =>{
    return await axios.post(`${API}/createOrUpdateRegObj`, data)
}