import React, { useState, useEffect, MouseEventHandler } from 'react'
import { Modal } from 'react-bootstrap';
import { ingreso,listIngreso, almacenes, tipoMovimiento, detIngreso } from '../TypesAlmacenes'
import * as ALMACENESservice from '../AlmacenesService'
import Ingresos from './Ingresos'
import {
    Input, Label, Select, Option, Container, FormContainer, Form
    , TableContainer, Table, THead, TH, TR,TRbody, TBody, TD, ContainerInput
    , SPAN, ButtonForm, BtnOpt
} from './ModalIngresosElements'
import { toast } from 'react-toastify';
import moment from 'moment';


interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>,
    idAlmacen: almacenes | undefined
    
}

function ListIngresos(props: Props|any) {
    const initialIngreso = {
        almacenDestino: {
            _id:'',
            nombre:''
        },
        almacenOrigen: {
            _id:'',
            nombre:''
        },
        estado: 'PENDIENTE',
        fechaIngreso: '',
        guiaRecepcion: '',
        ordenSiembra:'',
        finalOrdenSiembra: false,
        igvTotal: 0,
        numero: 0,
        proveedor: {
            _id:'',
            nombre:''
        },
        subtotal: 0,
        tipoMovimiento: {
            _id:'',
            descripcion:''
        },
        total: 0,
        _id: ''
    }
    const [listIngresos, setListIngresos] = useState<ingreso[]>([]);
    const [ingreso, setIngreso] = useState<ingreso>(initialIngreso)
    const [modalIngreso, setModalIngreso] = useState(false)
    const loadIngresos = async () => {
        const resIngresos = await ALMACENESservice.getIngresosByAlmacen(props.idAlmacen?._id)
        console.log(resIngresos.data)
        setListIngresos(resIngresos.data)
    }
    const showModalCreateIngreso = () => {
        setIngreso(initialIngreso)
        setModalIngreso(true)
    }
    const handleCloseModalIngreso = () => {
        setIngreso(initialIngreso)
        setModalIngreso(false);
        loadIngresos();
    }
    const viewIngreso = (id: string | undefined)=>{
        console.log(id)
        const selectIngreso = listIngresos.find(ingreso=> ingreso._id===id)
        if (selectIngreso?._id) {
            setIngreso(selectIngreso)
            setModalIngreso(true)
        }else{
            toast.error('No se encontro el registro, comuniquese con su administrador')
        }
        // console.log(selectIngreso)
        // setModalIngreso(true)
    }
    useEffect(() => {
        if (props.show) {
            loadIngresos();
        }else{
            setListIngresos([]);
        }
    }, [props.show])
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <Container>
                            <FormContainer align={'flex-center'}>
                                <ContainerInput size={'400px'}>
                                    INGRESOS - {props.idAlmacen?.nombre}
                                </ContainerInput>
                                <ContainerInput size={'200px'}>
                                    <ButtonForm onClick={showModalCreateIngreso}>Crear</ButtonForm>
                                </ContainerInput>
                            </FormContainer>
                        </Container>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>
                            <FormContainer align={'flex-start'}>
                                <ContainerInput size={'200px'}>
                                    <Label htmlFor='fechaInicial'>Fecha Inicio</Label>
                                    <Input type="date" name="fechaInicial" id="fechaInicial"
                                    bg={'#fff'}/>                                  
                                </ContainerInput>
                                <ContainerInput size={'200px'}>
                                    <Label htmlFor='fechaFin'>Fecha Fin</Label>
                                    <Input type="date" name="fechaFin" id="fechaFin"
                                    bg={'#fff'}/>                                  
                                </ContainerInput>
                                <ContainerInput size={'200px'}>
                                    <Label htmlFor='fechaFin'>Fecha Fin</Label>
                                    <Select  id="fechaFin"
                                    bg={'#fff'}>
                                        <Option value=''>Seleccione</Option>
                                        <Option value='numero'>Numero Ingreso</Option>
                                        <Option value='estado'>estado</Option>
                                        <Option value='tipoMovimiento'>Tipo Movimiento</Option>
                                        <Option value='proveedor'>Proveedor</Option>
                                        </Select> 
                                </ContainerInput>
                                <ContainerInput size={'200px'}>
                                    <Label htmlFor='textBusqueda'>Valor</Label>
                                    <Input type="text" name="textBusqueda" id="textBusqueda"
                                    bg={'#fff'}/>                                  
                                </ContainerInput>
                            </FormContainer>
                        </Container>
                    <TableContainer height={'45%'} className="table-responsive  col-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">
                        <Table >
                            <THead>
                                <TR>

                                    <TH>ESTADO</TH>
                                    <TH>NUMERO</TH>
                                    <TH>TIPO MOVIMIENTO</TH>
                                    <TH>PROVEEDOR</TH>
                                    <TH>ORIGEN</TH>
                                    <TH>DESTINO</TH>
                                    <TH>GUIA RECEPCION</TH>
                                    <TH >FECHA INGRESO</TH>
                                    <TH >FECHA CREACION</TH>
                                    <TH >SUBTOTAL</TH>
                                    <TH >IGV TOTAL</TH>
                                    <TH >TOTAL</TH>
                                    
                                </TR>
                            </THead>
                            <TBody>

                                {listIngresos.map((ingresos) => {
                                    return <TRbody id={ingresos._id} key={ingresos._id} 
                                            onClick={()=>viewIngreso(ingresos._id)}>
                                        <TD>{ingresos.estado}</TD>
                                        <TD>{ingresos.numero}</TD>
                                        <TD>{ingresos.tipoMovimiento.descripcion}</TD>
                                        <TD>{ingresos.proveedor?.nombre}</TD>
                                        <TD>{ingresos.almacenOrigen?.nombre}</TD>
                                        <TD>{ingresos.almacenDestino.nombre}</TD>
                                        <TD>{ingresos.guiaRecepcion}</TD>
                                        <TD>{moment(ingresos.fechaIngreso).format('YYYY-MM-DD')}</TD>
                                        <TD>{moment(ingresos.fechaIngreso).format('YYYY-MM-DD')}</TD>
                                        <TD>{ingresos.subtotal}</TD>
                                        <TD>{ingresos.igvTotal}</TD>
                                        <TD>{ingresos.total}</TD>
                                      
                                    </TRbody>
                                })}

                            </TBody>
                        </Table>
                    </TableContainer>

                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
            <Ingresos idAlmacen={props.idAlmacen} ingreso={ingreso} show={modalIngreso} onHide={handleCloseModalIngreso}></Ingresos>
        </>

    )
}

export default ListIngresos
