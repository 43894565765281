import React, {MouseEventHandler, useState,useEffect, ChangeEvent} from 'react'
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import * as almacenesService from '../AlmacenesService'
import {producto, detIngreso} from '../TypesAlmacenes'
import {
    Input, Label, Select, Option, Container, FormContainer, Form, ContainerInput
    , ButtonForm, InputTextArea
} from './IngresosElements'


interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>,
    idIngreso: string | undefined,
    numeroIngreso: number,
    detalleIngreso: detIngreso | undefined
}

type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;


function DetIngresos(props:Props|any) {
    const stateInitialDetIngreso = {
      ingreso: '', numeroIngreso:0 , estado: 'PENDIENTE',producto: {_id:'', nombre:''}, lote: '', ordenCompra:'', ordenSiembra:'', detalle:'', cantidad:1, unidadMedida:'',cantidadDisponible:1, precio:0, igv:0, total:0
    }
    const [detIngreso, setDetIngreso] = useState<detIngreso>(stateInitialDetIngreso)
    const [optProductos, setOptProductos] = useState<producto[]>([])
    const [stateIgv, setStateIgv] = useState('NO')
    const resetFormDetIngreso = () =>{
        setDetIngreso(stateInitialDetIngreso)
        setOptProductos([])
        setStateIgv('NO')
    }
    const handleChangeIgv = (e:any) =>{
        setStateIgv(e.target.value);
        
    }
    const verifyIgv = ()=>{
        if (stateIgv==='SI') {
            const igv = parseFloat((detIngreso.precio*0.18).toString()).toFixed(2);
            const total = parseFloat((detIngreso.precio*1.18).toString()).toFixed(2);
            setDetIngreso({...detIngreso, igv: parseFloat(igv) , total: parseFloat(total) })
        }else if(stateIgv==='NO'){
            setDetIngreso({...detIngreso, igv: 0, total: detIngreso.precio }) 
        }
    }
    const handleChangeDetIngreso = async (e:any)=>{
       
        if (e.target.name === 'cantidad') {            
            
            setDetIngreso({...detIngreso, cantidad: e.target.value, cantidadDisponible: e.target.value})
        }
        else if(e.target.name === 'producto'){
            
            if (e.target.value) {
                   
                const umedida = await getUMedida(e.target.value);   
                console.log("entrando al IF", umedida)          
                setDetIngreso({...detIngreso, [e.target.name]: e.target.value, unidadMedida: umedida})

            }else{
                console.log("entrando al Else")
                setDetIngreso({...detIngreso,[e.target.name]: e.target.value, unidadMedida: ''})
            }            
        }
        else{
            
            setDetIngreso({...detIngreso, [e.target.name]: e.target.value})
        }
    }
    const getUMedida = async(id: string) =>{
        
         
            console.log("SE ESTA BUSCANDO LA UNIDAD DE MEDIDA", id)            
            const resUMedida = await almacenesService.getUMedidaByProducto(id); 
            console.log(resUMedida.data.uMedida.abreviado)   
            return resUMedida.data.uMedida.abreviado;
            // setDetIngreso({...detIngreso, unidadMedida: resUMedida.data.uMedida.abreviado})
         
    }
    const busquedaProducto = async(e:any) =>{

        if (e.key === 'Enter'){
            e.preventDefault();
            const resBusquedaProd = await almacenesService.getProductoByText(e.target.value)        
            console.log(resBusquedaProd.data)
            setOptProductos(resBusquedaProd.data)
        }
        
    }
    const submitDetIngreso = async(e:any) =>{
        e.preventDefault();
     try {
        if (!detIngreso._id) { // Si el ID de detalle NO existe es por que se va proceder a Crear Detalle
            console.log(detIngreso);
        if (detIngreso.ingreso) {
            await almacenesService.createDetIngreso(detIngreso);            
            toast.success("DETALLE GUARDADO")                       
        }else{
            toast.error("Error al asignar detalle al Ingreso.");
        }
        setIngresoToDetalle();
    }else{ // Caso contrario se va rpoceder a Editar
        const resEditDetIngreso = await almacenesService.editDetIngreso(detIngreso);
        console.log(resEditDetIngreso);
        toast.success(resEditDetIngreso.data.message)
    }
     } catch (error:any) {
         console.log(error)
         toast.error(error.message)
     }
    }
    const setIngresoToDetalle = ()=>{
        setDetIngreso({...detIngreso, ingreso: props.idIngreso, numeroIngreso: props.numeroIngreso})
    }
    useEffect(() => {        
        if (props.show===false) {
            resetFormDetIngreso();  
        }else{
            console.log(props.detalleIngreso)
            if (props.detalleIngreso?._id) {
                setDetIngreso(props.detalleIngreso);
            }else{
                setIngresoToDetalle();
            }            
        }
    }, [props.show])
    useEffect(() => {
        verifyIgv();
    }, [detIngreso.precio, stateIgv])
    // useEffect(() => {
    //     if (!detIngreso.producto._id) {
    //         console.log(detIngreso.producto, "SE ESTA ENVIANDO PARA OBTENER LA UNIDAD MEDIDA")
    //         getUMedida(detIngreso.producto);
    //     }
    // }, [detIngreso.producto])
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {detIngreso._id ? ('Edicion'):('Creacion')} Ingreso -
                        {props.numeroIngreso}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form onSubmit={submitDetIngreso}>
                            <FormContainer align={'flex-start'}>
                                <ContainerInput size={'200px'}>
                                    <Label htmlFor='estado'> Estado</Label>
                                    <Input type='text' id='estado' name='estado' bg={'#21332636'}
                                        value={detIngreso.estado}                                        
                                        readOnly
                                        required
                                    />
                                </ContainerInput>
                                <ContainerInput size={'100px'}>
                                    <Label htmlFor='busquedaProd'>Busq. Producto</Label>
                                    <Input type='text' id='busquedaProv' name='busquedaProv' bg={'#fff'}
                                        onKeyPress={busquedaProducto}
                                        readOnly= {detIngreso._id ? true: false}
                                    />
                                </ContainerInput>
                                <ContainerInput size={'250px'}>
                                    <Label htmlFor='producto'>Producto</Label>

                                    <Select id='producto' name='producto' bg={'#fff'}
                                        // disabled={stateDisabled.proveedor}
                                        onChange={handleChangeDetIngreso}
                                        disabled= {detIngreso._id ? true: false}
                                        required>
                                        
                                        {detIngreso.producto._id ? (<Option value={detIngreso.producto._id} selected>{detIngreso.producto.nombre}</Option>):(<Option value="" selected>SELECCIONE</Option>)}
                                        {optProductos.map((producto) => {
                                            return <Option key={producto._id} value={producto._id}>{producto.nombre}</Option>
                                        })
                                        }
                                    </Select>
                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='lote'>Lote</Label>
                                    <Input type='text' id='lote' name='lote' bg={'#fff'}
                                        value={detIngreso.lote}
                                        onChange={handleChangeDetIngreso}                                       
                                         />

                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='ordenCompra'>Orden Compra</Label>
                                    <Input type='text' id='ordenCompra' name='ordenCompra' bg={'#fff'}
                                        value={detIngreso.ordenCompra}
                                        onChange={handleChangeDetIngreso}                                       
                                         />

                                </ContainerInput>
                                {/* <ContainerInput size={'150px'}>
                                    <Label htmlFor='ordenSiembra'>Orden Siembra</Label>
                                    <Input type='text' id='ordenSiembra' name='ordenSiembra' bg={'#fff'}
                                        value={detIngreso.ordenSiembra}
                                        onChange={handleChangeDetIngreso}
                                        
                                    />
                                </ContainerInput> */}
                                <ContainerInput size={'100px'}>
                                    <Label htmlFor='unidadMedida'>UM</Label>
                                    <Input type='text'  id='unidadMedida' name='unidadMedida' bg={'#21332636'}
                                        value={detIngreso.unidadMedida}
                                        onChange={handleChangeDetIngreso}
                                        required  
                                        readOnly                                      
                                    />
                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='cantidad'>Cantidad</Label>
                                    <Input type='number' step='any' id='cantidad' name='cantidad' bg={'#fff'}
                                        value={detIngreso.cantidad}
                                        onChange={handleChangeDetIngreso}
                                        required                                        
                                    />
                                </ContainerInput>      
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='cantidadDisponible'>Cantidad Disponible</Label>
                                    <Input type='number' step='any' id='cantidadDisponible' name='cantidadDisponible' bg={'#21332636'}
                                        value={detIngreso.cantidadDisponible}                                        
                                        required   
                                        readOnly                                     
                                    />
                                </ContainerInput>                            
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='precio'>Precio</Label>
                                    <Input type='number' step='any' id='precio' name='precio' bg={'#fff'}
                                        value={detIngreso.precio}
                                        onChange={handleChangeDetIngreso}
                                        required                                        
                                    />
                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='igv'>Igv?</Label>
                                    <Select id='igv' name='igv' bg={'#fff'}                                       
                                        onChange={handleChangeIgv}

                                    >
                                        <Option value="NO" selected>NO</Option>
                                        <Option value="SI">SI</Option>
                                        

                                    </Select>

                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='igv'>Monto Igv</Label>
                                    <Input type='number' step='any' id='igv' name='igv' bg={'#21332636'}
                                        value={detIngreso.igv}                                        
                                        required
                                        readOnly
                                    />

                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='total'>Total</Label>
                                    <Input type='number' step='any' min={0} id='total' name='total' bg={'#21332636'}
                                        value={detIngreso.total}
                                        onChange={handleChangeDetIngreso}
                                        required 
                                        readOnly                                       
                                    />

                                </ContainerInput>
                                <ContainerInput size={'100%'}>
                                    <Label htmlFor='detalle'>Detalle</Label>

                                    <InputTextArea bg={'#fff'} cols={30} rows={2} id='detalle' name='detalle' 
                                    value={detIngreso.detalle}
                                    onChange={handleChangeDetIngreso}
                                    ></InputTextArea>
                                                                    
                                </ContainerInput>

                            </FormContainer>
                            <FormContainer align={'center'}>
                                <ContainerInput size={'300px'}>
                                    <ButtonForm>{detIngreso._id ? ('EDITAR'):('CREAR')} DETALLE</ButtonForm>
                                </ContainerInput>

                            </FormContainer>
                        </Form>

                        
                    </Container>


                </Modal.Body>
                <Modal.Footer>
                    <FormContainer align={'center'}>
                        <ContainerInput size={'400px'}>
                            <ButtonForm onClick={props.onHide}>SALIR</ButtonForm>
                        </ContainerInput>
                    </FormContainer>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DetIngresos
