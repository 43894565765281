import React, { MouseEventHandler, useState, useEffect, ChangeEvent } from 'react'
import { Button, Modal } from 'react-bootstrap';
// import Table from 'react-bootstrap/Table'
import { ingreso, almacenes, tipoMovimiento, detIngreso, ordenSiembra} from '../TypesAlmacenes'
import { proveedor } from '../../Proveedores/proveedor'
// SERVIVIOS API
import * as almacenesService from '../../Planta/AlmacenMP/AlmacenMPServices'
import * as proveedoresService from '../../Proveedores/ProveedorService'
import * as ALMACENESservice from '../AlmacenesService'

import {
    Input, Label, Select, Option, Container, FormContainer, Form
    , TableContainer, Table, THead, TH, TR, TBody, TD, ContainerInput
    , SPAN, SpanOptForm, ButtonForm, I, MainContainer, CheckBox
} from './IngresosElements'

import DetalleConfirmacion from '../ModalConfirmacion/DetalleConfirmacion'
import DetIngresos from './DetIngresos'
import { toast } from 'react-toastify';
import moment from 'moment';

interface Props {
    show: boolean,
    onHide: MouseEventHandler<HTMLElement>,
    idAlmacen: almacenes | undefined,
    ingreso: ingreso
    // onHideFun: () => void
}
type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;
export default function Ingresos(props: Props|any ) {
    const stateinitialIngreso = {
        _id: '',
        estado: 'PENDIENTE',
        numero: 0,
        tipoMovimiento: {
            _id: '',
            descripcion: ''
        },
        proveedor: {
            _id: '',
            nombre: ''
        },
        almacenOrigen: {
            _id: '',
            nombre: ''
        },
        almacenDestino: {
            _id: '',
            nombre: ''
        },
        ordenSiembra:'',
        finalOrdenSiembra: false,
        fechaIngreso: '',
        guiaRecepcion: '',
        subtotal: 0,
        igvTotal: 0,
        total: 0
    }
    const stateInitialDetIngreso = {
        ingreso: '', numeroIngreso:0 , estado: 'PENDIENTE',producto: {_id:'', nombre:''}, lote: '', ordenCompra:'', ordenSiembra:'', detalle:'',unidadMedida:'', cantidad:1,cantidadDisponible:1, precio:0, igv:0, total:0
      }
    const [ingreso, setIngreso] = useState<ingreso>(stateinitialIngreso);
    const [detalleIngreso, setdetalleIngreso] = useState<detIngreso|undefined>();
    const [optProveedores, setOptProveedores] = useState<proveedor[]>([]);
    const [optAlmacenes, setOptAlmacenes] = useState<almacenes[]>([]);
    const [tipoMovimientos, setTipoMovimientos] = useState<tipoMovimiento[]>([]);
    const [listDetIngreso, setListDetIngreso] = useState<detIngreso[]>([]);
    const [stateDisabled, setStateDisabled] = useState({ proveedor: false, almacenOrigen: true });
    const [stateModalDetIngreso, setStateModalDetIngreso] = useState(false);
    const [stateFormIngreso, setStateFormIngreso] = useState(false);
    const [modalConfirmDeleteDet, setmodalConfirmDeleteDet] = useState(false);
    const [idToDeleteDet, setIdToDeleteDet] = useState('');
    const [optOrdenSiembra, setOptOrdenSiembra] = useState<ordenSiembra[]>([]);
    const [stateOS, setStateOS] = useState(false);
    // const [stateCheckFinOS, setStateCheckFinOS] = useState(false)
    const resetFormIngreso = () => {        
        setIngreso(stateinitialIngreso);
        setStateFormIngreso(false);
        // setOptAlmacenes([])
        // setOptProveedores([])
        setOptOrdenSiembra([])
        setStateDisabled({ proveedor: true, almacenOrigen: true })
    }
    const handleSubmitIngreso = async (e: any) => {
        
        e.preventDefault();
        console.log(ingreso)
     try {
        if (!ingreso._id) {// SI NO EXISTE EL ID SE PROCEDE A CREAR
            const resSaveIngreso = await ALMACENESservice.createIngreso(ingreso);
            
            if (resSaveIngreso.status === 200) {
                toast.success("INGRESO CREADO")
                setIngreso({ ...ingreso, _id: resSaveIngreso.data._id, numero: resSaveIngreso.data.numero })
                setStateFormIngreso(true)
            }
        } else {
            
            const resEditIngreso = await ALMACENESservice.editIngreso(ingreso);
            if (resEditIngreso.status === 200) {
                toast.success("Editado")
            }else{            
                toast.error(resEditIngreso.data.message)
            }
        }
     } catch (error:any) {
         toast.error(error.message)
     }
    }
    const handleChangeIngreso = (e: any) => {
        // console.log(e.target.value, [e.target.name])
        console.log("EJECUTANDO HCHANGE INGRESO" , ingreso)
        setIngreso({ ...ingreso, [e.target.name]: e.target.value })
        // console.log(tipoMovimientos)
        if (e.target.name === 'tipoMovimiento') {
            const verifyTransformacion = tipoMovimientos.find(movimiento => movimiento._id === e.target.value);
            // console.log(verifyTransformacion)
            if(verifyTransformacion?.transformacion===1){
                setStateOS(true);
                
            }else{
                setStateOS(false);
                
            }
        }
    
    }

    const busquedaProveedor = async (e: any) => {
        // console.log("busqueda Provedor")

        if (e.key === 'Enter') {
            // console.log('enter press here! ', e.target.value)
            e.preventDefault();
            const resProveedores = await proveedoresService.busquedaProveedor(e.target.value)
            setOptProveedores(resProveedores.data)
            // console.log(optProveedores)
        }
    }
    const loadAlmacenesOrigen = async () => {
        const resAlmacenes = await almacenesService.getAllAlmacenes();
        setOptAlmacenes(resAlmacenes.data)
    }
    const handleCloseModalDet = () => {
        setdetalleIngreso(stateInitialDetIngreso)
        setStateModalDetIngreso(false)
        loadAllIngreso();
        // if(!ingreso._id){ // Si ya existe un id de ingreso no me actualices el formulario
        //     loadAllIngreso();
        // }else{            // Solo actualizamos el detalle 
        //     loadDetIngreso(ingreso._id)
        // }
    }
    const addDetalleIngreso = () => {
        if (!ingreso._id || ingreso.estado != 'PENDIENTE') {
            return toast.info("Ingreso no disponible para crear Detalle")
        }
        setStateModalDetIngreso(true)
    }
    const loadTipoMovimientos = async () => {
        const allMovimientos = await ALMACENESservice.getTipoMovimientoByDocumento('INGRESOS');
        setTipoMovimientos(allMovimientos.data)
    }
    const loadDetIngreso = async (id: string | undefined) => {
        const resDetIngreso = await ALMACENESservice.getDetIngresoByIdIngreso(id);        
        setListDetIngreso(resDetIngreso.data);
    }
    const loadAllIngreso = async () => {
        const resOneIngreso = await ALMACENESservice.getOneIngreso(ingreso._id);
        
        const IngresoUpdated = {
            // _id: resOneIngreso.data._id, estado: resOneIngreso.data.estado,
            // numero: resOneIngreso.data.numero, tipoMovimiento: resOneIngreso.data.tipoMovimiento,
            // proveedor: resOneIngreso.data.proveedor, almacenOrigen: resOneIngreso.data.almacenOrigen,
            // almacenDestino: resOneIngreso.data.almacenDestino, fechaIngreso: moment(resOneIngreso.data.fechaIngreso).format('YYYY-MM-DD'),
            // guiaRecepcion: resOneIngreso.data.guiaRecepcion, 
            
            subtotal: resOneIngreso.data.subtotal,
            igvTotal: resOneIngreso.data.igvTotal, total: resOneIngreso.data.total
        }
        setIngreso({...ingreso, subtotal: IngresoUpdated.subtotal, igvTotal: IngresoUpdated.igvTotal, total: IngresoUpdated.total});
        loadDetIngreso(ingreso._id)
    }

    const anularIngreso = async() =>{
    }

    const eliminarIngreso = async() =>{        
    }

    const editDetIngreso = (id: string|undefined)=>{
        setStateModalDetIngreso(true)
        const selectedDetIngreso = listDetIngreso.find(detIngreso => detIngreso._id === id);
        console.log(selectedDetIngreso);
        setdetalleIngreso(selectedDetIngreso);
    }
    const deleteDetalle = async(id: string|undefined) =>{
        if (id) {
            setIdToDeleteDet(id);
        }
        setmodalConfirmDeleteDet(true);
    }
    const closeModalConfirmDet = () =>{
        setmodalConfirmDeleteDet(false)
    }
    const closeModalAfterConfirm = () =>{
        setmodalConfirmDeleteDet(false)
        loadAllIngreso();
        // loadDetIngreso(props.ingreso._id)
    }
    const setListOrdenSiembra = async() => {
        // setIngreso({...ingreso, ordenSiembra:''})
        if(stateOS && !ingreso.tipoMovimiento._id && !ingreso.proveedor._id){
            console.log("Busqueda de Orden de SiEMBRA CON LOS SIGUIENTES DATOS", ingreso.tipoMovimiento, ingreso.proveedor)
            const data={
                tipoMovimiento: ingreso.tipoMovimiento,
                proveedor: ingreso.proveedor
            }
            const resOrdenSiembras = await ALMACENESservice.getOrdenSiembrasToIngreso(data);
            if (resOrdenSiembras.data.length>0) {
                setOptOrdenSiembra(resOrdenSiembras.data)
                console.log(resOrdenSiembras)
            }           
        }
    }
    const changeCheckBoxOS = async()=>{
        setIngreso({...ingreso, finalOrdenSiembra: !ingreso.finalOrdenSiembra})
    }
    useEffect(() => {
        
        if (props.show) {
            loadTipoMovimientos();
            if (props.ingreso._id) {
                setIngreso(props.ingreso)
                loadDetIngreso(props.ingreso._id)
            }else{
                setListDetIngreso([])
            }
            console.log(props.ingreso,"INGRESO SELECCIONADO PARA EL FORMULARIO")
        } else {
            resetFormIngreso();            
        }
    }, [props.show])
    useEffect(() => {
        setListOrdenSiembra();
    }, [ingreso.proveedor, ingreso.tipoMovimiento])
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onHide}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {/* {props.ingreso._id ? ('Edicion') : ('Creacion')} Ingreso - {props.idAlmacen?.nombre} */}
                        <div className="d-flex flex-row justify-content-between">
                            <div className="mr-auto p-2">
                                {props.ingreso._id ? ('Edicion') : ('Creacion')} Salida #{ingreso.numero} - {props.idAlmacen?.nombre}
                            </div>
                            <div className="p-2 text-primary">
                                {ingreso.estado}
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form onSubmit={handleSubmitIngreso}>
                            <fieldset disabled={stateFormIngreso}>
                                <FormContainer align={'flex-start'}>
                                    {/* <ContainerInput size={'200px'}>
                                        <Label htmlFor='estado'> Estado</Label>
                                        <Input type='text' id='estado' name='estado' bg={'#21332636'}
                                            value={ingreso.estado}
                                            readOnly
                                            required
                                        />
                                    </ContainerInput>
                                    <ContainerInput size={'150px'}>
                                        <Label htmlFor='numero'>Numero Ingreso</Label>
                                        <Input type='number' id='numero' name='numero' bg={'#21332636'}
                                            value={ingreso.numero}
                                            readOnly
                                            required />

                                    </ContainerInput> */}
                                    <ContainerInput size={'350px'}>
                                        <Label htmlFor='tipoMovimiento'>Tipo Movimiento</Label>
                                        <Select id='tipoMovimiento' name='tipoMovimiento' bg={'#fff'}
                                            onChange={handleChangeIngreso}
                                            required
                                        >
                                            {ingreso._id ? (<Option value={ingreso.tipoMovimiento._id}>{ingreso.tipoMovimiento.descripcion}</Option>) : (<Option value="">SELECCIONE</Option>)}
                                            {tipoMovimientos.map((movimientos) => {
                                                return <Option key={movimientos._id} value={movimientos._id}>{movimientos.descripcion}</Option>
                                            })
                                            }
                                        </Select>

                                    </ContainerInput>
                                    <ContainerInput size={'100px'}>
                                        <Label htmlFor='busquedaProv'>Busq. Prov</Label>
                                        <Input type='text' id='busquedaProv' name='busquedaProv' bg={'#fff'}
                                            onKeyPress={busquedaProveedor}
                                        />
                                    </ContainerInput>
                                    <ContainerInput size={'250px'}>
                                        <Label htmlFor='proveedor'>Proveedor</Label>
                                        <Select id='proveedor' name='proveedor' bg={'#fff'}
                                            
                                            onChange={handleChangeIngreso}
                                            required>
                                            {ingreso.proveedor?._id ? (<Option value={ingreso.proveedor._id}>{ingreso.proveedor.nombre}</Option>) : (<Option value="">SELECCIONE</Option>)}
                                            {optProveedores.map((proveedores) => {
                                                return <Option key={proveedores._id} value={proveedores._id}>{proveedores.nombre}, RUC:{proveedores.numeroDocumento}</Option>
                                            })
                                            }
                                        </Select>
                                    </ContainerInput>
                                    <ContainerInput size={'250px'}>
                                        <Label htmlFor='ordenSiembra'>Orden Siembra</Label>
                                        <Select id='ordenSiembra' name='ordenSiembra' 
                                            bg={stateOS?('#fff'):('#21332636')}                                            
                                            onChange={handleChangeIngreso}
                                            disabled={!stateOS}
                                            required={stateOS}>                                                
                                            
                                            {ingreso.ordenSiembra ? (<Option value={ingreso.ordenSiembra}>{ingreso.ordenSiembra}</Option>) : (<Option value="">SELECCIONE</Option>)}
                                            {optOrdenSiembra.map((ordenSiembras) => {
                                                return <Option key={ordenSiembras.ordenSiembra} value={ordenSiembras.ordenSiembra}>{ordenSiembras.ordenSiembra}</Option>
                                            })
                                            }
                                        </Select>
                                    </ContainerInput>
                                    <ContainerInput size={'50px'}>
                                        <Label htmlFor='finalOrdenSiembra'>Fin OS</Label>
                                        <CheckBox type='checkbox' id='finalOrdenSiembra' name='finalOrdenSiembra' 
                                        disabled={!stateOS}
                                        onClick={changeCheckBoxOS}
                                        checked={ingreso.finalOrdenSiembra}
                                        />

                                    </ContainerInput>
                                    
                                    <ContainerInput size={'300px'}  >
                                        <Label htmlFor='almacenOrigen'>Almacen Origen</Label>
                                        <Select id='almacenOrigen' name='almacenOrigen' 
                                        bg={'#21332636'}
                                        
                                            disabled
                                            required 
                                            onChange={handleChangeIngreso}>
                                            {ingreso.almacenOrigen?._id ? (<Option value={ingreso.almacenOrigen._id}>{ingreso.almacenOrigen.nombre}</Option>) : (<Option value="">SELECCIONE</Option>)}
                                            
                                        </Select>
                                    </ContainerInput>
                                    <ContainerInput size={'300px'} >
                                        <Label htmlFor='almacenDestino'>Almacen Destino</Label>
                                        <Select id='almacenDestino' name='almacenDestino' bg={'#fff'}
                                            onChange={handleChangeIngreso}
                                            required
                                        >
                                            {ingreso.almacenDestino._id ? (<Option value={ingreso.almacenDestino._id}>{ingreso.almacenDestino.nombre}</Option>) : (<Option value="">SELECCIONE</Option>)}
                                            <Option value={props.idAlmacen?._id} >{props.idAlmacen?.nombre}</Option>
                                        </Select>
                                    </ContainerInput>

                                    <ContainerInput size={'250px'}>
                                        <Label htmlFor='fechaIngreso'>Fecha Ingreso</Label>
                                        <Input type='date' id='fechaIngreso' name='fechaIngreso' bg={'#fff'}
                                            value={moment(ingreso.fechaIngreso).format('YYYY-MM-DD')}
                                            onChange={handleChangeIngreso}
                                            required />

                                    </ContainerInput>
                                    <ContainerInput size={'200px'}>
                                        <Label htmlFor='guiaRecepcion'>Guia Recepcion</Label>
                                        <Input type='text' id='guiaRecepcion' name='guiaRecepcion' bg={'#fff'}
                                            value={ingreso.guiaRecepcion}
                                            onChange={handleChangeIngreso}
                                            required />

                                    </ContainerInput>
                                    {/* <ContainerInput size={'100px'}>
                                    <Label htmlFor='busquedaRef'>Busq. Ref</Label>
                                    <Input type='number' id='busquedaRef' bg={'#fff'} />
                                </ContainerInput>
                                <ContainerInput size={'150px'}>
                                    <Label htmlFor='referencia'>Referencia</Label>
                                    <Input type='number' id='referencia' bg={'#fff'} readOnly={true} />
                                </ContainerInput> */}

                                    <ContainerInput size={'200px'}>
                                        <Label htmlFor='subTotal'>Sub Total</Label>
                                        <Input type='number' step='any' id='subTotal' name='subTotal' bg={'#21332636'}
                                            value={ingreso.subtotal}
                                            required
                                            readOnly
                                        />

                                    </ContainerInput>
                                    <ContainerInput size={'200px'}>
                                        <Label htmlFor='igvTotal'>Igv Total</Label>
                                        <Input type='number' id='igvTotal' name='igvTotal' bg={'#21332636'}
                                            value={ingreso.igvTotal}
                                            required
                                            readOnly
                                        />

                                    </ContainerInput>
                                    <ContainerInput size={'200px'}>
                                        <Label htmlFor='total'>Total</Label>
                                        <Input type='number' id='total' name='total' bg={'#21332636'}
                                            value={ingreso.total}
                                            required
                                            readOnly
                                        />

                                    </ContainerInput>


                                </FormContainer>
                                <FormContainer align={'center'}>
                                    <ContainerInput size={'200px'}>
                                        <ButtonForm>{ingreso._id ? ('EDITAR') : ('CREAR')}</ButtonForm>
                                    </ContainerInput>
                                    {ingreso._id ? (
                                        <>
                                            <ContainerInput size={'200px'}>
                                                <SpanOptForm color={'#6d6d6d'} onClick={anularIngreso}>Anular</SpanOptForm>                                                
                                            </ContainerInput>
                                            <ContainerInput size={'200px'}>
                                                <SpanOptForm color={'#970000'} onClick={eliminarIngreso}>Eliminar</SpanOptForm>
                                            </ContainerInput>
                                        </>
                                    ) : ('')}

                                </FormContainer>
                            </fieldset>
                        </Form>

                        <div className="row container">
                            <h4 className="mt-3">Agregar Detalle</h4>
                            <SPAN onClick={addDetalleIngreso}>+</SPAN>
                        </div>
                        <TableContainer className="table-responsive table-striped col-12 mx-auto shadow mb-5 bg-body rounded trheight tableFixHead">
                            <Table >
                                <THead>
                                    <TR>
                                        <TH>#</TH>
                                        <TH>ESTADO</TH>
                                        <TH >PRODUCTO</TH>
                                        <TH>UM</TH>
                                        <TH>CANTIDAD</TH>
                                        <TH>CANT. DISPONIBLE</TH>
                                        <TH >SUBTOTAL</TH>
                                        <TH >IGV</TH>
                                        <TH >TOTAL</TH>
                                        <TH >ORDEN COMPRA</TH>
                                        <TH >ORDEN SIEMBRA</TH>
                                        <TH >DETALLE</TH>
                                        <TH >NUMERO INGRESO</TH>
                                        <TH >OPCIONES___</TH>
                                    </TR>
                                </THead>
                                <TBody>

                                    {listDetIngreso.map((detIngreso, index) => {
                                        return <TR id={detIngreso._id} key={detIngreso._id}>
                                            <TD>{index}</TD>
                                            <TD>{detIngreso.estado}</TD>
                                            <TD>{detIngreso.producto.nombre}</TD>
                                            <TD>{detIngreso.unidadMedida}</TD>
                                            <TD>{detIngreso.cantidad}</TD>
                                            <TD>{detIngreso.cantidadDisponible}</TD>
                                            <TD>{detIngreso.precio}</TD>
                                            <TD>{detIngreso.igv}</TD>
                                            <TD>{detIngreso.total}</TD>
                                            <TD>{detIngreso.ordenCompra}</TD>
                                            <TD>{detIngreso.ordenSiembra}</TD>
                                            <TD>{detIngreso.detalle}</TD>
                                            <TD>{detIngreso.numeroIngreso}</TD>
                                            <TD>
                                            <MainContainer align={'center'}>
                                                <I onClick={()=> editDetIngreso(detIngreso._id)} color={'#2b577a'} className="fas fa-edit"></I>
                                                <I onClick={()=> deleteDetalle(detIngreso._id)} color={'#8a2c2c'} className="far fa-trash-alt"></I>
                                            </MainContainer>
                                            </TD>
                                        </TR>
                                    })}

                                </TBody>
                            </Table>
                        </TableContainer>
                    </Container>


                </Modal.Body>
                <Modal.Footer>
                    {/* <FormContainer align={'center'}>
                        <ContainerInput size={'400px'}>
                            <ButtonForm>SALIR</ButtonForm>
                        </ContainerInput>

                    </FormContainer> */}
                </Modal.Footer>
            </Modal>
            <DetIngresos idIngreso={ingreso._id} detalleIngreso={detalleIngreso}  numeroIngreso={ingreso.numero} show={stateModalDetIngreso} onHide={handleCloseModalDet} />
            <DetalleConfirmacion show={modalConfirmDeleteDet} onHide={closeModalConfirmDet} idDetalleToDelete={idToDeleteDet} afterConfirm={closeModalAfterConfirm} />
        </>
    )
}
