import React,{MouseEventHandler, useState, useEffect} from 'react'
import proyCampoImg from '../../../Media/png/proyCampo.png'
import cosechadoImg from '../../../Media/png/cosechado.png'
import { Modal } from 'react-bootstrap';
import { loteToModal, proyeccionToModal } from '../proyecciones'
import {MainContainerModalProy, WrapTitleModalProy} from '../ProyeccionesElements'
import moment from 'moment';
import * as ProyeccionCtrl from '../ProyeccionesService'
import { toast } from 'react-toastify';
// import momentz from 'moment-timezone'
interface Props {
    show: boolean,
    lote: loteToModal,
    idProyeccion: string,
    allProyeccion: proyeccionToModal | undefined,
    numeroProyeccion: number,
    onHide: MouseEventHandler<HTMLElement>|any,
    onHideFun: () => void
}
function ModalSetProyeccion(props:Props) {
    const initialProyCampo=  {id:'', fecha: '', jabas: ''} ;      
    const initialFormCosechado=  {id:'', fechaCosechada: '', jabasCosechado: ''} ; 
    const [stateSubmitBtnProy, setstateSubmitBtnProy] = useState(false)
    const [stateSubmitCosechado, setStateSubmitCosechado] = useState(false)
    const [dataProyCampo, setDataProyCampo] = useState(initialProyCampo)
    const [dataFormCosechado, setDataFormCosechado] = useState(initialFormCosechado)
    // const [stateDesbreve, setStateDesbreve] = useState(false)

    const handleChangeFormProyCampo = (e:any) =>{
        setDataProyCampo({...dataProyCampo, [e.target.name]:e.target.value})
    }
    const handleChangeFormCosechado = (e:any) =>{
        setDataFormCosechado({...dataFormCosechado, [e.target.name]: e.target.value})
    }
    const submitProyCampo = async(e:any)=>{
        e.preventDefault()
        try {
            setstateSubmitBtnProy(true);
            const saveProyeccionCampo = await ProyeccionCtrl.editOneProyeccionFromTable(dataProyCampo)
            if(saveProyeccionCampo.data.ErrorMessage) {
                setstateSubmitBtnProy(false);
                return toast.error(saveProyeccionCampo.data.ErrorMessage)
            }
            toast.success("Editado!")          
            setstateSubmitBtnProy(false);
            props.onHideFun();
        } catch (error:any) {
            toast.error(error)
            setstateSubmitBtnProy(false);
        }
    }
    const submitCosechado = async(e:any) =>{
        e.preventDefault()
        try {
            setStateSubmitCosechado(true);
            const saveCosechado = await ProyeccionCtrl.editProyCosechadoFromTable(dataFormCosechado)
            // console.log(saveCosechado)
            // if(saveCosechado.status === 401) return toast.error("su sesion caduco")
            if(saveCosechado.data.ErrorMessage) {
                setStateSubmitCosechado(false);
                return toast.error(saveCosechado.data.ErrorMessage)
            }
            toast.success("Editado!")            
            setStateSubmitCosechado(false);
            props.onHideFun();
        } catch (error:any) {
            // console.log("error")
            toast.error(error)
            setStateSubmitCosechado(false);
        }
    }
    // useEffect(() => {
    //     if (props.allProyeccion?.numeroProyeccion===1) {
    //         // console.log(dataProyCampo.jabas, props.lote.areaLote, "JABAS Y AREA LOTE")
    //         const jabasXHectarea = parseInt(dataProyCampo.jabas) / props.lote.areaLote
            
    //         if (jabasXHectarea<13.5) {
    //             console.log(jabasXHectarea ,"TOTAL DENTRO DEL IF")
    //             setStateDesbreve(true)
    //         }else if(jabasXHectarea>=13.5){
    //             console.log(jabasXHectarea ,"TOTAL DENTRO DEL IF")
    //             setStateDesbreve(false)
    //         }
    //     }
    // }, [dataProyCampo.jabas])
    useEffect(() => {
        if (props.show) {
            // console.log(props.allProyeccion, "TODA LA PROY")
            setDataProyCampo({
                ...dataProyCampo, 
                id:props.idProyeccion, 
                fecha: props.allProyeccion?.fechaProyeccionCampo ? moment(props.allProyeccion?.fechaProyeccionCampo).format("YYYY-MM-DD"):'',
                jabas: props.allProyeccion?.jabasProyeccionCampo ? props.allProyeccion?.jabasProyeccionCampo.toString() :''})
            setDataFormCosechado({
                ...dataFormCosechado,
                id: props.idProyeccion
            })
        }else{
            // setStateDesbreve(false)
            setDataProyCampo(initialProyCampo)
            setDataFormCosechado(initialFormCosechado)
            setstateSubmitBtnProy(false)
            setStateSubmitCosechado(false)
        }
    }, [props.show])
    return (
        <div>
             <Modal
             
             show={props.show}
             onHide={props.onHide}
             size="lg"
             aria-labelledby="contained-modal-title-vcenter"
             centered
             >
             <Modal.Header closeButton>
             
             </Modal.Header>
             <Modal.Body>
                 <WrapTitleModalProy>
                     <h3  id="agricultor">
                     {props.lote.agricultor}
                     </h3>
                     <h5>
                     {props.lote.numeroContrato } &nbsp; &nbsp; &nbsp;{props.lote.areaLote} Hec
                    </h5>   
                     <h3 id="numProy">
                     #{props.numeroProyeccion}
                     </h3>
                     
                 </WrapTitleModalProy>
                <MainContainerModalProy>
                    <div id="proyeccionCampo">                        
                        <img src={proyCampoImg} alt=""/>
                        <h3>Proyeccion Campo</h3>
                        <form action="" onSubmit={submitProyCampo}>
                            
                            <label htmlFor="">Fecha</label>
                            <input type="date" 
                                name='fecha' 
                                onChange={handleChangeFormProyCampo}
                                value={dataProyCampo.fecha}
                                required
                            />
                            <label htmlFor="">Jabas</label>
                            <input type="number" 
                                name='jabas' 
                                onChange={handleChangeFormProyCampo}
                                value={dataProyCampo.jabas}
                                required
                            />
                            <button disabled={stateSubmitBtnProy}>
                            {stateSubmitBtnProy?
                                <div className="spinner-border text-ligth" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>:
                                'Guardar Proyeccion'
                                }                                                               
                            </button>
                            
                        </form>
                    </div>
                    {props.allProyeccion?.fechaProyeccionCampo ? 
                        <div id="cosechado">                        
                        <img src={cosechadoImg} alt=""/>                        
                        <h3>Cosechado</h3>
                        <form action="" onSubmit={submitCosechado}>
                            <label htmlFor="">Fecha</label>
                            <input type="date"
                                name='fechaCosechada' 
                                onChange={handleChangeFormCosechado}
                                value={dataFormCosechado.fechaCosechada}
                                required
                            />
                            <label htmlFor="">Jabas</label>
                            <input type="number"
                                name='jabasCosechado' 
                                onChange={handleChangeFormCosechado}
                                value={dataFormCosechado.jabasCosechado}
                                required
                            />
                            <button disabled={stateSubmitCosechado}>
                                {stateSubmitCosechado?
                                    <div className="spinner-border text-ligth" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>:
                                  "Guardar Cosecha"
                                }                                                               
                            </button>
                            
                        </form>
                    </div>
                    : ''
                    }
                    
                </MainContainerModalProy>
             </Modal.Body>
             <Modal.Footer>
             </Modal.Footer>
             </Modal>
        </div>
    )
}

export default ModalSetProyeccion
