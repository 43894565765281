import moment from 'moment';
import React,{useEffect, useState, ChangeEvent} from 'react'
import { toast } from 'react-toastify';
import * as CalidadProduccionServices from '../CalidadProduccionServices'
import { rangoFechasPrint, listInputs, currentInput} from '../typesCalidadProduccion'
import ModalInputRegMas from './ModalInputRegMas'

type InputChange = ChangeEvent<HTMLInputElement | HTMLSelectElement>;

function InputRegMas() {
    const initialCurrentInput = {
        _id: '',
        nameInput: '',
        uMedida:{
            nombre: ''
        },
        idSubCatInput:{
            nombre: ''
        },
        categoria:{
            _id: '',
            nombre: ''
        }
    }
    const [listInputs, setListInputs] = useState<listInputs[]>([]);
    const [busquedaListInputs, setBusquedaListInputs] = useState<listInputs[]>([]);
    const [rangoFechas, setRangoFechas] = useState({ fechaInicio: '', fechaFin: ''})
    const [arrayFechas, setArrayFechas] = useState<rangoFechasPrint[]>([])
    const [stateModalReg, setStateModalReg] = useState(false)
    const [currentInput, setCurrentInput] = useState<currentInput>(initialCurrentInput);
    const [controlSubmit, setControlSubmit] = useState(false)
    const [textBusqueda, setTextBusqueda] = useState('');
    const hideModalReg = () =>{
        setStateModalReg(false)        
    }
    const openModalReg = (id: string) =>{
        const findInput = listInputs.find((input)=> input._id ===  id)
      
        if (findInput) {
            setCurrentInput(findInput)
            setStateModalReg(true);

        }else{
            toast.error("Error al encontrar registro")
        }
        // setCurrentInput(findInput)
        
    }
    const changeControlSubmit =()=>{
        setControlSubmit(true)
    }
    const changeFechas = (e: InputChange)=>{
        setRangoFechas({...rangoFechas, [e.target.name]: e.target.value})        
    }

    const afterHidenModal = ()=>{
       
        setStateModalReg(false) ;
    }

    const getRegInputs = async()=>{
        if (!rangoFechas.fechaFin || !rangoFechas.fechaInicio) return toast.warning('Seleccione rango de fechas');
        const resRegInputsByFecha = await CalidadProduccionServices.getInputsRegByFecha(rangoFechas);
        setArrayFechas(resRegInputsByFecha.data.arrayFechas);
        // setListInputs(resRegInputsByFecha.data.allInputs); 
        setBusquedaListInputs(resRegInputsByFecha.data.allInputs);   
        //**Pintando Inputs con filter */
        var resultadoBusqueda = resRegInputsByFecha.data.allInputs.filter((input:listInputs) => {
            if (input.nameInput.toString().toLowerCase().includes(textBusqueda.toLowerCase()) || 
            input.idSubCatInput.nombre.toString().toLowerCase().includes(textBusqueda.toLowerCase()) ||
            input.categoria.nombre.toString().toLowerCase().includes(textBusqueda.toLowerCase())) {
                return input
            }
        })
        setListInputs(resultadoBusqueda)
    }
    const changeBusquedaInput = (e: InputChange) => {
        setTextBusqueda(e.target.value);
        filterInput(e.target.value)
    }
    const filterInput = (terminoBusqueda: string) => {
        var resultadoBusqueda = busquedaListInputs.filter((input) => {
            if (input.nameInput.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) || 
            input.idSubCatInput.nombre.toString().toLowerCase().includes(terminoBusqueda.toLowerCase()) ||
            input.categoria.nombre.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())) {
                return input
            }
        })
        setListInputs(resultadoBusqueda)
    }
    
    useEffect(() => {        
        const fechaActual = moment(new Date()).format('YYYY-MM-DD')
        setRangoFechas({fechaInicio: fechaActual, fechaFin:fechaActual})
    }, [])
    useEffect(() => {
        if (controlSubmit) {
            console.log(" INGRESANDO AL ACTUALIZADOR")
            getRegInputs();             
        }
    }, [stateModalReg])
    return (
        <div>
              <div className="text-center col-12 col-sm-4 mx-auto p-3">
                <h3><strong>Registro Masivo de Inputs</strong> </h3>
            </div>
             <div className="d-flex flex-wrap justify-content-center align-items-end">
                <div className="p-2">
                    <label htmlFor=""  className="text-dark">Fecha Inicio</label>
                    <input className="form-control form-control-lg border-dark" name="fechaInicio" type="date" 
                        max={rangoFechas.fechaFin}
                        onChange={changeFechas}
                        value={rangoFechas.fechaInicio}
                    />
                </div>    
                <div className="p-2">
                    <label htmlFor=""  className="text-dark">Fecha fin</label>
                    <input className="form-control form-control-lg border-dark" name='fechaFin' type="date" 
                        min={rangoFechas.fechaInicio}
                        onChange={changeFechas}
                        value={rangoFechas.fechaFin}
                    />
                </div> 
                <div className="p-2">
                    <button className="btn btn-outline-dark btn-lg"
                        onClick={getRegInputs}
                        >
                        <i className="fas fa-search"></i>
                    </button>
                </div>
            </div>
            <div className="col-12 col-sm-6 mx-auto">
                <div className="p-2">
                    <input className="form-control form-control-lg " placeholder="Busqueda Input" type="text" name="busquedaInput" id="busquedaInput"
                        onChange={changeBusquedaInput} 
                    />
                </div>
            </div>
            <div className="table-responsive mb-5 ">
                <table className="tableFixedColum mx-auto">
                    <thead>
                        <tr>
                            <th scope="col">CATEGORIA</th>                            
                            <th scope="col">NOMBRE INPUT</th>
                            {arrayFechas.map((fechas)=>{
                                return <th key={fechas.id} style={{ minWidth: '100px', maxWidth:'150px' }}> {fechas.fecha} </th>
                            })}
                               
                            </tr>
                    </thead>
                    <tbody>
                        {listInputs.map((inputs)=>{
                            return(
                                <tr key={inputs._id}>
                                    <td><span style={{fontSize:'12px'}}>{inputs.categoria.nombre}</span> <br/>
                                    <span style={{fontSize:'12px'}}>{inputs.idSubCatInput.nombre}</span>
                                    </td>
                                    <td onClick={()=>openModalReg(inputs._id)}>{inputs.nameInput} <br/>
                                        <span className='text-info' style={{fontSize:'12px', textTransform:'lowercase'}}>{inputs.uMedida.nombre}</span>
                                    </td>
                                    {inputs.registros.map((registro) =>{
                                        if (registro >= 0) {
                                            return <td className="text-dark font-weight-bold h5">{registro}</td>
                                        } else {
                                            return <td >{registro}</td>
                                        }
                                    })}
                                </tr>
                                
                            )
                        })}
                        
                    </tbody>
                </table>
            </div>
            <ModalInputRegMas show={stateModalReg} onHide={hideModalReg} input={currentInput} afterHidenModal={afterHidenModal} changeControlSubmit={changeControlSubmit}/>
        </div>
    )
}

export default InputRegMas
