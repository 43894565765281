import styled from 'styled-components'

export const PruebaContariner = styled.div`
   
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: 100px;

`

export const AlmacenContainer = styled.div`
    color: #fff;
    /* 
    background: #010606; */
    border: 1px solid #fff;
    display: grid;
    margin: auto;
    width: 100%;
    height: 100vw;
    grid-gap: 10px;
    grid-template-areas: 
    "ZonaA"
    "ZonaB" 
    "ZonaC"
    "ZonaC1"
    "ZonaD" 
    "Pasadizo2"    
    "Pasadizo1" 
    "Oficinas" 
    "Paletizado" 
    "ZonaRightC1"
    "ZonaTopC1"
    "ZonaEmpaque"
    "ZonaTopA"
    "ZonaTopC"
    "ZonaTopB"
    ;
    grid-template-columns: 100% ;
    & > * {
        background-color:#e6e6e6;
        color: #010625;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #010625;
    } 
   
   @media screen and (min-width: 500px){
       padding: 0px;
       /* grid-template-areas:
       "ZonaD Pasadizo2 ZonaTopC ZonaTopB Pasadizo1 ZonaTopA ZonaTopA" 
       "ZonaD Pasadizo2 ZonaC ZonaTopB Pasadizo1 ZonaTopA ZonaTopA" 
       "ZonaD Pasadizo2 ZonaEmpaque ZonaB Pasadizo1 ZonaA ZonaA" 
       "Oficinas Pasadizo2 Paletizado Paletizado Pasadizo1 ZonaTopC1 ZonaTopC1"
       "Oficinas Pasadizo2 Paletizado Paletizado Pasadizo1 ZonaC1 ZonaRightC1" ;
       grid-template-columns: 1fr 0.2fr 1.3fr 0.4fr 0.2fr 0.3fr 0.5fr;
       grid-template-rows: 0.2fr 0.1fr 2.2fr 0.2fr 1fr; */
       grid-template-areas:
       "ZonaTopD Pasadizo2 ZonaC ZonaTopB Pasadizo1 ZonaTopA ZonaTopA"
       "ZonaD Pasadizo2 ZonaC ZonaTopB Pasadizo1 ZonaTopA ZonaTopA" 
       "ZonaD Pasadizo2 ZonaC ZonaTopB Pasadizo1 ZonaTopA ZonaTopA" 
       "ZonaD Pasadizo2 ZonaEmpaque ZonaB Pasadizo1 ZonaA ZonaA" 
       "Oficinas Pasadizo2 Paletizado Paletizado Pasadizo1 ZonaTopC1 ZonaTopC1"
       "Oficinas Pasadizo2 Paletizado Paletizado Pasadizo1 ZonaC1 ZonaRightC1" ;
       grid-template-columns: 1fr 0.2fr 1.3fr 0.4fr 0.2fr 0.3fr 0.5fr;
       grid-template-rows: 0.2fr 0.2fr 0.5fr 2.2fr  0.2fr 1fr;
   }
`
export const AlmacenZonaRow = styled.div`
    display: grid;
    grid-auto-columns: minmax( 1fr);
    align-items: center;
`
// NOMBAR AREAS

export const  ZonaD = styled.div`
   grid-area: ZonaD;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 1px;
    grid-auto-rows: auto;
    color: #e6e6e6;
`
export const  Pasadizo2 = styled.div`
    grid-area: Pasadizo2;
    background-color:#ffffff;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  ZonaC = styled.div`
   grid-area: ZonaC;
    display: grid;
    grid-template-columns: repeat(13, 1fr);
    grid-gap: 1px;
    grid-auto-rows: auto;
    color: #e6e6e6;
`
export const  ZonaB = styled.div`
    grid-area: ZonaB;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1px;
    grid-auto-rows: auto;
    color: #e6e6e6;
`
export const  Pasadizo1 = styled.div`
    grid-area: Pasadizo1;
    background-color:#ffffff;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  ZonaA = styled.div`
   
    grid-area: ZonaA;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 1px;
    grid-auto-rows: auto;
    color: #e6e6e6;

`
export const  PalletZonaA1 = styled.div` 
    height:5.2vh;
    background: #010609;

    
`
export const  Oficinas = styled.div`
    grid-area: Oficinas;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  Paletizado = styled.div`
    grid-area: Paletizado;
    background: #476397;
    color: #eee;
    font-size: 30px;
    cursor: pointer;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  ZonaC1 = styled.div`
   grid-area: ZonaC1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1px;
    grid-auto-rows: auto;
    color: #e6e6e6;
`
export const  ZonaEmpaque = styled.div`
    grid-area: ZonaEmpaque;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  ZonaTopA = styled.div`
    
    grid-area: ZonaTopA;
 
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  ZonaTopB = styled.div`
    grid-area: ZonaTopB;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  ZonaTopC1 = styled.div`
    grid-area: ZonaTopC1;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  ZonaTopC = styled.div`
    grid-area: ZonaTopC;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  ZonaTopD = styled.div`
    grid-area: ZonaTopD;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const  ZonaRightC1 = styled.div`
    grid-area: ZonaRightC1;
    @media screen and (max-width: 768px){
       font-size: 10px
   }
`
export const TitleH1 = styled.h1`
    padding-top: 20px;
    margin: auto;
    color: #010101;
    text-align: center;
`
export const BtnBusqueda = styled.button`
    border-radius: 8px;
    border: 2px solid #125c03;
    background: #ffffff;
    padding: 0.6em 0.8em;
    margin: 10px;
    font-family: monospace;
    font-weight: bold;
    color: #757575;

   @media screen and (max-width: 750px){
       width: 100%;       
       margin: 10px;
   }

    &:focus{
        transform: scale(1.09);
        transition: ease-in-out;
    }
`