import React, { useState, useEffect } from 'react'
import { Data } from './Data'
import { Pallets, Bases } from './almacen'
import { bases, formatos } from '../ModalBox/boxModal'
import ModalBox from '../ModalBox/ModalBox'
import ModalPaletizado from '../ModalPaletizado/ModalPaletizado'
import ModalSalida from '../ModalSalida/ModalSalida'
import * as almacenesServices from './AlmacenMPServices'
import IndexMapas from '../IndexMapas/IndexMapas'

import {
    AlmacenContainer,
    ZonaA,
    ZonaB,
    ZonaC,
    ZonaC1,
    ZonaD,
    Paletizado,
    Pasadizo1,
    Pasadizo2,
    Oficinas,
    ZonaEmpaque,
    ZonaTopA,
    ZonaTopB,
    ZonaTopC1,
    ZonaRightC1,
    TitleH1,
    // ZonaTopC,
    ZonaTopD,
    // PalletZonaA1,
    // PruebaContariner,
    BtnBusqueda
} from './AlmacenMPElements'
import BoxMP from '../BoxMP/BoxMP'
import { TooltipTarget } from '../BoxMP/BoxMPElements'
import { toast } from 'react-toastify'
function AlmacenMP() {
    const [baseOrigen, setBaseOrigen] = useState("")
    const [baseDestino, setBaseDestino] = useState("")
    const [baseVacia, setBaseVacia] = useState<Bases>({_id:'',index: 0, matris: '', estado: '', zona: {_id:''}, pallets: '', busqueda: false})
    const palletDesde = (baseInicial: any, baseFinal: any) => {  
        setBaseOrigen(baseInicial)
        setBaseDestino(baseFinal);
        const busquedaPalletOrigen: any = bases.findIndex((base) => base._id === baseInicial);
        const busquedaPalletDestino: any = bases.findIndex((base) => base._id === baseFinal);
        let copyBases = [...bases];
        let baseDestino = { ...copyBases[busquedaPalletDestino] }
        let baseAMover = { ...copyBases[busquedaPalletOrigen] }
        if(baseDestino.pallets.length===0){
            //No hacer nada adicional
            baseDestino.pallets = baseAMover.pallets
        }else{
            let niveles = baseDestino.pallets.length;
            for(let i= 0; i<baseAMover.pallets.length;i++){
                let palletTemp = baseAMover.pallets[i]
                palletTemp.nivel = palletTemp.nivel + niveles
                baseDestino.pallets.push(palletTemp)
                //niveles = baseDestino.pallets[i].nivel;
            }          
        }
        
        baseAMover.pallets = []
        copyBases[busquedaPalletDestino] = baseDestino;
        copyBases[busquedaPalletOrigen] = baseAMover;
        setBases(copyBases)
    }
    
    const palletHasta = (base: any) => {  
        //No hacer Nada
        //setmodalBox({ ...modalBox, estadoModal: true, base: base });
    }
    
    const [Info, setInfo] = useState<Pallets[]>([])
    const [modalBox, setmodalBox] = useState({ estadoModal: false, base: [] })
    const [bases, setBases] = useState<Bases[]>([])
    const [palletBuscado, setPalletBuscado] = useState({ codigoPallet: '' })
    const [textSearchFormat, setTextSearchFormat] = useState('')
    const [listFormatos, setListFormatos] = useState<formatos[]>([])
    const [formatoSelected, setFormatoSelected] = useState('')
    const [modalPaletizado, setModalPaletizado] = useState(false);
    const [flagState, setFlagState] = useState(false)
    //Modal Salida
    const [stateModalSalida, setStateModalSalida] = useState(false)
    


    const changeFlagState = () =>{
        setFlagState(true)
    }
    const openModalSalida = () =>{
        setStateModalSalida(true);
    }
    const closeModalSalida = () =>{
        if (flagState) {
            selectBases(); 
        }        
        setStateModalSalida(false);
    }
    const handleCloseModal = () => {
        if (flagState) {
            selectBases(); 
        }             
        setmodalBox({ ...modalBox, estadoModal: false, base: [] })
    };
    const handleShowModal = (base: any) => {  
        setFlagState(false)    
        setmodalBox({ ...modalBox, estadoModal: true, base: base });
    }
    

    const selectBases = async () => {
        const idAlmacen = '61534152431a5c10acf19d9e';
        const resBases = await almacenesServices.getBases(idAlmacen);
        //console.log(resBases.data)
        setBases(resBases.data)        
    }
    const setBusquedaPallet = async (e: any) => {
        setPalletBuscado({ codigoPallet: e.target.value })
    }
    const BuscarPallet = async () => {
        try {
            // console.log(palletBuscado.codigoPallet)
            const textBusqueda = palletBuscado.codigoPallet;
            if(textBusqueda && formatoSelected) return toast.info("Por favor busque un campo a la vez")
            if (textBusqueda) {
                const resBase = await almacenesServices.busquedaBaseByNumPallet(textBusqueda)
                // console.log(resBase.data[0])            
                if (resBase.data[0]._id) {
                    const resIDBase = resBase.data[0]._id;
                    const busquedaPalletByBase: any = bases.find((base) => base._id === resIDBase);
                    const busquedaPalletByBaseIndex: any = bases.findIndex((base) => base._id === resIDBase);
                    let copyBases = [...bases];
                    let copyBase = { ...copyBases[busquedaPalletByBaseIndex] }
                    copyBase.busqueda = true;
                    copyBases[busquedaPalletByBaseIndex] = copyBase;
                    setBases(copyBases)
                    return toast.success("Pallet Encontrado")
                }else {
                    return toast.error("Pallet no encontrado")
                }
            }else{
                const dataToSearch = {
                    formato: formatoSelected,
                    almacen: '61534152431a5c10acf19d9e'
                }
                const resBase = await almacenesServices.busquedaBaseByFormato(dataToSearch)
                
                var copyBases = [...bases];
                for (let i = 0; i < resBase.data.base.length; i++) {
                    const oneIdBase = resBase.data.base[i];
                    console.log(oneIdBase.base._id,"dentro del for")
                    const busquedaPalletByBaseIndex: any = bases.findIndex((base) => base._id === oneIdBase.base._id);
                    
                    let copyBase = { ...copyBases[busquedaPalletByBaseIndex] }
                    copyBase.busqueda = true;
                    copyBases[busquedaPalletByBaseIndex] = copyBase;
                    
                }
                setBases(copyBases)
                toast.success('Produccion total '+ resBase.data.totalProduccion)
            }

            
        } catch (error:any) {
            console.log("ERROR FRONT")
            toast.error(error.message)
        }
        //    base[busquedaPalletByBaseIndex] = {...base[busquedaPalletByBaseIndex]}
    }
    const handleChangeFormato = (e:any) => {
        setFormatoSelected(e.target.value)
    }
    const ChangeTextSearchFormat = (e:any) =>{
        setTextSearchFormat(e.target.value)
    }
    const busquedaTextFormato = async() =>{
        console.log(textSearchFormat);          
        if(!textSearchFormat) {
            setFormatoSelected('')
            setListFormatos([])
        }else{
            const resBusquedaFormato = await almacenesServices.busquedaFormato(textSearchFormat)
            setListFormatos(resBusquedaFormato.data);
        } 
    }
    const handleModalPaletizado = async() =>{
        setModalPaletizado(true);
    }
    const handleCloseModalPaletizado = ()=>{
        setModalPaletizado(false)
        selectBases();
    }
    useEffect(() => {
        setInfo(Data);
        selectBases();
    }, [])
    return (
        <>
            <IndexMapas />

            <TitleH1>ALSUR PERU</TitleH1>
            <div className=" row align-items-end justify-content-center m-1">
            <div className="col-8 col-md-2">

                <input type="text" className="form-control form-control-lg" name="bformato"
                    onChange={ChangeTextSearchFormat}
                    value={textSearchFormat}
                />
                </div>
                {/* <span className="col-4 btn btn-block btn-dark "><i className="fas fa-search"></i></span> */}
                <div className="col-4 col-md-1">
                <span className=" btn btn-lg btn-block btn-dark" onClick={busquedaTextFormato} ><i className="fas fa-search"></i></span>
                </div>
                <div className="col-12 col-md-2">
                    
                    <label htmlFor="formato">Codigo - Formato</label>
                    <select className="form-control " name="formato" onChange={handleChangeFormato} required >
                       
                            {listFormatos ? (<><option value="" selected>Seleccione</option></>):(<>  </>)}
                        
                        {listFormatos.map((formato) => {
                            return (
                                <option value={formato._id} >{formato.codigo} - {formato.descripcion}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="col-8 col-md-2 ">
                    <input className="form-control form-control-lg text-center" type="text" value={palletBuscado.codigoPallet} onChange={setBusquedaPallet} placeholder="Numero Pallet" />
                </div>
                <div className=" col-md-auto">
                    <BtnBusqueda onClick={BuscarPallet} > Buscar</BtnBusqueda>
                </div>
                <div className="col-12 col-md-2">
                <span className=" btn btn-lg btn-block btn-warning text-center" onClick={openModalSalida}  ><span className="h3">+</span> Salida</span>
                </div>
            </div>
            <AlmacenContainer>
                <ZonaTopA>Zona Top A</ZonaTopA>
                <ZonaTopB>ZonaTopB</ZonaTopB>
                <ZonaD>
                    {bases.map((base) => {
                        if (base.zona._id === '615346832be0413ee84684cb') {
                            return (
                                <BoxMP idPallet={base._id} palletFrom={palletDesde} palletTo={palletHasta}   matriz={base.matris}  text={`${base.pallets.map((pallet: any) => { return ('Nivel ' + pallet.nivel + ':' + pallet.numPallet + ' ') })} `} position="top">
                                    <TooltipTarget
                                        onClick={() => { handleShowModal(base) }}
                                        colorBG={base.pallets.length}
                                        buscado={base.busqueda}
                                        idPallet={base._id}
                                    >D <br /> {base.matris}
                                    </TooltipTarget>
                                </BoxMP>
                            )
                        }

                    })}
                </ZonaD>
                <Pasadizo2>Pasadizo 2</Pasadizo2>
                <ZonaEmpaque>Zona Empaque</ZonaEmpaque>
                
                <ZonaC>
                    {bases.map((base) => {
                        if (base.zona._id === '615346832be0413ee84684c9') {
                            return (
                                <BoxMP idPallet={base._id} palletFrom={palletDesde} palletTo={palletHasta}   matriz={base.matris}  text={`${base.pallets.map((pallet: any) => { return ('Nivel ' + pallet.nivel + ':' + pallet.numPallet + ' ') })} `} position="top">
                                    <TooltipTarget
                                        onClick={() => { handleShowModal(base) }}
                                        colorBG={base.pallets.length}
                                        buscado={base.busqueda}
                                        idPallet={base._id}
                                    >C <br /> {base.matris}
                                    </TooltipTarget>
                                </BoxMP>
                            )
                        }

                    })}
                </ZonaC>
                <ZonaB>
                    {bases.map((base) => {
                        if (base.zona._id === '615346832be0413ee84684c8') {
                            return (
                                <BoxMP idPallet={base._id} palletFrom={palletDesde} palletTo={palletHasta}   matriz={base.matris}  text={`${base.pallets.map((pallet: any) => { return ('Nivel ' + pallet.nivel + ':' + pallet.numPallet + ' ') })} `} position="top">
                                    <TooltipTarget
                                        onClick={() => { handleShowModal(base) }}
                                        colorBG={base.pallets.length}
                                        buscado={base.busqueda}
                                        idPallet={base._id}
                                    >B <br /> {base.matris}
                                    </TooltipTarget>
                                </BoxMP>
                            )
                        }

                    })}
                </ZonaB>
                <Pasadizo1>Pasadizo 1</Pasadizo1>

                <ZonaA>

                    {bases.map((base) => {
                        if (base.zona._id === '615346832be0413ee84684c7') {
                            return (
                                <BoxMP idPallet={base._id} palletFrom={palletDesde} palletTo={palletHasta}   matriz={base.matris}  text={`${base.pallets.map((pallet: any) => { return ('Nivel ' + pallet.nivel + ':' + pallet.numPallet + ' ') })} `} position="top">
                                    <TooltipTarget
                                        onClick={() => { handleShowModal(base) }}
                                        colorBG={base.pallets.length}
                                        buscado={base.busqueda}
                                        idPallet={base._id}
                                    >A <br /> {base.matris}
                                    </TooltipTarget>
                                </BoxMP>
                            )
                        }

                    })}
                </ZonaA>
                <Oficinas>Oficinas</Oficinas>
                <Paletizado
                    onClick={handleModalPaletizado}>
                    Paletizado
                </Paletizado>
                <ZonaC1>
                    {bases.map((base) => {
                        if (base.zona._id === '615346832be0413ee84684ca') {
                            return (
                                <BoxMP idPallet={base._id} palletFrom={palletDesde} palletTo={palletHasta}   matriz={base.matris}  text={`${base.pallets.map((pallet: any) => { return ('Nivel ' + pallet.nivel + ':' + pallet.numPallet + ' ') })} `} position="top">
                                    <TooltipTarget
                                        onClick={() => { handleShowModal(base) }}
                                        colorBG={base.pallets.length}
                                        buscado={base.busqueda}
                                        idPallet={base._id}
                                    >C1 <br /> {base.matris}
                                    </TooltipTarget>
                                </BoxMP>
                            )
                        }

                    })}
                </ZonaC1>
                <ZonaTopC1>ZonaTopC1</ZonaTopC1>
                <ZonaRightC1>ZonaRightC1</ZonaRightC1>
                <ZonaTopD>Zona Top D</ZonaTopD>
            </AlmacenContainer>
            <ModalBox base={modalBox.base} show={modalBox.estadoModal} onHide={handleCloseModal} checkFlagState={changeFlagState} />
            <ModalPaletizado show={modalPaletizado} onHide={handleCloseModalPaletizado}/>
            <ModalSalida show={stateModalSalida} onHide={closeModalSalida} idAlmacen={'61534152431a5c10acf19d9e'}/>
        </>
    )
}

export default AlmacenMP
