import React,{useState, useEffect} from 'react'
import ModalFormParametros from './ModalFormParametros'
import * as calidadServices from '../CalidadProduccionServices'
import {listParametros} from '../typesCalidadProduccion'
function Parametros() {
    const initialStateParametroSelected = {
        _id: '', 
        idInput: {_id: '', nameInput: '', idSubCatInput:{nombre: ''}}, 
        idFormato:{id:{ _id: '', descripcion: '', codigo: '' },
            corte:'', 
            pesoEscurrido: 0, 
            margenBruto: 0,
        }, 
        idEnvase:{_id: '', descripcion: '', codigo: ''}, 
        idTapa:{_id: '', descripcion: '', codigo: '' }
    }
    const [modalState, setModalState] = useState(false);
    const [listParametros, setListParametros] = useState<listParametros[]>([])
    const [parametroSelected, setParametroSelected] = useState<listParametros>(initialStateParametroSelected)
    const showModal = () =>{
        setModalState(true);
        
    }
    const closeModal = () =>{
        setModalState(false);
        setParametroSelected(initialStateParametroSelected)
        
    }
    const afterCloseModal = () =>{
        setModalState(false)
        setParametroSelected(initialStateParametroSelected)
        getAllParametros();
    }
    const getAllParametros = async()=>{
        const resAllParametros = await calidadServices.listParametros()
        console.log(resAllParametros.data)
        setListParametros(resAllParametros.data)
    }
    const openModalEdit = (id: string)=>{        
        const findParametro = listParametros.find((parametro)=> parametro._id === id)        
        if (findParametro) {
            setParametroSelected(findParametro);
        }
        setModalState(true);
    }
    useEffect(() => {
        getAllParametros();
    }, [])

    return (
        <div>
             <div className="text-center col-12 col-sm-4 mx-auto p-3">
                <h3><strong>PARAMETROS</strong></h3>
            </div>
            <div className="text-center pt-4 pb-2">
                <button className="btn btn-info btn-lg"
                onClick={showModal}
                    >
                    Anadir Parametro
                </button>
            </div>
            <div className="table-responsive mx-auto col-12 col-sm-12 col-md-10">
                <table className="table   table-hover table-info border border-info  tableFixHead">
                    <thead>
                        <tr>
                            <th scope="col" style={{ minWidth: '120px' }}className="">SUB CAT</th>
                            <th scope="col" style={{ minWidth: '120px' }}className="">INPUT</th>
                            <th scope="col"  style={{ minWidth: '100px' }} className="">FORMATO</th>
                            <th scope="col"  style={{ minWidth: '100px' }} className="">ENVASE</th>
                            <th scope="col"  style={{ minWidth: '100px' }} className="">TAPA</th>
                            <th scope="col"  style={{ minWidth: '100px' }} className=""></th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {listParametros.map((parametros)=>{
                            return( <tr id={parametros._id} key={parametros._id} onClick={()=>openModalEdit(parametros._id)}>
                                <td>{parametros.idInput.idSubCatInput.nombre}</td>
                                <td>{parametros.idInput.nameInput}</td>
                                <td>{parametros.idFormato.id.descripcion}<br/>                                    
                                    corte: {parametros.idFormato.corte}  <br/>                                     
                                    Margen bruto: {parametros.idFormato.margenBruto} <br/>                                    
                                    Peso escurrido: {parametros.idFormato.pesoEscurrido} 
                                </td>
                                <td>{parametros.idEnvase.descripcion}</td>
                                <td>{parametros.idTapa.descripcion}</td>
                                <td><i style={{fontSize:"35px" , cursor:'pointer'}} className="far fa-edit" ></i></td>
                            </tr> )
                        })}
                    </tbody>
                </table>
            </div>
            <ModalFormParametros show={modalState} onHide={closeModal} afterHidenModal={afterCloseModal} parametroSelected={parametroSelected} />
        </div>
        
    )
}

export default Parametros
