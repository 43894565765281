import styled from 'styled-components'


export const WrapRelog = styled.div`
    background: #3b3a3a;
    color: #f1f1f1;
    font-size: 80px;
    padding: 3rem;
    border-radius: 25px;
    /* margin: 2rem; */
    width: 520px;
    height: 150px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    div{
        
        position: absolute;
    }
    @media screen and (max-width: 1000px){
        width: 100%;
        margin:0;
    }
`
export const TextTime = styled.h1`
    /* letter-spacing: 10px;
    font-size: 50px; */
`