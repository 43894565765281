import styled from 'styled-components'
interface cosechado {
    cosechado: boolean
}
interface ContainerInput{
    width: string,
    height: string
}
interface SelectGeneral{
    width: string,
    height: string,
    background: string,
    color: string,
    fontSize: string
}

export const ContainerProyecciones = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    
`
export const CardAddProy = styled.div`
    width: 300px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    border-radius: 15px;
    margin: 20px;
    font-size: 100px;
    color: #145A32;
`
export const CardProy = styled.div<cosechado>`
    width: 260px;
    height: 800px;
    display: flex;
    flex-direction: column;
    background: ${({ cosechado }) => cosechado ? 'rgba(191, 201, 202)' : ''};
    border-radius: 5px;
    margin: 20px;
    padding:15px;
    div#wrapMain{        
        height: 550px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;   
    }
    div div span{
        margin: auto;
    }    
    @media screen and (max-width: 768px){
        width: 90%;
    }

`
export const WrapCosechado = styled.div`
        border-radius: 5px;
        width: 100%;
        height: 30%;
        cursor: pointer;       
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: rgba(29, 131, 72 );
        color: #f1f1f1;
        text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #503040;
        transition: 0.2s ease-in;
        div{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-direction: row;
            font-weight: 500;            
        }
        div div{
            padding: 0.2rem;
            h3{
                padding: 2px;
                border-radius : 25%;
                border: 1px solid rgba(191, 201, 202);
                background: #fff;
                color:  rgba(44, 62, 80, 0.9);
            }
        }
`
export const WrapProyCampo = styled.div`
       border-radius: 5px;
        width: 100%;
        height: 30%;
        cursor: pointer;       
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: rgba(20, 143, 119);
        color: #f1f1f1;
        text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
        transition: 0.2s ease-in;
        div{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-direction: row;
            font-weight: 500; 
        }
        div div{
            padding: 0.2rem;
            h3{
                padding: 2px;
                border-radius : 25%;
                border: 1px solid rgba(191, 201, 202);
                background: #fff;
                color:  rgba(44, 62, 80, 0.9);
            }
        }
`
export const WrapProyDiaCampo = styled.div`
       border-radius: 5px;
        width: 100%;
        height: 30%;
        cursor: pointer;       
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: rgba(131, 145, 146 );
        color: #f1f1f1;
        text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
        transition: 0.2s ease-in;
        div{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-direction: row;
            font-weight: 500; 
        }
        div div{
            padding: 0.2rem;
            h3{
                padding: 2px;
                border-radius : 25%;
                border: 1px solid rgba(191, 201, 202);
                background: #fff;
                color:  rgba(44, 62, 80, 0.9);
            }
        }
`
export const WrapProySistema = styled.div`
        border-radius: 5px;
        width: 100%;
        height: 30%;
        cursor: pointer;       
        box-shadow: rgba(0, 0, 0, 0.35) 0px 0px 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: rgba(44, 62, 80, 0.9);
        color: #f1f1f1;
        text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
        transition: 0.2s ease-in;
        div{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-direction: row;
            font-weight: 500; 
        }
        div div{
            padding: 0.2rem;
            h3{
                padding: 2px;
                border-radius : 25%;
                border: 1px solid rgba(191, 201, 202);
                background: #fff;
                color:  rgba(44, 62, 80, 0.9);
            }
        }
      
`
export const WrapImgProy = styled.div`
    img{
        width: 35px;
    }
`
export const ContDeleteProy = styled.div`
    width: 300px;
    height: 50px;    
    text-align: center;    
    margin: 1rem 0 1rem;
    i{
        text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
        margin: 1rem;
        font-size: 25px;
        transition: 0.2s ease-in;
        cursor: pointer;
        :hover{
            font-size: 35px;
        }
    }
`
export const TextKilos = styled.span`
    text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
`
export const TitleContainer = styled.div`
    width: 100%;
    height: 30px;
    text-align: center;
    h3{
        text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
    }
`
export const SelectLote = styled.select`
    font-size: 20px;
    font-weight: 500;
    background: #6FAA87;
    text-align: center;
    color: #F2F3F4;
`
export const DivTipoCampo = styled.div`
    border: 0.3px #D4EFDF solid;
    background: rgba(93, 109, 126, .4);
    color: #283747;
    border-radius: 10px;
    width: 400px;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 10px;
    justify-content: center;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    margin-bottom: 2rem;
    select{
        padding: 1rem;
        text-align: center;
        font-size: 25px;
    }
    button{
        margin-top: 2rem;
        padding: 1rem;
        text-align: center;
        font-size: 25px;
        background: rgba(52, 73, 94 , 0.9);
        border: none;
        color: #fff;
        text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
                 0px -5px 35px rgba(2,2,2,0.3);
    }
    @media screen and (max-width: 768px){
        width: 100%;
    }
`
export const DivFechaRealSiembra = styled.div`
    border: 0.3px #D4EFDF solid;
    background: #D4EFDF;
    color: #283747;
    border-radius: 10px;
    width: 400px;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 10px;
    justify-content: center;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    margin-bottom: 2rem;
    input{
        padding: 1rem;
        text-align: center;
        font-size: 25px;
    }
    button{
        margin-top: 2rem;
        padding: 1rem;
        text-align: center;
        font-size: 25px;
        background: #239B56;
        border: none;
        color: #283747;
        text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
                 0px -5px 35px rgba(2,2,2,0.3);
    }
    @media screen and (max-width: 768px){
        width: 100%;
    }
`
export const DivAsignarProyectista = styled.div`
    border: 0.3px #F4F6F7 solid;
    background: #F4F6F7;
    color: #283747;
    border-radius: 10px;
    width: 400px;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 10px;
    justify-content: center;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    margin-bottom: 2rem;
    select{
        padding: 1rem;
        text-align: center;
        font-size: 25px;
    }
    button{
        margin-top: 2rem;
        padding: 1rem;
        text-align: center;
        font-size: 25px;
        background: #7B7D7D;
        border: none;
        color: #fff;
        text-shadow: 2px 8px 6px rgba(0,0,0,0.2),
                 0px -5px 35px rgba(2,2,2,0.3);
    }
    @media screen and (max-width: 768px){
        width: 100%;
    }
`

export const WrapTotal = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    div{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: row;
        padding: 0 0.8rem 0.5rem;
        img{
        width: 40px;
        margin-right: 1rem;
        }
        h3{
            text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
            color: #0B5345;
            font-size: 18px;
        }
    }
    @media screen and (max-width: 768px){
        width: 100%;
    }
`
export const WrapFechasCosecha = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 1rem .5rem;
    }
   label{
    padding:0;
    margin: 0;
    text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
   }
   input{
        height: 30px;
        width: 30px;

        :checked{
            background: orange;
        }
    }
`
export const TDProySis = styled.div`
    margin: 0;
    padding: 3px;
    background: rgba(44, 62, 80, 0.8);
    color: rgba(227, 235, 233);
    
    span{
        font-size: 18px;
        text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
         
    }
`
export const TDProyCam = styled.div`
    margin: 0 ;
    padding: 3px;
    background: rgba(22, 160, 133, 0.9);
    color: rgba(227, 235, 233 ) ;
    span{
        font-size: 18px;
        text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
         
    }
`
export const TDProyDiaCam = styled.div`
    margin: 0 ;
    padding: 3px;
    background: rgba(131, 145, 146);
    color: rgba(227, 235, 233 ) ;
    span{
        font-size: 18px;
        text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
         
    }
`
export const TDCosechado = styled.div`
    margin: 0;
    padding: 3px;
    background: rgba(30, 132, 73);
    color: rgba(227, 235, 233 ) ;
    
    span{
        font-size: 18px;
        text-shadow: 0px 1px 2px #8d8d8d, 0 0 2px #969696;
         
    }
`
export const TDNull = styled.div`
    height:25px;
    background: rgba(0, 105, 92, 0);
`
export const InputProyeccionCampo = styled.input`
    margin: 0 ;
    padding: 3px;
    background: rgba(22, 160, 133, 0.9);;
    color: rgba(227, 235, 233 ) ;
    width:100px;
    appearance: none;
`
export const WrapTDProyeccion = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    /* align-items: flex-start; */
    /* align-items: flex-start; */
    width:100%;
    height: 130px;
    /* flex-direction: column; */
    cursor: pointer;
`
export const MainContainerModalProy = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    div#proyeccionCampo{
        color: #4E504F;
        width:45%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        margin: 0.5rem;
        border: 2px solid rgba(22, 160, 133, 0.9);
        border-radius: 10px;
        @media screen and (max-width: 1000px){
            width: 100%;
            padding-bottom: 2rem;
        }
        img{
            width: 80px;
            margin-right: 1rem;  
        }
        form  {
            width:100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            label{
                text-align:start;
                margin:0;
                padding: 1rem 0 0;
            }
            input{
                width: 250px;
                height: 50px;
                padding: 12px 20px;
                margin: 0.3rem;
                font-size: 1.2rem;  
                font-weight: 700;        
                border-width: 1px;
                border-style: solid;
                border-color: #90989e;
                color: #495563;
                border-radius: 6px;
                text-align: center;
                outline: transparent;
                transition: 0.5 ease-in;
                :focus{
                    border-color: #495563;
                    border-width: 2px;
                } 
            }
            button{
                width: 250px;
                height: 60px;
                margin-top: 1rem;
                border-radius: 15px;
                font-size: 1.2rem;
                color: #fff;
                border: 1px solid rgba(22, 160, 133, 0.9);
                background: rgba(22, 160, 133, 0.9);
                transition: .1s ease-in;
            :hover{
               
                font-size: 1.3rem;
                
            }
            }
        }
    }
    div#cosechado{
        color: #4E504F;
        width:45%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px solid rgba(30, 132, 73);
        border-radius: 10px;
        padding: 2rem;
        margin: 0.5rem;
        @media screen and (max-width: 1000px){
            width: 100%;
            padding-bottom: 2rem;
        }
        img{
            width: 80px;
            margin-right: 1rem;  
        }
        form  {
            width:100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            label{
                text-align:start;
                margin:0;
                padding: 1rem 0 0;
            }
            input{
                width: 250px;
                height: 50px;
                padding: 12px 20px;
                margin: 0.3rem;
                font-size: 1.2rem;  
                font-weight: 700;        
                border-width: 1px;
                border-style: solid;
                border-color: #90989e;
                color: #495563;
                border-radius: 6px;
                text-align: center;
                outline: transparent;                
                transition: 0.5 ease-in;
                :focus{
                    border-color: #495563;
                    border-width: 2px;
                } 
            }
            button{
                width: 250px;
                height: 60px;
                margin-top: 1rem;
                border-radius: 15px;
                font-size: 1.2rem;
                color: #fff;
                border: 1px solid rgba(30, 132, 73);
                background: rgba(30, 132, 73);
                transition: .1s ease-in;
            :hover{
               
                font-size: 1.3rem;
                
            }
            }
        }
    }

    @media screen and (max-width: 1000px){
        flex-direction: column;
        width: 100%;
    }
`
export const WrapTitleModalProy = styled.div`
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    h3#numProy{
        border-radius: 45%;
        background:rgba(44, 62, 80, 0.8);
        padding: 0.5rem;
        color: white;
    }
`
export const WrapBusqueda = styled.div`
    display: flex;
    width: 100;
    justify-content: start;
    align-items: flex-end;
    input{
        width: 50%;
                height: 30px;
                padding: 5px 10px;
                margin: 0.3rem;
                font-size: 1.2rem;  
                font-weight: 500;        
                border-width: 1px;
                border-style: solid;
                border-color: #90989e;
                color: #495563;
                border-radius: 6px;
                text-align: start;
                outline: transparent;            
                transition: 0.5 ease-in;
                :focus{
                    border-color: #495563;
                    border-width: 2px;
                    
    } 
    button{
        
    }

    @media screen and (max-width: 1000px){
        width: 100%;
    }
    }
`
export const ContainerAdmLote = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    div#title{
        display: flex;
        text-align: center;
        flex-direction: column;
        
        justify-content: center;
        text-shadow: 0px 1px 2px #8d8d8d, 0 1px 2px #503040;

        div#proyectista{
            display: flex;
            justify-content: center;
            align-items: flex-end;
            div{
                display: flex;
                flex-direction: column;  
                select{
                width: 250px;
                height: 50px;
                padding: 12px 20px;
                margin: 0.3rem;
                font-size: 1.2rem;  
                font-weight: 700;        
                border-width: 1px;
                border-style: solid;
                border-color: #90989e;
                color: #495563;
                border-radius: 6px;
                text-align: center;
                outline: transparent;                
                transition: 0.5 ease-in;
                :focus{
                    border-color: #495563;
                    border-width: 2px;                
                } 
                }
            }
            button{
                width: 100px;
                height: 50px;
                margin: 0.3rem;
                border-radius: 15px;
                font-size: 1.2rem;
                color: #fff;
                border: 1px solid #212F3C   ;
                background: #212F3C;
                transition: .1s ease-in;
            :hover{
               
                font-size: 1.3rem;
                
            }
            }
        }
    }
    div#proyecciones{
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        div{
            text-align: center;
            margin: 0.5rem;

            div.proyeccionSistema{
                width: 120px;             
                border-radius: 5px;                
                color: #fff;
                background: rgba(44, 62, 80);
                transition: .1s ease-in;
            }
            div.proyeccionCampo{
                width: 120px;
                border-radius: 5px;
                color: #fff;
                background: rgba(22, 160, 133, 0.9);
                transition: .1s ease-in;
            }
            div.cosechado{
                width: 120px;             
                border-radius: 5px;
                color: #fff;
                background: rgba(30, 132, 73);
                transition: .1s ease-in;
            }
            div.delete{
                cursor: pointer;
                width: 120px; 
                height: 30px; 
                font-size: 20px;           
                border-radius: 5px;               
                color: #fff;                
                background: #B03A2E;
                transition: .1s ease-in;
            }
            
        }
        div.addProyeccion{
                width: 140px;
                border-radius: 5px;
                margin: 2rem 1rem 2rem;
                padding: 0.5rem;
                color: #fff;
                display: flex;
                flex-direction:column;
                justify-content: center;
                align-items: center;                
                background: rgba(22, 160, 133, 0.9);
                transition: .1s ease-in;
            input{
                width: 120px;
                height: 30px;
                margin: 0.2rem;
                font-weight: 700;        
                border-width: 1px;
                border-style: solid;
                border-color: #90989e;
                color: #495563;
                border-radius: 6px;
                text-align: center;
                outline: transparent;
                transition: 0.5 ease-in;
                :focus{
                    border-color: #495563;
                    border-width: 2px;               
                } 
               
            }
            button{
                    width: 120px;
                height: 35px;
                margin: 0.3rem;
                border-radius: 15px;
                font-size: 1.2rem;
                color: #fff;
                border: 1px solid #212F3C   ;
                background: #212F3C;                
                :hover{
                
                    font-size: 1.3rem;
                    
                }
                } 
            }
    }
`

export const ContainerCerrarCampo = styled.div`
    display: flex;
    width: 80%;
    margin: 2rem auto;
    color: white;
    justify-content: center;
    align-items: center;
    background: #480700;
    border-radius: 5px;
    border:  2px solid #480700;
    cursor: pointer;
    img{
        width: 50px;
        margin: 1rem;
    }
    p{
        font-size: 20px;
        margin: 1rem;
    }
    :hover{
        color: #480700;
        background: rgba(72, 7, 0, 0.2) ;
        border:  2px solid #480700;
    }
    @media screen and (max-width: 1000px){
        width: 100%;
    }
`



export const ContainerInput = styled.div<ContainerInput>`
    width: ${({width})=>width ? width : '500px'};

`

export const SelectGeneral = styled.div`

`