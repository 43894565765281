import styled from 'styled-components'

export const CardAlmacen = styled.div`
    border: solid 1px #6464646e;
    border-radius: 8px;
    box-shadow: 0px 5px 8px rgba(0,0,0, 0.2);
    max-height: 400px;
    min-height: 350px;
    max-width: 400px;
    min-width: 350px;
    display: grid;
    margin: 10px;
    align-content: flex-end;
    & > * {  
        margin: 10px;    
        color: #010625;
        display: flex;
        justify-content: center;
        align-items: center;
    } 

`
export const TitleContainer = styled.div`
    /* border: 1.2px solid #141414; */
    justify-content: center;
    align-items: flex-end;
    height: 100%;
`
export const Title = styled.h1`
    color: #213326;
    cursor: pointer;
`
export const CardMovimientos = styled.div`
   display: flex;
   display: -webkit-flex;
   display: --ms-flexbox;
   /* border: 1.2px solid #141414; */
   flex-direction: flex;
   flex-wrap: wrap;
   
   justify-content: center;
   /* align-items: flex-end; */
   align-content: flex-end;
`
export const Button = styled.button`
     border-radius: 8px;
    border: 1.2px solid #213326;
    background: #213326;
    /* padding: 10px; */
    width: 90%;    
    margin: 2px;
    font-family: monospace;
    font-weight: bold;
    color: #ffffff;
    height: 50px;

    &:hover{
        /* box-shadow: 10px 30px 45px #768f8066;  */
        background: #fff;
        color: #213326;
    }
    @media screen and (max-width: 900px){
        height: 70px;
   }
`